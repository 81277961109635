
#bootstrapoverride .login-container {
	position: relative;
	width: 685px;
	height: 330px;
	margin: 40px auto 140px;

	.login-box {
		position: absolute;
		top: 44px;
		left: 0;
		width: 685px;
		background-color: white;
		border: 5px solid $vermillion;
		border-radius: 20px;

		&:after {
			content: url("../img/signin-lowerleftbear.png");
			position: absolute;
			bottom: -36px;
			left: 20px; }

		.row {
			width: 65%;
			margin: 0 auto 20px; }


		label {
			font-size: 16px;
			color: #595757; }

		input {
			width: 100%;
			appearance: none;
			border: 1px solid #808080;
			border-radius: 20px;
			padding: 5px 15px;
			background: transparent; }

		input[type="checkbox"] {
			-webkit-appearance: checkbox;
			width: auto; }

		.password-col {
			position: relative;

			.forgot-password {
				position: absolute;
				bottom: -24px;
				right: 18px;
				font-size: 12px;
				color: $vermillion; } }


		.login-buttons {
			margin-top: 70px;
			margin-bottom: 40px;

			.no-padding {
				padding: 0; } }

		.red-button {
			width: 110px;
			margin-right: 0;
			border-color: none; }

		.white-button {
			width: 110px; }


		.warning {
			margin: 0 0 30px 50px;

			img {
				display: inline-block;
				margin-right: 7px; }

			p {
				font-size: 16px;
				color: $vermillion; } } }


	form[name="form-login"] {
		margin-top: 50px; }


	#reset-password-tab {
		display: inline; }


	form[name="form-reset-password"] {
		display: block;
		margin-top: 30px;

		.red-button {
			width: 140px; } }


	form[name="form-reset-password-code"] {
		display: none;
		margin-top: 30px;

		.red-button {
			width: 140px; } }


	form[name="form-new-password"] {
		display: block;
		margin-top: 30px;

		.red-button {
			width: 140px; } } }



#bootstrapoverride form[name="user-registration-form"] {
	width: 70%;
	margin: 50px auto;

	.row:first-of-type {
		margin-bottom: 70px; }

	.remark {
		position: absolute;
		display: inline-block;
		bottom: -50px;
		left: 15px;
		width: 82%;

		p {
			font-size: 10px;
			color: #6d6d6d;
			letter-spacing: 1px; }

		span {
			vertical-align: middle; }

		.red-button {
			display: inline-block;
			width: 72px;
			height: 20px;
			font-size: 12px;
			margin: 0;
			padding: 0; } }


	.confirmation-code-btn {
		width: 125px;
		height: 35px;
		background-color: #808080;
		color: white;
		font-size: 18px;
		padding: 4px;
		text-align: center;
		border: none; }


	.margin-top {
		margin-top: 50px; }


	.row-tighter {
		margin-bottom: 10px; }


	.red-button {
		width: 110px;
		border: none;
		margin-top: 30px; } }


#bootstrapoverride .conditions-container {
	.inner-container {
		padding: 50px 40px; }

	.conditions-button {
		position: absolute;
		bottom: -50px;
		right: 0;
		width: 85px;
		height: 26px;
		font-size: 18px;
		padding: 2px 0 2px 14px;

		.arrow-up {
			top: 5px;
			left: -2px; } } }


#conditions-page-top {
	position: absolute;
	top: 0; }

@media all and (max-width: $mobile) {
	#bootstrapoverride .login-container {
		width: 90vw;
		margin: 80px auto 140px;

		.login-box {
			width: 90vw;
			border-radius: 8px;

			&:after {
				 background-size: 80px 80px;
				 content: "";
				 background-image: url("../img/signin-lowerleftbear.png");
				 height: 80px;
				 width: 80px; }


			.row {
				width: 90%; }
			.col-sm-4 {
				min-width: 100px; }

			.red-button {
				float: right;
				margin-left: 9px;
				width: 100px; }
			.warning {
				margin: 0px 0px 30px 15px; } } }

	#bootstrapoverride form[name="user-registration-form"] {
		width: 90%;

		.row:first-of-type {
			margin-bottom: 125px; }

		.remark {
			position: absolute;
			display: inline-block;
			bottom: -110px;
			left: 25px;
			width: 82%;

			p {
				font-size: 10px;
				color: #6d6d6d;
				letter-spacing: 1px; }

			span {
				vertical-align: middle; }

			.red-button {
				display: inline-block;
				width: 72px;
				height: 20px;
				font-size: 12px;
				margin: 0;
				padding: 0; } }


		.confirmation-code-btn {
			width: 125px;
			height: 35px;
			background-color: #808080;
			color: white;
			font-size: 18px;
			padding: 4px;
			text-align: center;
			border: none; }


		.margin-top {
			margin-top: 50px; }


		.row-tighter {
			margin-bottom: 10px; }


		.red-button {
			width: 110px;
			border: none;
			margin-top: 30px; } }

	.register-container {
		.col-sm-9, .col-sm-5 {
			width: 100%; }
		.col-sm-5.name {
			padding-right: 3px; }
		.col-sm-2.gender {
			display: inline-table;
			padding: 3px;
			&.male {
				padding-right: 60px; }
			label {
				padding-left: 3px !important; } }

		.dropdown-col {
			padding-right: 0px;
			width: 23%;
			min-width: 20% !important; } }


	#bootstrapoverride .conditions-container {
		.inner-container {
			padding: 15px; } } }
















