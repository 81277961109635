.order-number-container {
	width: 820px;
	height: 50px;
	border-radius: 100px;
	background-color: #f0190f;
	margin: 40px auto 20px;
	font-size: 18px !important;
	font-weight: bold;
	line-height: 1.33;
	letter-spacing: 2px;
	color: #ffffff;
	padding: 15px 35px; }

::-webkit-scrollbar {
	width: 5px; }
::-webkit-scrollbar-track-piece {
	background: white; }
::-webkit-scrollbar-thumb {
	background-color: orange;
	background: red;
	color: blue;
	border-radius: 10px; }

.chatbox {
	border-bottom: solid 6px #f0190f;
	height: 400px;
	overflow-y: auto;
	padding: 0px 20px;
	scrollbar-face-color: green;
	scrollbar-track-color: blue;

	img {
		margin: 0px 16px; }

	.message-box {
		position: relative;
		max-width: 440px;
		min-height: 50px;
		height: auto;
		word-break: break-all;
		padding: 15px;
		border-radius: 8px;
		margin-bottom: 30px;
		&:after {
				position: absolute;
				content: "";
				width: 0;
				height: 0;
				border-style: solid; } }

	.time-stamp {
		width: 70px;
		margin: 0px 5px;
		color: #808080; }

	.user-box {
		min-height: 40px;
		overflow: hidden;
		.message-box {
			background-color: #d9f5ff;
			&:after {
				border-width: 20px 20px 0 0;
				border-color: #d9f5ff transparent transparent transparent;
				top: 0px;
				right: -13px; } } }

	.server-box {
		min-height: 40px;
		overflow: hidden;
		.message-box {
			background-color: #ececec;
			&:after {
				border-width: 0 20px 20px 0;
				border-color: transparent #ececec transparent transparent;
				top: 0px;
				left: -13px; } } } }
.float-right {
	float: right; }
.float-left {
	float: left; }
.message-form {
	width : 820px;
	height: 60px;
	border-radius: 20px;
	border: solid 1px #ff372d;
	margin: 17px auto;
	.messages {
		border: none !important;
		height: inherit !important;
		width: 725px !important;
		float: left;
		padding: 20px !important;
		overflow: hidden;
		font-size: 20px; }
	.message-send-btn {
		width: 66px !important;
		float: left;
		margin: 13px !important; } }

@media all and (max-width: $mobile) {
	.order-number-container {
		width: 80vw;
		height: 22px;
		margin: 16px auto 20px;
		font-size: 11px !important;
		padding: 1px 20px; }
	.chatbox {
		padding: 0px;
		img {
			margin: 0px 2px;
			width: 15%;
			margin-top: 10px; }
		.message-box {
			max-width: 40vw; } }
	.message-form {
		width: 80vw;
		height: 35px;
		border-radius: 12px;

		.messages {
			padding: 6px 10px !important;
			width: 60vw !important; }
		.message-send-btn {
			width: 40px !important;
			height: 20px !important;
			margin: 7px !important;
			float: right;
			padding: 0px !important; } } }
