@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'Noto';
  src: url("../fonts/NotoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto';
  src: url("../fonts/NotoSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Yuanti';
  src: url("../fonts/cwTeXYen-zhonly.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  min-width: 1400px;
}

#bootstrapoverride h1, #bootstrapoverride h2, #bootstrapoverride h3, #bootstrapoverride h4, #bootstrapoverride h5, #bootstrapoverride li, #bootstrapoverride a, #bootstrapoverride label, #bootstrapoverride p, #bootstrapoverride span, #bootstrapoverride div {
  font-family: "Heiti TC", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 22px;
}

#bootstrapoverride li, #bootstrapoverride a, #bootstrapoverride label, #bootstrapoverride p, #bootstrapoverride span, #bootstrapoverride div {
  font-size: 14px;
}

#bootstrapoverride h1 {
  font-size: 45px;
}

#bootstrapoverride h2 {
  font-size: 37px;
  line-height: 45px;
}

#bootstrapoverride h3 {
  font-size: 24px;
  line-height: 32px;
}

#bootstrapoverride h4 {
  font-size: 20px;
  line-height: 28px;
}

#bootstrapoverride h5 {
  font-size: 15px;
}

#bootstrapoverride p {
  font-size: 14px;
  color: #595757;
}

#bootstrapoverride a,
#bootstrapoverride a:link,
#bootstrapoverride a:visited,
#bootstrapoverride a:hover,
#bootstrapoverride a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}

#bootstrapoverride input:focus,
#bootstrapoverride textarea:focus {
  outline: none;
}

.mobile-show {
  display: none;
}

.slick-prev, .slick-next {
  z-index: 20;
  background-image: url(/asset/img/icons/btn-stroke-right.svg) !important;
  background-size: cover !important;
  height: 32px;
}

.slick-prev:before, .slick-next:before {
  opacity: 0;
  content: "";
}

.slick-prev {
  left: 0;
  top: 35%;
  transform: rotateZ(180deg);
}

.slick-next {
  right: 0;
}

.slick-dots li.slick-active button:before {
  background-color: #f0190f;
  color: transparent;
  opacity: 1;
  border: none;
  width: 15px;
  height: 15px;
}

.slick-dots li button:before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #f0190f;
  color: transparent;
  opacity: 1;
}

.back-to-top-btn {
  position: fixed;
  bottom: 80px;
  right: 8vw;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: grey;
  opacity: 0.4;
  padding: 10px;
  z-index: 1000000;
}

@media all and (max-width: 500px) {
  body {
    min-width: 100vw;
  }
  .mobile-not-show {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  .back-to-top-btn {
    bottom: 20px;
    right: 20px;
  }
}

#bootstrapoverride .body-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  overflow: hidden;
  margin-left: 0px;
  transition: all 0.2s linear;
}

#bootstrapoverride .body-on-nav-open {
  margin-left: 200px;
}

#bootstrapoverride .main-content-wrapper {
  flex: 1;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

@media all and (max-width: 500px) {
  body {
    overflow-x: hidden;
  }
  #non-overflow-body {
    width: 100vw;
    overflow-x: hidden;
  }
}

#bootstrapoverride .clearfix:after {
  content: "";
  clear: both;
  display: table;
}

#bootstrapoverride .img-center {
  width: 100%;
}

#bootstrapoverride .img-center img {
  width: 100%;
  object-fit: cover;
}

#bootstrapoverride .red-button, #bootstrapoverride .white-button {
  display: block;
  width: 143px;
  height: 32px;
  background-color: #f0190f;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  color: white !important;
  padding: 5px 0;
  margin: 0 auto;
  border: none;
}

#bootstrapoverride .white-button {
  background-color: white;
  color: #f0190f !important;
  border: 2px solid #f0190f;
  padding: 3px 0;
}

#bootstrapoverride .favorite-button {
  float: right;
  margin: 50px 0px 20px 10px;
}

#bootstrapoverride .red-button-small {
  display: inline-block;
  height: 20px;
  background-color: #f0190f;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  color: white !important;
  padding: 0 8px;
}

#bootstrapoverride .vertical-align-middle {
  display: flex;
  align-items: center;
}

#bootstrapoverride .align-right {
  text-align: right;
}

#bootstrapoverride .red-tab-container {
  position: relative;
  width: 900px;
  margin: 40px auto 140px;
  padding-top: 45px;
}

#bootstrapoverride .red-tab-container .inner-container {
  position: relative;
  width: 900px;
  background-color: white;
  border: 5px solid #f0190f;
  border-radius: 20px;
  z-index: 2;
}

#bootstrapoverride .red-tab-container .inner-container.conditions p {
  font-size: 15px;
  line-height: 30px;
}

#bootstrapoverride .red-tab-container .inner-container.conditions p .text-indent {
  text-indent: 25px;
}

#bootstrapoverride .red-tab-container .position-1 {
  left: 0;
}

#bootstrapoverride .red-tab-container .position-2 {
  left: 190px;
}

#bootstrapoverride .red-tab-container .position-3 {
  left: 380px;
}

#bootstrapoverride .red-tab-container .position-4 {
  left: 570px;
}

#bootstrapoverride .red-tab-container .mobile-tab-nav {
  display: none;
}

#bootstrapoverride .red-tab-container .tab-inactive {
  position: absolute;
  top: 5px;
  width: 220px;
  height: 60px;
  border: 5px solid #f0190f;
  border-radius: 20px 20px 0 0;
  background-color: white;
  padding: 7px;
  text-align: center;
  transition: all .1s ease-in-out;
}

#bootstrapoverride .red-tab-container .tab-inactive:hover {
  cursor: pointer;
}

#bootstrapoverride .red-tab-container .tab-inactive p {
  color: #f0190f;
  font-size: 22px;
  font-weight: bold;
}

#bootstrapoverride .red-tab-container .tab {
  position: absolute;
  top: -10px;
  width: 220px;
  height: 70px;
  border: 0 solid transparent;
  border-radius: 20px 20px 0 0;
  background-color: #f0190f;
  padding: 17px 5px;
  text-align: center;
  z-index: 1;
}

#bootstrapoverride .red-tab-container .tab:hover {
  cursor: pointer;
}

#bootstrapoverride .red-tab-container .tab:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 40px;
  left: 215px;
  border-style: solid;
  border-color: #f0190f;
  border-width: 0 0 5px 5px;
  border-radius: 0 0 0 20px;
  box-shadow: -5px 5px 0 #f0190f;
}

#bootstrapoverride .red-tab-container .tab.tab-middle:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-color: #f0190f;
  border-width: 0 5px 5px 0;
  border-radius: 0 0 20px 0;
  box-shadow: 5px 5px 0 #f0190f;
}

#bootstrapoverride .red-tab-container .tab.tab-last:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 40px;
  left: -15px;
  border-style: solid;
  border-color: #f0190f;
  border-width: 0 5px 5px 0;
  border-radius: 0 0 20px 0;
  box-shadow: 5px 5px 0 #f0190f;
}

#bootstrapoverride .red-tab-container .tab.tab-last:after {
  display: none;
}

#bootstrapoverride .red-tab-container .tab p {
  color: white;
  font-size: 22px;
  font-weight: bold;
}

#bootstrapoverride .hidden {
  display: none;
}

#bootstrapoverride label {
  font-size: 16px;
  color: #595757;
}

#bootstrapoverride form .row {
  margin-bottom: 22px;
}

#bootstrapoverride form .row.margin-bottom {
  margin-bottom: 35px;
}

#bootstrapoverride form .indent {
  margin-left: 20px;
}

#bootstrapoverride .row-title {
  color: #595757;
  font-size: 16px;
  font-weight: bold;
}

#bootstrapoverride input[type="text"],
#bootstrapoverride input[type="tel"],
#bootstrapoverride input[type="password"],
#bootstrapoverride input[type="email"],
#bootstrapoverride textarea,
#bootstrapoverride select {
  width: 100%;
  appearance: none;
  border: 1px solid #808080;
  border-radius: 20px;
  padding: 5px 15px;
  background: transparent;
}

#bootstrapoverride textarea {
  width: 100%;
  height: 135px;
  padding: 10px 15px;
  font-family: "Heiti TC", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
}

#bootstrapoverride .indent-for-radio {
  padding-left: 10px;
}

#bootstrapoverride .indent-for-radio-row {
  padding-left: 40px;
}

#bootstrapoverride .grey-remark {
  position: relative;
  display: inline;
}

#bootstrapoverride .grey-remark span.small {
  position: absolute;
  display: inline-block;
  width: 200px;
  bottom: -18px;
  right: 0;
  font-size: 10px;
  color: #6d6d6d;
  text-align: right;
  letter-spacing: 1px;
}

#bootstrapoverride .blue-remark {
  position: relative;
  margin-bottom: 10px;
}

#bootstrapoverride .blue-remark span.small {
  position: absolute;
  display: inline-block;
  width: 600px;
  bottom: -24px;
  right: 0;
  font-size: 11px;
  font-weight: bold;
  color: #00b9ff;
  text-align: right;
}

#bootstrapoverride select[name="expireMM"] {
  padding-left: 25px;
}

#bootstrapoverride select[name="expireYY"] {
  padding-left: 15px;
}

#bootstrapoverride .dropdown-wrapper {
  position: relative;
  width: 100%;
  height: 35px;
}

#bootstrapoverride .dropdown-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 20px;
  margin-top: -6px;
  border-width: 7px 7px 0 7px;
  border-style: solid;
  border-color: #777777 transparent;
  pointer-events: none;
}

#bootstrapoverride .dropdown-wrapper .dropdown {
  width: 100%;
  height: 35px;
  border: solid 1px #808080;
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: #777777;
}

#bootstrapoverride .img-wrapper {
  position: relative;
  overflow: hidden;
}

#bootstrapoverride .img-wrapper img {
  position: absolute;
  height: 100%;
  width: auto;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
}

#bootstrapoverride .arrow-up {
  position: relative;
}

#bootstrapoverride .arrow-up:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 14px;
  right: 4px;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: #fff transparent;
}

#bootstrapoverride .pagination {
  float: right;
  margin: 0 0 20px 0;
}

#bootstrapoverride .pagination li {
  display: inline-block;
  width: 33px;
  height: 20px;
  padding: 1px 0;
  border: 1px solid #f0190f;
  border-radius: 10px;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #f0190f;
}

#bootstrapoverride .pagination li:hover {
  cursor: pointer;
}

#bootstrapoverride .pagination li.active {
  background-color: #f0190f;
  color: white;
}

.underline {
  text-decoration: underline;
}

.slick-dots {
  text-align: right;
}

@media all and (max-width: 500px) {
  .mobile-hidden {
    display: none;
  }
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  #bootstrapoverride .favorites-container {
    margin: 100px auto 140px;
  }
  #bootstrapoverride .red-tab-container {
    width: 90vw;
  }
  #bootstrapoverride .red-tab-container .inner-container {
    width: 90vw;
  }
  #bootstrapoverride .red-tab-container .inner-container.conditions p {
    font-size: 11px;
  }
  #bootstrapoverride .red-tab-container .tab {
    top: 19px;
    width: 110px;
    border-radius: 8px 8px 0px 0px;
    padding: 4px;
  }
  #bootstrapoverride .red-tab-container .tab:after {
    top: 16px;
    left: 105px;
  }
  #bootstrapoverride .red-tab-container .tab p {
    font-size: 11px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav {
    display: initial;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .nav-title {
    width: 90vw;
    height: 55px;
    background-color: red;
    position: relative;
    color: white;
    padding: 13px;
    margin-bottom: -13px;
    border-radius: 8px 8px 0px 0px;
    margin-top: 90px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .nav-title p {
    color: white;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .nav-title:after {
    position: absolute;
    content: "";
    top: 11px;
    right: 20px;
    border: solid white;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .nav-title:before {
    content: url("../img/container-righttop-yellowbear.svg");
    position: absolute;
    top: -88px;
    right: 20px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .active-title {
    margin-bottom: 0px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .mobile-tab {
    width: 90vw;
    background-color: white;
    padding: 6px 13px;
    border: solid 1px #f0190f;
    height: 35px;
    position: relative;
    left: 0px !important;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .mobile-tab:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 11px;
    right: 20px;
    border-radius: 50%;
    border: solid 1px #f0190f;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .mobile-tab:first-child {
    border-radius: 8px 8px 0px 0px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .mobile-tab:last-child {
    border-radius: 0px 0px 8px 8px;
  }
  #bootstrapoverride .red-tab-container .mobile-tab-nav .active-mobile-tab:before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    top: 14px;
    right: 23px;
    border-radius: 50%;
    background-color: #f0190f;
  }
  #bootstrapoverride .red-tab-container .desktop-tab-nav {
    display: none;
  }
  #bootstrapoverride .row-title {
    font-size: 11px;
  }
  #bootstrapoverride .col-sm-3 {
    min-width: 90px;
  }
  #bootstrapoverride .grey-remark span.small {
    left: 0;
    text-align: left;
  }
  #bootstrapoverride .red-button, #bootstrapoverride .white-button, #bootstrapoverride .white-button {
    width: 100px;
    font-size: 11px;
  }
  #bootstrapoverride input[type="text"],
  #bootstrapoverride input[type="tel"],
  #bootstrapoverride input[type="password"],
  #bootstrapoverride input[type="email"],
  #bootstrapoverride textarea,
  #bootstrapoverride select {
    padding: 0 15px;
  }
  #bootstrapoverride input {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  #bootstrapoverride .m20 {
    width: 20vw !important;
  }
  #bootstrapoverride .m25 {
    width: 25vw !important;
  }
  #bootstrapoverride .m30 {
    width: 30vw !important;
  }
  #bootstrapoverride .m35 {
    width: 35vw !important;
  }
  #bootstrapoverride .m40 {
    width: 40vw !important;
  }
  #bootstrapoverride .m45 {
    width: 45vw !important;
  }
  #bootstrapoverride .m50 {
    width: 50vw !important;
  }
  #bootstrapoverride .m55 {
    width: 55vw !important;
  }
  #bootstrapoverride .m60 {
    width: 60vw !important;
  }
  #bootstrapoverride .m65 {
    width: 65vw !important;
  }
  #bootstrapoverride .m70 {
    width: 70vw !important;
  }
  #bootstrapoverride .m75 {
    width: 75vw !important;
  }
  #bootstrapoverride .m80 {
    width: 80vw !important;
  }
  #bootstrapoverride .m85 {
    width: 85vw !important;
  }
  #bootstrapoverride .m90 {
    width: 90vw !important;
  }
  #bootstrapoverride .m95 {
    width: 95vw !important;
  }
  #bootstrapoverride .m100 {
    width: 100vw !important;
  }
  #bootstrapoverride .m105 {
    width: 105vw !important;
  }
  #bootstrapoverride .m110 {
    width: 110vw !important;
  }
  #bootstrapoverride .m115 {
    width: 115vw !important;
  }
  #bootstrapoverride .m120 {
    width: 120vw !important;
  }
  #bootstrapoverride .m125 {
    width: 125vw !important;
  }
  #bootstrapoverride .dropdown-wrapper {
    height: 25px;
    margin: 5px 0;
  }
  #bootstrapoverride .dropdown-wrapper select {
    padding: 0px 7px;
    font-size: 11px;
  }
  #bootstrapoverride .dropdown-wrapper:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    right: 6px;
    top: 16px;
    margin-top: -6px;
    border-width: 7px 7px 0 7px;
    border-style: solid;
    border-color: #777777 transparent;
    pointer-events: none;
  }
  #bootstrapoverride .dropdown-wrapper .dropdown {
    width: 100%;
    height: auto;
    border: solid 1px #808080;
    border-radius: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    color: #777777;
  }
}

#bootstrapoverride nav.desktop-nav {
  width: 100%;
  text-align: center;
  padding: 50px 0 40px;
}

#bootstrapoverride nav.desktop-nav .logo img {
  width: 243px;
  height: 34px;
}

#bootstrapoverride nav.desktop-nav .logo {
  display: inline-block;
  margin-bottom: 30px;
}

#bootstrapoverride nav.desktop-nav .quick-nav {
  position: absolute;
  top: 50px;
  right: 20px;
}

#bootstrapoverride nav.desktop-nav .quick-nav li {
  display: inline-block;
  margin: 0 4px;
  vertical-align: top;
}

#bootstrapoverride nav.desktop-nav .quick-nav li:first-of-type {
  position: absolute;
  top: 7px;
  left: -90px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .circle {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-color: #f0190f;
  border-radius: 50%;
}

#bootstrapoverride nav.desktop-nav .quick-nav .circle img {
  float: left;
}

#bootstrapoverride nav.desktop-nav .quick-nav .circle div {
  float: left;
  color: white;
  font-size: 20px;
  line-height: 40px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .non-empty-cart {
  width: 70px;
  height: 35px;
  border-radius: 18px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  transition: width .5s ease-in-out;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn input {
  display: inline-block;
  width: 230px;
  height: 35px;
  color: #545455;
  padding: 1px 0 0 15px;
  border: solid 4px #9b9da0;
  border-radius: 18px;
  pointer-events: none;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn input::-webkit-input-placeholder {
  color: #545455;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn input:-moz-placeholder {
  color: #545455;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn input::-moz-placeholder {
  color: #545455;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn input:-ms-input-placeholder {
  color: #545455;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .expanded > input {
  pointer-events: auto;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble {
  display: inline-block;
  position: absolute;
  top: -45px;
  left: -155px;
  opacity: 0;
  transition: opacity .4s ease-out;
  z-index: 1;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble img {
  position: absolute;
  top: 8px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble img:hover {
  cursor: pointer;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble #search {
  right: 32px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble #delete-input {
  right: 10px;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble.expanded {
  opacity: 1;
}

#bootstrapoverride nav.desktop-nav .quick-nav .search-btn .search-bubble:after {
  content: "";
  position: absolute;
  top: 32px;
  left: 153px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 18px 8px;
  border-color: #9b9da0 transparent transparent transparent;
  transform: skew(40deg);
}

#bootstrapoverride nav.desktop-nav .quick-nav a {
  position: relative;
}

#bootstrapoverride nav.desktop-nav .quick-nav a:hover .bubble {
  opacity: 1;
}

#bootstrapoverride nav.desktop-nav .quick-nav a .bubble {
  display: inline-block;
  position: absolute;
  top: -60px;
  left: -5px;
  width: 100px;
  height: 28px;
  color: #fff;
  background-color: #f0190f;
  padding: 2px 0;
  border-radius: 18px;
  opacity: 0;
  transition: opacity .4s ease-out;
  pointer-events: none;
}

#bootstrapoverride nav.desktop-nav .quick-nav a .bubble:before {
  content: "";
  position: absolute;
  top: 24px;
  left: 15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 7px;
  border-color: #f0190f transparent transparent transparent;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/bear"] .bubble {
  left: -50px !important;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/bear"] .bubble:before {
  left: 60px !important;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/bear"] .bubble:after {
  left: 61px !important;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/cart"] .bubble {
  width: 80px !important;
  left: -35px !important;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/cart"] .bubble:before {
  left: 45px !important;
}

#bootstrapoverride nav.desktop-nav .quick-nav a[href="/cart"] .bubble:after {
  left: 46px !important;
}

#bootstrapoverride nav.desktop-nav .main-nav li {
  display: inline-block;
  margin: 0 1px;
  padding: 5px 15px 3px;
  border: solid 1px transparent;
  transition: background-color .3s, color .3s;
}

#bootstrapoverride nav.desktop-nav .main-nav li a {
  font-size: 18px;
  font-weight: bold;
}

#bootstrapoverride nav.desktop-nav .main-nav li:last-of-type {
  position: relative;
}

#bootstrapoverride nav.desktop-nav .main-nav li:last-of-type span.cart {
  position: absolute;
  bottom: -5px;
  right: -60px;
  width: 75px;
  height: 55px;
}

.mobile-nav-top {
  display: none;
}

.side-nav {
  position: fixed;
  width: 200px;
  height: 100vh;
  top: 0px;
  left: -200px;
  transition: all 0.2s linear;
  background-color: #f0190f;
  overflow: hidden;
}

.side-nav li {
  color: white;
  letter-spacing: 1.6px;
  line-height: 60px !important;
  padding: 0px 24px;
  height: 60px;
  position: relative;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  display: block;
}

.side-nav li:first-child {
  line-height: 0px !important;
  padding: 18px 0px;
}

.side-nav li input {
  height: 23px;
  width: 112px !important;
  background-color: white !important;
  color: black;
  position: relative;
  padding: 0px 15px !important;
}

.side-nav li .search {
  position: absolute;
  top: 19px;
  right: 46px;
}

.side-nav li #delete-input {
  position: absolute;
  display: none;
  top: 22px;
  right: 48px;
}

.side-nav li .login-pic {
  position: absolute;
  top: 20px;
  left: 38px;
}

.side-nav li .cart-pic {
  position: absolute;
  top: 11px;
  right: 20px;
}

.side-nav-open {
  left: 0px;
}

@media all and (max-width: 770px) {
  .desktop-nav {
    display: none;
  }
  .mobile-nav-top {
    display: block;
    position: fixed;
    z-index: 20;
    background-color: white;
    width: 100vw;
  }
  .mobile-nav-top .nav-burger {
    height: 5px;
    width: 24px;
    position: relative;
    background-color: #f0190f;
    margin: 24px 20px;
  }
  .mobile-nav-top .nav-burger:before {
    content: "";
    position: absolute;
    height: 5px;
    width: 24px;
    top: -8px;
    background-color: #f0190f;
  }
  .mobile-nav-top .nav-burger:after {
    content: "";
    position: absolute;
    height: 5px;
    width: 24px;
    top: 8px;
    background-color: #f0190f;
  }
  .mobile-nav-top .mobile-top-logo {
    width: 170px;
    margin: -40px auto 0px;
  }
  .mobile-nav-top .mobile-top-logo img {
    width: 170px;
    height: auto;
  }
  .mobile-nav-top .shopping-cart {
    position: absolute;
    top: 16px;
    right: 20px;
  }
  .mobile-nav-top .shopping-cart img {
    width: 24px;
    height: 24px;
  }
  .mobile-nav-top .shopping-cart .none-empty-cart div {
    display: none;
  }
}

#bootstrapoverride footer {
  width: 100%;
  background-color: transparent;
  color: white;
  padding: 50px;
  z-index: 11;
  transition: background-color .3s;
  background-color: #bbbbbb;
}

#bootstrapoverride footer .inner-container {
  width: 900px;
  margin: 0 auto;
}

#bootstrapoverride footer .inner-container .footer-col {
  width: 25%;
  float: left;
}

#bootstrapoverride footer .inner-container .col-md-3 h5 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

#bootstrapoverride footer .inner-container .col-md-3 li {
  line-height: 16px;
}

#bootstrapoverride footer .inner-container .col-md-3 li a {
  font-size: 14px;
  line-height: 24px;
}

#bootstrapoverride footer .inner-container .subscribe {
  margin: 2px 0 9px;
}

#bootstrapoverride footer .inner-container input[type="email"] {
  display: inline-block;
  float: left;
  width: 100px;
  height: 18px;
  border: solid 1px #fff;
  border-radius: 10px;
  padding: 9px 10px;
  background-color: transparent;
  color: #fff;
  font-size: 9px;
  line-height: 18px;
}

#bootstrapoverride footer .inner-container input[type="email"]::-webkit-input-placeholder {
  color: #fff;
}

#bootstrapoverride footer .inner-container input[type="email"]:-moz-placeholder {
  color: #fff;
}

#bootstrapoverride footer .inner-container input[type="email"]::-moz-placeholder {
  color: #fff;
}

#bootstrapoverride footer .inner-container input[type="email"]:-ms-input-placeholder {
  color: #fff;
}

#bootstrapoverride footer .inner-container .subscribe-btn {
  display: inline-block;
  float: left;
  width: 45px;
  height: 18px;
  border-radius: 10px;
  padding: 6px 8px 5px 8px;
  margin-left: 3px;
  font-size: 9px;
  line-height: 9px;
  color: #f0190f;
  background-color: #fff;
  display: block;
  text-align: center;
}

#bootstrapoverride footer .inner-container .subscribe-btn:hover {
  cursor: pointer;
}

#bootstrapoverride footer .social li {
  display: inline;
  margin: 0 2px;
}

@media all and (max-width: 500px) {
  #bootstrapoverride footer {
    padding: 40px 15px;
    z-index: 11;
  }
  #bootstrapoverride footer .inner-container {
    width: 100vw;
  }
  #bootstrapoverride footer .inner-container .col-md-3 {
    float: left;
    padding: 0px 20px 0px 0px;
    width: 33vw;
  }
  #bootstrapoverride footer .inner-container .col-md-3 h5 {
    font-size: 15px;
    margin-bottom: 20px;
  }
  #bootstrapoverride footer .inner-container .col-md-3 li {
    font-size: 13px;
    line-height: 2;
  }
  #bootstrapoverride footer .inner-container .col-md-3 li a {
    font-size: 13px;
    line-height: 2;
  }
  #bootstrapoverride footer .inner-container .col-md-3 .subscribe-btn {
    margin-top: 10px;
  }
  #bootstrapoverride footer .inner-container .col-md-3 .dense {
    letter-spacing: -1px;
  }
  #bootstrapoverride footer .inner-container input[type="email"] {
    width: 92px;
  }
  #bootstrapoverride footer .inner-container .footer-pic {
    float: right;
    width: 230px;
    margin: 42px 0px 0px 0px;
  }
}

#bootstrapoverride .bg-circle {
  position: absolute;
  z-index: 0;
  border-radius: 50%;
}

#bootstrapoverride .bg-circle.bg-yellow {
  background-color: #ffe100;
}

#bootstrapoverride .bg-circle.bg-yellow-2 {
  background-color: #ffe100;
}

#bootstrapoverride .bg-circle.bg-green {
  background-color: #64d200;
}

#bootstrapoverride .bg-circle.bg-blue {
  background-color: #00b9ff;
}

#bootstrapoverride .bg-circle.bg-blue-2 {
  background-color: #00b9ff;
}

#bootstrapoverride .bg-circle.bg-orange {
  background-color: #ff7f00;
}

#bootstrapoverride .bg-circle.bg-orange-2 {
  background-color: #ff7f00;
}

#bootstrapoverride .bg-circle.bg-red {
  background-color: #ea2224;
}

#bootstrapoverride .bg-circle.bg-red-2 {
  background-color: #f0190f;
}

#bootstrapoverride .bg-circle.bg-pink {
  background-color: #ff4191;
}

#bootstrapoverride .bg-circle.bg-pink-2 {
  background-color: #ff4191;
}

#bootstrapoverride .bg-circle.x-1 {
  width: 43px;
  height: 43px;
}

#bootstrapoverride .bg-circle.x-2 {
  width: 50px;
  height: 50px;
}

#bootstrapoverride .bg-circle.x-3 {
  width: 70px;
  height: 70px;
}

#bootstrapoverride .bg-circle.x-4 {
  width: 100px;
  height: 100px;
}

#bootstrapoverride .bg-circle.x-5 {
  width: 130px;
  height: 130px;
}

#bootstrapoverride .bg-circle.x-6 {
  width: 160px;
  height: 160px;
}

#bootstrapoverride .bg-circle.x-7 {
  width: 190px;
  height: 190px;
}

#bootstrapoverride .bg-circle.x-8 {
  width: 230px;
  height: 230px;
}

#bootstrapoverride .bg-circle.x-9 {
  width: 290px;
  height: 290px;
}

#bootstrapoverride .bg-circle.x-10 {
  width: 330px;
  height: 330px;
}

#bootstrapoverride .bg-circle.x-11 {
  width: 470px;
  height: 470px;
}

#bootstrapoverride .decoration-bg-story {
  opacity: 0.4;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .bg-circle.x-1 {
    width: 43px;
    height: 43px;
  }
  #bootstrapoverride .bg-circle.x-2 {
    width: 3vw;
    height: 3vw;
  }
  #bootstrapoverride .bg-circle.x-3 {
    width: 5vw;
    height: 5vw;
  }
  #bootstrapoverride .bg-circle.x-4 {
    width: 10vw;
    height: 10vw;
  }
  #bootstrapoverride .bg-circle.x-5 {
    width: 15vw;
    height: 15vw;
  }
  #bootstrapoverride .bg-circle.x-6 {
    width: 20vw;
    height: 20vw;
  }
  #bootstrapoverride .bg-circle.x-7 {
    width: 25vw;
    height: 25vw;
  }
  #bootstrapoverride .bg-circle.x-8 {
    width: 30vw;
    height: 30vw;
  }
  #bootstrapoverride .bg-circle.x-9 {
    width: 35vw;
    height: 35vw;
  }
  #bootstrapoverride .bg-circle.x-10 {
    width: 40vw;
    height: 40vw;
  }
  #bootstrapoverride .bg-circle.x-11 {
    width: 45vw;
    height: 45vw;
  }
  #bootstrapoverride .decoration-bg-story .bg-circle {
    opacity: 1;
  }
}

@keyframes floating {
  0%, 100% {
    transform: rotate(0deg) translate3d(-20px, 0px, 0px) rotate(-5deg);
  }
  50% {
    transform: rotate(-180deg) translate3d(-20px, 0px, 0px) rotate(180deg);
  }
}

.floating {
  animation-name: floating;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

@keyframes floating-cloud {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px);
  }
  50% {
    transform: translate3d(20px, 0px, 0px);
  }
}

@keyframes float-scale-06 {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px) scale(0.6);
  }
  50% {
    transform: translate3d(20px, 0px, 0px) scale(0.6);
  }
}

@keyframes float-scale-07 {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px) scale(0.7);
  }
  50% {
    transform: translate3d(20px, 0px, 0px) scale(0.7);
  }
}

@keyframes float-scale-08 {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px) scale(0.8);
  }
  50% {
    transform: translate3d(20px, 0px, 0px) scale(0.8);
  }
}

@keyframes float-scale-12 {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px) scale(1.2);
  }
  50% {
    transform: translate3d(20px, 0px, 0px) scale(1.2);
  }
}

@keyframes float-scale-14 {
  0%, 100% {
    transform: translate3d(-20px, 0px, 0px) scale(1.4);
  }
  50% {
    transform: translate3d(20px, 0px, 0px) scale(1.4);
  }
}

@keyframes flying-down {
  100% {
    transform: transalte(0, 0);
  }
}

@keyframes balloon-fly-1 {
  0% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translate(-200px, -500px);
    opacity: 0;
  }
}

@keyframes balloon-fly-2 {
  0% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translate(-20px, -500px);
    opacity: 0;
  }
}

@keyframes balloon-fly-3 {
  0% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translate(200px, -500px);
    opacity: 0;
  }
}

@keyframes balloon-fly-4 {
  0% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translate(400px, -500px);
    opacity: 0;
  }
}

.balloon-fly-1 {
  animation-name: balloon-fly-1;
  animation-timing-function: cubic-bezier(0.57, 0.08, 0.84, 0.87);
  animation-iteration-count: 1;
  animation-duration: 2.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.balloon-fly-2 {
  animation-name: balloon-fly-2;
  animation-timing-function: cubic-bezier(0.57, 0.08, 0.84, 0.87);
  animation-iteration-count: 1;
  animation-duration: 2.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.balloon-fly-3 {
  animation-name: balloon-fly-3;
  animation-timing-function: cubic-bezier(0.57, 0.08, 0.84, 0.87);
  animation-iteration-count: 1;
  animation-duration: 2.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

.balloon-fly-4 {
  animation-name: balloon-fly-4;
  animation-timing-function: cubic-bezier(0.57, 0.08, 0.84, 0.87);
  animation-iteration-count: 1;
  animation-duration: 2.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes cloud-float {
  0%, 100% {
    transform: translate(10px, 0);
  }
  50% {
    transform: translate(-10px, 0);
  }
}

.cloud-float {
  animation-name: cloud-float;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

.fly-to-right {
  transform: translate3d(300px, 0px, 0px) !important;
  transition: transform 2s ease-in-out;
}

.fly-to-middle {
  transform: translate3d(50px, 0px, 0px) !important;
  transition: transform 2s ease-in-out;
}

@keyframes bear-landing {
  0% {
    position: absolute;
    top: 8280px;
    left: 80px;
  }
  100% {
    position: absolute;
    top: 8310px;
    left: 70px;
  }
}

.bear-landing {
  animation-name: bear-landing, family-sway;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-duration: 1s, 2s;
  animation-delay: 0, 0;
  animation-fill-mode: forwards, none;
}

@keyframes family-sway {
  0%, 100% {
    transform: rotate(-3deg);
    transform-origin: 50% 100%;
  }
  50% {
    transform: rotate(3deg);
    transform-origin: 50% 100%;
  }
}

.family-sway {
  animation-name: family-sway;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

.family-opening-left-1 {
  left: 470px !important;
  transition: left 2s ease-in-out;
}

.family-opening-left-2 {
  left: 570px !important;
  transition: left 2s ease-in-out;
}

.family-opening-left-3 {
  left: 630px !important;
  transition: left 2s ease-in-out;
}

.family-opening-right-1 {
  left: 810px !important;
  transition: left 2s ease-in-out;
}

.family-opening-right-2 {
  left: 890px !important;
  transition: left 2s ease-in-out;
}

.popup {
  transform: scale(1) !important;
  transition: transform 0.7s cubic-bezier(0.42, 0.05, 0.56, 1.58);
}

.fly-from-left {
  transform: translateX(0) !important;
  transition: transform 2s cubic-bezier(0.06, 0.53, 0.47, 0.93);
}

.fly-from-right {
  transform: translateX(0) !important;
  transition: transform 2s cubic-bezier(0.06, 0.53, 0.47, 0.93);
}

.push-out-left {
  transform: translateX(-800px);
}

.push-out-right {
  transform: translateX(800px);
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.pulse-1 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

.pulse-2 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-fill-mode: none;
}

.pulse-3 {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: none;
}

@keyframes dolphin-jump {
  0% {
    transform: rotate(0deg) translate(0) rotate(0deg);
  }
  100% {
    transform: rotate(45deg) translate(-350px) rotate(-45deg);
  }
}

.dolphin-jump {
  animation-name: dolphin-jump;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes raise-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}

@keyframes bouncing {
  0%, 100% {
    top: 130px;
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
}

.raise-heart {
  animation-name: raise-up, bouncing;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
  animation-duration: 2s, 2s;
  animation-delay: 0s, 2s;
}

@keyframes sparkling {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.sparkling img {
  animation-name: sparkling;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes winking {
  0% {
    z-index: 1;
  }
  100% {
    z-index: -1;
  }
}

@media all and (max-width: 500px) {
  .family-opening-left-1 {
    left: 32vw !important;
  }
  .family-opening-left-2 {
    left: 39vw !important;
  }
  .family-opening-left-3 {
    left: 50vw !important;
  }
  .family-opening-right-1 {
    left: 66vw !important;
  }
  .family-opening-right-2 {
    left: 74vw !important;
  }
  .bear-absolute {
    position: absolute !important;
    top: 6850px !important;
    left: 4vw !important;
  }
  .fly-to-right {
    transform: translate3d(33vw, 0px, 0px) !important;
    transition: transform 2s ease-in-out;
  }
  .fly-to-middle {
    transform: translate3d(0vw, 0px, 0px) !important;
    transition: transform 2s ease-in-out;
  }
}

#bootstrapoverride .cart-view-container {
  position: absolute;
  top: 100px;
  right: 20px;
  width: 510px;
  background-color: #fff;
  border: 6px solid #f0190f;
  border-radius: 20px;
  z-index: 100;
}

#bootstrapoverride .cart-view-container table.table-head {
  height: 90px;
}

#bootstrapoverride .cart-view-container table.table-head tr {
  border-bottom: 6px solid #f0190f;
}

#bootstrapoverride .cart-view-container table.table-head th {
  font-weight: bold;
  font-size: 16px;
}

#bootstrapoverride .cart-view-container .table-body {
  height: 350px;
  overflow: scroll;
}

#bootstrapoverride .cart-view-container .table-body tr {
  border-bottom: 2px dashed #f0190f;
  height: 80px;
}

#bootstrapoverride .cart-view-container table {
  width: 100%;
  text-align: center;
}

#bootstrapoverride .cart-view-container table th,
#bootstrapoverride .cart-view-container table td {
  width: 18%;
  text-align: center;
  vertical-align: middle;
}

#bootstrapoverride .cart-view-container table th {
  color: #f0190f;
}

#bootstrapoverride .cart-view-container table .item-info span,
#bootstrapoverride .cart-view-container table .discount-info span {
  display: block;
}

#bootstrapoverride .cart-view-container .checkout-container {
  height: 100px;
  text-align: center;
}

#bootstrapoverride .cart-view-container .checkout-container .checkout-btn {
  display: inline-block;
  width: 145px;
  height: 35px;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #f0190f;
  margin-top: 30px;
  padding: 5px 0;
}

.decoration-bg-home {
  position: relative;
  width: 1400px;
  z-index: 0;
  margin: 0 auto;
}

.decoration-bg-home div.bg-circle {
  z-index: 0;
}

.decoration-bg-home div.bg-circle:nth-of-type(1) {
  top: 20px;
  left: 400px;
}

.decoration-bg-home div.bg-circle:nth-of-type(2) {
  top: 240px;
  left: 1290px;
}

.decoration-bg-home div.bg-circle:nth-of-type(3) {
  top: 820px;
  left: 720px;
}

.decoration-bg-home div.bg-circle:nth-of-type(4) {
  top: 940px;
  left: 260px;
}

.decoration-bg-home div.bg-circle:nth-of-type(5) {
  top: 340px;
  left: 60px;
}

.decoration-bg-home div.bg-circle:nth-of-type(6) {
  top: 30px;
  left: 85px;
}

.decoration-bg-home div.bg-circle:nth-of-type(7) {
  top: 10px;
  left: 1170px;
}

.decoration-bg-home div.bg-circle:nth-of-type(8) {
  top: 800px;
  left: 1145px;
}

.decoration-bg-home div.bg-circle:nth-of-type(9) {
  top: 955px;
  left: 760px;
}

.decoration-bg-home div.bg-circle:nth-of-type(10) {
  top: 975px;
  left: 1210px;
}

.decoration-bg-home div.bg-circle:nth-of-type(11) {
  top: 840px;
  left: -40px;
}

#bootstrapoverride .home-container {
  position: relative;
  width: 1400px;
  height: 1055px;
  margin: 0 auto;
  z-index: 10;
}

#bootstrapoverride .home-container .circle {
  display: inline-block;
  position: absolute;
  background-color: #d8d8d8;
  border-radius: 50%;
}

#bootstrapoverride .home-container .circle .bubble {
  position: absolute;
}

#bootstrapoverride .home-container .bear-img, #bootstrapoverride .home-container .bear-img-2 {
  position: absolute;
  top: 370px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#bootstrapoverride .home-container .bear-img-2 {
  animation-name: winking;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

#bootstrapoverride .home-container .bubble {
  top: -45px;
  right: 10px;
}

#bootstrapoverride .home-container #about-circle {
  top: 80px;
  left: 185px;
}

#bootstrapoverride .home-container #about-circle > div {
  background-image: url("../img/home/home_center_who.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 270px;
  height: 270px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #newarrival-circle {
  top: 20px;
  left: 570px;
}

#bootstrapoverride .home-container #newarrival-circle > div {
  background-image: url("../img/home/home_center_newarrival.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 310px;
  height: 310px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #story-circle {
  top: 80px;
  left: 900px;
}

#bootstrapoverride .home-container #story-circle > div {
  background-image: url("../img/home/home_center_storybook.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 240px;
  height: 240px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #newclothes-circle {
  top: 390px;
  left: 1030px;
}

#bootstrapoverride .home-container #newclothes-circle > div {
  background-image: url("../img/home/home_center_newclothes.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 310px;
  height: 310px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #share-circle {
  top: 680px;
  left: 795px;
}

#bootstrapoverride .home-container #share-circle > div {
  background-image: url("../img/home/home_center_loveshare.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 240px;
  height: 240px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #visit-circle {
  top: 700px;
  left: 390px;
}

#bootstrapoverride .home-container #visit-circle > div {
  background-image: url("../img/home/home_center_where.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 240px;
  height: 240px;
  border-radius: 50%;
}

#bootstrapoverride .home-container #toy-circle {
  top: 450px;
  left: 60px;
}

#bootstrapoverride .home-container #toy-circle > div {
  background-image: url("../img/home/home_center_toy.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 310px;
  height: 310px;
  border-radius: 50%;
}

@media all and (max-width: 500px) {
  .decoration-bg-home {
    position: relative;
    width: 100vw;
    z-index: 0;
    margin: 0 auto;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(1) {
    width: 7vw !important;
    height: 7vw !important;
    top: 85px;
    left: 80vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(2) {
    width: 7vw !important;
    height: 7vw !important;
    top: 240px;
    left: 55vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(3) {
    width: 25vw !important;
    height: 25vw !important;
    top: 203px;
    left: -10vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(4) {
    width: 15vw !important;
    height: 15vw !important;
    top: 268px;
    left: 20vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(5) {
    width: 5vw !important;
    height: 5vw !important;
    top: 429px;
    left: 80vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(6) {
    width: 7vw !important;
    height: 7vw !important;
    top: 518px;
    left: 60vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(7) {
    width: 7vw !important;
    height: 7vw !important;
    top: 664px;
    left: 15vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(8) {
    width: 13vw !important;
    height: 13vw !important;
    top: 714px;
    left: 2vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(9) {
    width: 20vw !important;
    height: 20vw !important;
    top: 770px;
    left: 80vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(10) {
    width: 10vw !important;
    height: 10vw !important;
    top: 906px;
    left: 10vw;
  }
  .decoration-bg-home div.bg-circle:nth-of-type(11) {
    width: 13vw !important;
    height: 13vw !important;
    top: 972px;
    left: -2px;
  }
  #bootstrapoverride .home-container {
    width: 100vw;
    min-height: 1330px;
    overflow: hidden;
  }
  #bootstrapoverride .home-container .bear-img, #bootstrapoverride .home-container .bear-img-2 {
    top: 370px;
    width: 250px;
    height: 144px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  #bootstrapoverride .home-container .bubble {
    width: 90px;
  }
  #bootstrapoverride .home-container #about-circle {
    top: 83px;
    left: 10vw;
  }
  #bootstrapoverride .home-container #about-circle > div {
    width: 28vw;
    height: 28vw;
  }
  #bootstrapoverride .home-container #newarrival-circle {
    top: 148px;
    left: 45vw;
  }
  #bootstrapoverride .home-container #newarrival-circle > div {
    width: 50vw;
    height: 50vw;
  }
  #bootstrapoverride .home-container #story-circle {
    top: 508px;
    left: 10vw;
  }
  #bootstrapoverride .home-container #story-circle > div {
    width: 30vw;
    height: 30vw;
  }
  #bootstrapoverride .home-container #newclothes-circle {
    top: 597px;
    left: 50vw;
  }
  #bootstrapoverride .home-container #newclothes-circle > div {
    width: 45vw;
    height: 45vw;
  }
  #bootstrapoverride .home-container #share-circle {
    top: 1005px;
    left: 20vw;
  }
  #bootstrapoverride .home-container #share-circle > div {
    width: 60vw;
    height: 60vw;
  }
  #bootstrapoverride .home-container #visit-circle {
    top: 881px;
    left: 60vw;
  }
  #bootstrapoverride .home-container #visit-circle > div {
    width: 30vw;
    height: 30vw;
  }
  #bootstrapoverride .home-container #toy-circle {
    top: 788px;
    left: 15vw;
  }
  #bootstrapoverride .home-container #toy-circle > div {
    width: 35vw;
    height: 35vw;
  }
}

.decoration-bg-story {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 1400px;
  margin: 0 auto;
  z-index: -9999;
}

.decoration-bg-story .bg-circle:nth-child(1) {
  top: -130px;
  left: 40px;
}

.decoration-bg-story .bg-circle:nth-child(2) {
  top: -110px;
  left: 900px;
}

.decoration-bg-story .bg-circle:nth-child(3) {
  top: 245px;
  left: 85px;
}

.decoration-bg-story .bg-circle:nth-child(4) {
  top: 215px;
  left: 1150px;
}

.decoration-bg-story .bg-circle:nth-child(5) {
  top: 335px;
  left: 1025px;
}

.decoration-bg-story .bg-circle:nth-child(6) {
  top: 390px;
  left: 490px;
}

.decoration-bg-story .bg-circle:nth-child(7) {
  top: 520px;
  left: 220px;
}

.decoration-bg-story .bg-circle:nth-child(8) {
  top: 495px;
  left: 1240px;
}

.decoration-bg-story .bg-circle:nth-child(9) {
  top: 465px;
  left: -400px;
}

.decoration-bg-story .bg-circle:nth-child(10) {
  top: 1160px;
  left: 1290px;
}

.decoration-bg-story .bg-circle:nth-child(11) {
  top: 1490px;
  left: 485px;
}

.decoration-bg-story .bg-circle:nth-child(n+11) {
  display: none;
}

#bootstrapoverride .story-container {
  position: relative;
  width: 900px;
  margin: 40px auto 80px;
  z-index: 10;
}

#bootstrapoverride .story-container .introduction {
  position: relative;
}

#bootstrapoverride .story-container .introduction .text-align-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 25px;
}

#bootstrapoverride .story-container .introduction .text-align-bottom h2 {
  font-size: 26px;
  line-height: 39px;
}

#bootstrapoverride .story-container .books {
  margin: 80px 0 120px;
}

#bootstrapoverride .story-container .books .col-sm-3 {
  margin-bottom: 45px;
}

#bootstrapoverride .story-container .books .col-sm-6 {
  text-align: right;
}

#bootstrapoverride .story-container .img-wrapper {
  width: 175px;
  height: 190px;
}

#bootstrapoverride .story-book-container {
  position: relative;
  width: 1100px;
  margin: 40px auto 80px;
  z-index: 10;
}

#bootstrapoverride .story-book-container .book-bg {
  width: 100%;
  padding: 80px 50px;
  background-color: #fff1dc;
}

#bootstrapoverride .story-book-container .book-bg .book-container {
  width: 100%;
  height: 650px;
  background-color: white;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.05);
}

@media all and (max-width: 500px) {
  .decoration-bg-story .bg-circle:nth-child(1) {
    top: 0px;
    left: 5vw;
    background-color: #ff4191 !important;
  }
  .decoration-bg-story .bg-circle:nth-child(2) {
    top: 110px;
    left: 90vw;
    background-color: #ffe100 !important;
  }
  .decoration-bg-story .bg-circle:nth-child(3) {
    top: 200px;
    left: 66vw;
    width: 30vw !important;
    height: 30vw !important;
    background-color: #64d200 !important;
    display: block !important;
  }
  .decoration-bg-story .bg-circle:nth-child(4) {
    top: 270px;
    left: 10vw;
    width: 20vw !important;
    height: 20vw !important;
    background-color: #00b9ff !important;
    display: block !important;
  }
  .decoration-bg-story .bg-circle:nth-child(5) {
    top: 200px;
    left: 2vw;
    width: 8vw !important;
    height: 8vw !important;
    display: block !important;
  }
  .decoration-bg-story .bg-circle:nth-child(6) {
    top: 400px;
    left: 93vw;
    background-color: #64d200 !important;
  }
  .decoration-bg-story .bg-circle:nth-child(7) {
    top: 520px;
    left: -3vw;
    width: 15vw !important;
    height: 15vw !important;
    background-color: #ff7bac !important;
  }
  .decoration-bg-story .bg-circle:nth-child(8) {
    top: 660px;
    left: 90vw;
    background-color: #ffe100 !important;
  }
  .decoration-bg-story .bg-circle:nth-child(9) {
    top: 680px;
    left: -30vw;
    width: 40vw !important;
    height: 40vw !important;
  }
  .decoration-bg-story .bg-circle:nth-child(10) {
    top: 880px;
    left: 85vw;
    height: 5vw !important;
    width: 5vw !important;
    background-color: #ff7bac !important;
  }
  .decoration-bg-story .bg-circle:nth-child(11) {
    top: 920px;
    left: 90vw;
    background-color: #3fa9f6 !important;
  }
  .decoration-bg-story .bg-circle:nth-child(n+11) {
    display: block;
  }
  .decoration-bg-story .bg-circle:nth-child(12) {
    top: 1050px;
    left: -2vw;
  }
  .decoration-bg-story .bg-circle:nth-child(13) {
    top: 1200px;
    left: -20vw;
  }
  .decoration-bg-story .bg-circle:nth-child(14) {
    top: 1260px;
    left: 80vw;
  }
  .decoration-bg-story .bg-circle:nth-child(15) {
    top: 1360px;
    left: 10vw;
  }
  .decoration-bg-story .bg-circle:nth-child(16) {
    top: 1500px;
    left: 80vw;
  }
  .decoration-bg-story .bg-circle:nth-child(17) {
    top: 1750px;
    left: 85vw;
  }
  .decoration-bg-story .bg-circle:nth-child(18) {
    top: 1800px;
    left: 15vw;
  }
  .decoration-bg-story .bg-circle:nth-child(19) {
    top: 1930px;
    left: -5vw;
  }
  .decoration-bg-story .bg-circle:nth-child(20) {
    top: 2040px;
    left: 15vw;
  }
  .decoration-bg-story .bg-circle:nth-child(21) {
    top: 2100px;
    left: 80vw;
  }
  .decoration-bg-story .bg-circle:nth-child(22) {
    top: 2250px;
    left: 90vw;
  }
  .decoration-bg-story .bg-circle:nth-child(23) {
    top: 2330px;
    left: -10vw;
  }
  .decoration-bg-story .bg-circle:nth-child(24) {
    top: 2470px;
    left: 95vw;
  }
  .decoration-bg-story .bg-circle:nth-child(25) {
    top: 2650px;
    left: 90vw;
  }
  .decoration-bg-story .bg-circle:nth-child(26) {
    top: 2700px;
    left: -20vw;
  }
  .decoration-bg-story .bg-circle:nth-child(27) {
    top: 2880px;
    left: 4vw;
  }
  #bootstrapoverride .story-container {
    width: 100vw;
  }
  #bootstrapoverride .story-container .introduction {
    margin-bottom: 0px;
    margin-top: 70px;
  }
  #bootstrapoverride .story-container .introduction .text-align-bottom {
    position: relative;
    text-align: center;
    font-size: 14px;
    width: 273px;
    margin: 5px auto;
  }
  #bootstrapoverride .story-container .introduction .text-align-bottom h2 {
    font-size: 26px;
    line-height: 39px;
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: 1.2px;
  }
  #bootstrapoverride .story-container .introduction .col-sm-5 img {
    width: 90%;
    margin-left: 5%;
  }
  #bootstrapoverride .story-container .books {
    margin: 80px auto 120px;
    width: 220px;
  }
  #bootstrapoverride .story-container .books .col-sm-3 {
    margin-bottom: 45px;
  }
  #bootstrapoverride .story-container .books .col-sm-6 {
    text-align: right;
  }
  #bootstrapoverride .story-container .books .col-sm-6 img {
    width: 100%;
  }
  #bootstrapoverride .story-book-container {
    width: 100vw;
  }
}

.decoration-bg-visit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 1400px;
  margin: 0 auto;
  z-index: 0;
}

.decoration-bg-visit .bg-circle {
  opacity: .4;
}

.decoration-bg-visit .bg-circle:nth-child(1) {
  top: 195px;
  left: 480px;
}

.decoration-bg-visit .bg-circle:nth-child(2) {
  top: 1100px;
  left: 100px;
}

.decoration-bg-visit .bg-circle:nth-child(3) {
  top: 1240px;
  left: 1125px;
}

.decoration-bg-visit .bg-circle:nth-child(4) {
  top: 1485px;
  left: -80px;
}

.decoration-bg-visit .bg-circle:nth-child(5) {
  top: 1600px;
  left: 1230px;
}

.decoration-bg-visit .bg-circle:nth-child(6) {
  top: 1825px;
  left: 170px;
}

.decoration-bg-visit .bg-circle:nth-child(7) {
  top: 2080px;
  left: 1105px;
}

.decoration-bg-visit .bg-circle:nth-child(8) {
  top: 2265px;
  left: 25px;
}

.decoration-bg-visit .bg-circle:nth-child(9) {
  top: 2460px;
  left: 1265px;
}

.decoration-bg-visit .bg-circle:nth-child(10) {
  top: 2700px;
  left: 75px;
}

.decoration-bg-visit .bg-circle:nth-child(11) {
  top: 2835px;
  left: 1145px;
}

.decoration-bg-visit .bg-circle:nth-child(12) {
  top: 2985px;
  left: 150px;
}

.decoration-bg-visit .bg-circle:nth-child(13) {
  top: 3190px;
  left: 1265px;
}

.decoration-bg-visit .bg-circle:nth-child(14) {
  top: 3315px;
  left: -80px;
}

.decoration-bg-visit .bg-circle:nth-child(15) {
  top: 3675px;
  left: 1120px;
}

.decoration-bg-visit-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.decoration-bg-visit-detail .bg-circle {
  opacity: .4;
}

.decoration-bg-visit-detail .bg-circle:nth-child(1) {
  top: 325px;
  left: 120px;
}

.decoration-bg-visit-detail .bg-circle:nth-child(2) {
  top: 420px;
  left: 1200px;
}

.decoration-bg-visit-detail .bg-circle:nth-child(3) {
  top: 720px;
  left: -75px;
}

.decoration-bg-visit-detail .bg-circle:nth-child(4) {
  top: 800px;
  left: 1220px;
}

.decoration-bg-visit-detail .bg-circle:nth-child(5) {
  top: 960px;
  left: 850px;
}

.decoration-bg-visit-detail .bg-circle:nth-child(6) {
  top: 1060px;
  left: 390px;
}

#bootstrapoverride .visit-container {
  position: relative;
  width: 900px;
  margin: 40px auto 80px;
  z-index: 10;
}

#bootstrapoverride .visit-container ol.carousel-indicators {
  bottom: -50px;
  left: auto;
  right: 0px;
  width: 100%;
}

#bootstrapoverride .visit-container ol.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-color: #f0190f;
  margin: 0 7px;
  float: right;
}

#bootstrapoverride .visit-container ol.carousel-indicators li.active {
  background-color: #f0190f;
}

#bootstrapoverride .visit-container .visit-nav {
  margin: 65px 0 30px;
}

#bootstrapoverride .visit-container .visit-nav li {
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  text-align: center;
  border-radius: 50%;
  border: 3px solid;
  transition: background-color .3s;
}

#bootstrapoverride .visit-container .visit-nav li a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: calc(55px - 14px);
  font-size: 18px;
  font-weight: bold;
  border-radius: 50%;
  transition: color .3s;
}

#bootstrapoverride .visit-container .visit-nav .red {
  border-color: #f0190f;
}

#bootstrapoverride .visit-container .visit-nav .red a {
  color: #f0190f;
}

#bootstrapoverride .visit-container .visit-nav .red:hover {
  background-color: #f0190f;
}

#bootstrapoverride .visit-container .visit-nav .red:hover a {
  color: #fff;
}

#bootstrapoverride .visit-container .visit-nav .green {
  border-color: #64d200;
}

#bootstrapoverride .visit-container .visit-nav .green a {
  color: #64d200;
}

#bootstrapoverride .visit-container .visit-nav .green:hover {
  background-color: #64d200;
}

#bootstrapoverride .visit-container .visit-nav .green:hover a {
  color: #fff;
}

#bootstrapoverride .visit-container .visit-nav .orange {
  border-color: #ff7f00;
}

#bootstrapoverride .visit-container .visit-nav .orange a {
  color: #ff7f00;
}

#bootstrapoverride .visit-container .visit-nav .orange:hover {
  background-color: #ff7f00;
}

#bootstrapoverride .visit-container .visit-nav .orange:hover a {
  color: #fff;
}

#bootstrapoverride .visit-container .visit-nav .blue {
  border-color: #00b9ff;
}

#bootstrapoverride .visit-container .visit-nav .blue a {
  color: #00b9ff;
}

#bootstrapoverride .visit-container .visit-nav .blue:hover {
  background-color: #00b9ff;
}

#bootstrapoverride .visit-container .visit-nav .blue:hover a {
  color: #fff;
}

#bootstrapoverride .visit-container .img-wrapper {
  width: 900px;
  height: 600px;
}

#bootstrapoverride .visit-container .img-wrapper img {
  position: absolute;
  height: auto !important;
  width: auto !important;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
}

#bootstrapoverride .visit-container .store-list {
  position: relative;
  width: 100%;
  margin-bottom: 35px;
  padding: 40px 90px;
  border: 4px solid;
  border-radius: 20px;
  background-color: #fff;
}

#bootstrapoverride .visit-container .store-list h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

#bootstrapoverride .visit-container .store-list li {
  margin: 10px 0;
  font-size: 15px;
}

#bootstrapoverride .visit-container .store-list .break {
  display: inline-block;
  width: 100%;
  height: 30px;
}

#bootstrapoverride .visit-container .div-to-top-btn {
  width: 85px;
  height: 26px;
  padding: 3px 19px 3px 32px;
  border-radius: 30px;
  position: relative;
  float: right;
  color: white;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}

#bootstrapoverride .visit-container .div-to-top-btn:before {
  position: absolute;
  content: "";
  left: 14px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent white transparent;
}

#bootstrapoverride .visit-container .stores-1 {
  border-color: #f0190f;
}

#bootstrapoverride .visit-container .stores-1 .div-to-top-btn {
  background-color: #f0190f;
}

#bootstrapoverride .visit-container .stores-1:before {
  display: block;
  position: absolute;
  content: "";
  top: -78px;
  right: 0;
  width: 205px;
  height: 90px;
  background-image: url("../img/visit/container-righttop-redhead.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container .stores-1:before {
    top: -53px;
    width: 125px;
    height: 59px;
  }
}

#bootstrapoverride .visit-container .stores-2 {
  border-color: #64d200;
}

#bootstrapoverride .visit-container .stores-2 .div-to-top-btn {
  background-color: #64d200;
}

#bootstrapoverride .visit-container .stores-3 {
  border-color: #ff7f00;
}

#bootstrapoverride .visit-container .stores-3 .div-to-top-btn {
  background-color: #ff7f00;
}

#bootstrapoverride .visit-container .stores-4 {
  border-color: #00b9ff;
}

#bootstrapoverride .visit-container .stores-4 .div-to-top-btn {
  background-color: #00b9ff;
}

#bootstrapoverride .visit-container .stores-4:after {
  display: block;
  position: absolute;
  content: "";
  top: 90px;
  left: -170px;
  width: 220px;
  height: 295px;
  background-image: url("../img/visit/container-lower-left-bear.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#bootstrapoverride .visit-container .in-branch:after {
  display: none;
}

#bootstrapoverride .visit-container .store-list.store-detail {
  margin-top: 90px;
}

#bootstrapoverride .visit-container .store-list.store-detail .store-name {
  width: 470px;
  margin: 0 auto 30px;
  padding-bottom: 30px;
  text-align: center;
}

#bootstrapoverride .visit-container .store-list.store-detail .store-name h2 {
  font-size: 22px;
  font-weight: bold;
  color: #64d200;
}

#bootstrapoverride .visit-container .store-list.store-detail .row {
  margin-bottom: 45px;
}

#bootstrapoverride .visit-container .store-list.store-detail .col-sm-2 {
  border-right: 2px solid #595757;
  padding-right: 0;
}

#bootstrapoverride .visit-container .store-list.store-detail .col-sm-6 {
  padding-left: 25px;
}

#bootstrapoverride .visit-container .store-list.store-detail .col-sm-6 p {
  display: inline-block;
  margin-bottom: 20px;
}

#bootstrapoverride .visit-container .store-list.store-detail .info p {
  font-size: 18px;
  font-weight: bold;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-1 .store-name {
  border-bottom: 4px solid #f0190f;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-1 .store-name h2 {
  color: #f0190f;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-2 .store-name {
  border-bottom: 4px solid #64d200;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-2 .store-name h2 {
  color: #64d200;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-3 .store-name {
  border-bottom: 4px solid #ff7f00;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-3 .store-name h2 {
  color: #ff7f00;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-4 .store-name {
  border-bottom: 4px solid #00b9ff;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-4 .store-name h2 {
  color: #00b9ff;
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-1:before {
  display: block;
  position: absolute;
  content: "";
  top: -78px;
  right: 0;
  width: 205px;
  height: 90px;
  background-image: url("../img/visit/container-righttop-redhead.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container .store-list.store-detail.stores-1:before {
    top: -53px;
    width: 125px;
    height: 59px;
  }
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-2:before {
  display: block;
  position: absolute;
  content: "";
  top: -78px;
  right: 0;
  width: 205px;
  height: 90px;
  background-image: url("../img/visit/container-righttop-greenhead.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container .store-list.store-detail.stores-2:before {
    top: -53px;
    width: 125px;
    height: 59px;
  }
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-3:before {
  display: block;
  position: absolute;
  content: "";
  top: -78px;
  right: 0;
  width: 205px;
  height: 90px;
  background-image: url("../img/visit/container-righttop-orangehead.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container .store-list.store-detail.stores-3:before {
    top: -53px;
    width: 125px;
    height: 59px;
  }
}

#bootstrapoverride .visit-container .store-list.store-detail.stores-4:before {
  display: block;
  position: absolute;
  content: "";
  top: -78px;
  right: 0;
  width: 205px;
  height: 90px;
  background-image: url("../img/visit/container-righttop-bluehead.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container .store-list.store-detail.stores-4:before {
    top: -53px;
    width: 125px;
    height: 59px;
  }
}

#bootstrapoverride .visit-container .map-container {
  width: 100%;
  height: 306px;
  margin-bottom: 80px;
}

#bootstrapoverride .visit-container .map-container iframe {
  height: 150px;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .visit-container {
    width: 100vw;
  }
  #bootstrapoverride .visit-container .img-wrapper {
    width: 100vw;
    height: 300px;
  }
  #bootstrapoverride .visit-container .visit-nav {
    margin: 65px auto 30px;
    width: 298px;
  }
  #bootstrapoverride .visit-container .visit-nav li {
    width: 65px;
    height: 65px;
    margin-right: 6px;
    font-size: 11px;
  }
  #bootstrapoverride .visit-container .visit-nav li a {
    font-size: 11px;
    padding-top: 20px;
  }
  #bootstrapoverride .visit-container .store-list {
    width: 90vw;
    padding: 14px 20px;
    margin: 0px auto;
  }
  #bootstrapoverride .visit-container .store-list-mobile {
    display: none;
  }
  #bootstrapoverride .visit-container .stores-1:before {
    display: none;
  }
  #bootstrapoverride .visit-container .stores-4:after {
    display: none;
  }
  #bootstrapoverride .visit-container .store-list.store-detail {
    margin-top: 150px;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .store-name {
    width: 77vw;
    margin: 0 auto 30px;
    padding-bottom: 0px;
    text-align: center;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .store-name h2 {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #64d200;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .row {
    margin-bottom: 10px;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .col-sm-2 {
    border-right: none;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .col-sm-6 {
    padding-left: 15px;
    font-size: 13px;
  }
  #bootstrapoverride .visit-container .store-list.store-detail .info p {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  #bootstrapoverride .visit-container .map-container {
    height: 150px;
    margin-bottom: 30px;
  }
  #bootstrapoverride .visit-container .map-container iframe {
    height: 150px;
  }
  .decoration-bg-visit-detail .bg-circle {
    opacity: .4;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(1) {
    top: 120px;
    left: 2vw;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(2) {
    top: 220px;
    left: 85px;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(3) {
    top: 330px;
    left: -15vw;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(4) {
    top: 430px;
    left: 97vw;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(5) {
    top: 580px;
    left: 65vw;
  }
  .decoration-bg-visit-detail .bg-circle:nth-child(6) {
    top: 640px;
    left: 15vw;
  }
}

#bootstrapoverride .newclothes-container {
  position: relative;
  width: 1100px;
  margin: 0 auto;
  padding-top: 65px;
}

#bootstrapoverride .newclothes-container .catalogue-outer-container {
  width: 100%;
  padding-top: 65px;
  margin-bottom: 60px;
  background-color: #ffe100;
}

#bootstrapoverride .newclothes-container .catalogue-container {
  width: 90%;
  height: 790px;
  margin: 0 auto 65px;
  box-shadow: 0 0 25px 8px rgba(0, 0, 0, 0.3);
}

#bootstrapoverride .newclothes-container .catalogue-description {
  width: 100%;
  background-color: #fff9cc;
  padding: 5px 45px;
}

#bootstrapoverride .newclothes-container .catalogue-description h2 {
  font-size: 24px;
  line-height: 34px;
  color: #fa5f0f;
}

#bootstrapoverride .newclothes-container .catalogue-collection {
  width: 1000px;
  margin: 0 auto 100px;
}

#bootstrapoverride .newclothes-container .catalogue-collection .col-sm-12 {
  margin-bottom: 15px;
}

#bootstrapoverride .newclothes-container .catalogue-collection .category-name {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 25px;
  font-weight: bold;
  color: #fff;
  background-color: #ff4191;
}

#bootstrapoverride .newclothes-container .img-wrapper {
  width: 225px;
  height: 280px;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .newclothes-container {
    width: 100vw;
    padding-top: 100px;
  }
  #bootstrapoverride .newclothes-container .catalogue-outer-container {
    display: none;
  }
  #bootstrapoverride .newclothes-container .catalogue-collection {
    width: 220px;
  }
  #bootstrapoverride .newclothes-container .catalogue-collection .col-sm-12 {
    margin-bottom: 15px;
  }
  #bootstrapoverride .newclothes-container .catalogue-collection .category-name {
    padding: 2px 5px;
    font-size: 13px;
  }
  #bootstrapoverride .newclothes-container .img-wrapper {
    width: 156px;
    height: 194px;
    margin-bottom: 5px;
  }
}

#bootstrapoverride .shop-container {
  max-width: 1200px;
  margin: 30px auto 100px;
}

#bootstrapoverride .shop-container .shop-nav-container {
  float: left;
  width: 200px;
  padding-left: 30px;
}

#bootstrapoverride .shop-container .shop-nav-container li {
  cursor: pointer;
  margin-bottom: 5px;
  position: relative;
}

#bootstrapoverride .shop-container .shop-nav-container .active-li .active-dec {
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 15px;
  top: -1px;
  left: -29px;
  position: absolute;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category {
  position: relative;
  margin-bottom: 10px;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category > span {
  font-size: 18px;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category > span a {
  font-size: 18px;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category .color-node, #bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-boy, #bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-girl {
  position: absolute;
  top: 3px;
  left: -25px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: transparent;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-boy {
  background-color: #80d4ff;
}

#bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-girl {
  background-color: #ff8cb4;
}

#bootstrapoverride .shop-container .shop-nav-container .sub-category {
  display: none;
  margin: 10px 0 10px 15px;
}

#bootstrapoverride .shop-container .shop-nav-container .sub-category a {
  font-size: 14px;
  position: relative;
}

#bootstrapoverride .shop-container .shop-nav-container .sub-category.active {
  display: block;
}

#bootstrapoverride .shop-container .product-list-container {
  float: left;
  width: calc(100% - 220px);
}

#bootstrapoverride .shop-container .product-list-container .shop-home-pic {
  position: relative;
  margin: 11px;
  background-color: #d8d8d8;
  float: left;
  overflow: hidden;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-pic img {
  width: inherit;
  height: inherit;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-pic .intro {
  position: absolute;
  height: 135px;
  bottom: -135px;
  background-color: #ee8ab6;
  transition: all .1s ease-in-out;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2.3px;
  line-height: 1.5;
  color: #ffffff;
  padding: 40px 50px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-pic:hover .intro {
  bottom: 0px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-one-pic {
  width: 913px;
  height: 550px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-one-pic .intro {
  width: 913px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-two-pic {
  width: 446px;
  height: 568px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-two-pic .intro {
  width: 446px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-three-pic {
  width: 290px;
  height: 280px;
}

#bootstrapoverride .shop-container .product-list-container .shop-home-three-pic .intro {
  width: 290px;
}

#bootstrapoverride .shop-container .product-list-container .shop-banner-container-home {
  width: 913px;
  height: 550px;
  background-color: #d8d8d8;
  margin: 11px 11px 60px 11px;
}

#bootstrapoverride .shop-container .product-list-container .shop-banner-container-home img {
  width: 913px;
  height: 550px;
}

#bootstrapoverride .shop-container .product-list-container .shop-banner-container {
  width: 980px;
  height: 590px;
  background-color: #d8d8d8;
  margin-bottom: 60px;
}

#bootstrapoverride .shop-container .product-list-container .shop-banner-container img {
  width: 980px;
  height: 590px;
}

#bootstrapoverride .shop-container .product-list-container .row > div {
  height: 450px;
  text-align: center;
}

#bootstrapoverride .shop-container .product-list-container .img-center {
  margin-bottom: 20px;
}

#bootstrapoverride .shop-container .product-list-container .img-center img {
  height: 310px;
}

#bootstrapoverride .shop-container .product-list-container .product-name,
#bootstrapoverride .shop-container .product-list-container .product-price {
  font-size: 18px;
  line-height: 24px;
}

#bootstrapoverride .shop-container .product-list-container .product-price {
  font-weight: bold;
  font-family: "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  margin-bottom: 5px;
}

#bootstrapoverride .shop-container .product-list-container .sold-out {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  color: #d1d3d4;
}

#bootstrapoverride .shop-container .size-table-bg {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

#bootstrapoverride .shop-container .size-table {
  position: fixed;
  top: 2vh;
  left: 15vw;
  width: 70vw;
  height: 96vh;
  overflow-y: auto;
  z-index: 1001;
  background-color: white;
  padding: 20px;
}

#bootstrapoverride .shop-container .size-table .size-div {
  width: 800px;
  margin: 20px auto;
}

#bootstrapoverride .shop-container .size-table .close-btn {
  position: fixed;
  right: 10px;
  top: 6vh;
  width: 32px;
  height: 32px;
  opacity: 1;
  z-index: 1000;
}

#bootstrapoverride .shop-container .size-table .close-btn:before, #bootstrapoverride .shop-container .size-table .close-btn:after {
  position: absolute;
  left: 15px;
  content: '';
  height: 33px;
  width: 2px;
  background-color: #333;
}

#bootstrapoverride .shop-container .size-table .close-btn:before {
  transform: rotate(45deg);
}

#bootstrapoverride .shop-container .size-table .close-btn:after {
  transform: rotate(-45deg);
}

#bootstrapoverride .shop-container .size-table table {
  width: 800px;
  margin: 20px auto;
  border-bottom: dashed 1px #808080;
  color: #808080;
}

#bootstrapoverride .shop-container .size-table table tr:last-child td {
  padding-bottom: 8px;
}

#bootstrapoverride .shop-container .size-table table .unberline-tr {
  border-bottom: dashed 1px #808080;
}

#bootstrapoverride .shop-container .size-table table .unberline-tr th {
  padding-bottom: 5px;
  color: #595757;
}

#bootstrapoverride .shop-container .size-table table .upper-block td {
  padding-top: 5px;
}

#bootstrapoverride .shop-container .size-table table th, #bootstrapoverride .shop-container .size-table table td {
  min-width: 70px;
}

#bootstrapoverride .shop-container .size-table table .table-title-2 {
  letter-spacing: 35px;
}

#bootstrapoverride .shop-container .size-table table .table-title-3 {
  letter-spacing: 10.8px;
}

#bootstrapoverride .shop-container .size-table .size img {
  display: block;
  margin: 20px auto;
  width: 805px;
}

#bootstrapoverride .shop-container .size-table .size-chart-img {
  background-image: url("../img/img-sizechart.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 90%;
  height: 525px;
  margin: 0px auto;
}

#bootstrapoverride .shop-container .size-table .measure-diagram {
  background-image: url("../img/measurediagram.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 90%;
  height: 1339px;
  margin: 0px auto;
}

#bootstrapoverride .shop-container .size-table .orange-line {
  width: 800px;
  height: 2px;
  background-color: #f0190f;
  margin: 20px auto;
}

#bootstrapoverride .shop-container .product-view .similar-products {
  margin: 50px 0;
}

#bootstrapoverride .shop-container .item-photo p {
  margin-top: 20px;
}

#bootstrapoverride .shop-container .active-photo img {
  height: 514px;
}

#bootstrapoverride .shop-container .mini-photos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#bootstrapoverride .shop-container .mini-photos .img-center {
  flex-shrink: 0;
  width: 100px;
  margin: 0px 5px;
}

#bootstrapoverride .shop-container .mini-photos img {
  height: 100px;
  cursor: pointer;
}

#bootstrapoverride .shop-container .item-options .item-name {
  font-size: 18px;
  font-weight: bold;
}

#bootstrapoverride .shop-container .item-options .item-code {
  font-size: 14px;
  color: #6d6d6d;
}

#bootstrapoverride .shop-container .item-options .item-price {
  font-weight: bold;
  color: black;
  margin: 30px 0 10px;
}

#bootstrapoverride .shop-container .item-options .item-price span {
  font-weight: bold;
  color: #f0190f;
}

#bootstrapoverride .shop-container .item-options .on-sale-price {
  color: #f0190f;
}

#bootstrapoverride .shop-container .item-options .original-price {
  font-size: 18px;
  font-weight: bold;
  text-decoration: line-through;
}

#bootstrapoverride .shop-container .item-options .small-gray {
  color: #808080;
  font-size: 14px;
  text-decoration: none;
}

#bootstrapoverride .shop-container .item-options .colors {
  width: 90%;
  margin-top: 30px;
  margin-bottom: 20px;
}

#bootstrapoverride .shop-container .item-options .colors input[type="radio"] {
  display: none;
}

#bootstrapoverride .shop-container .item-options .colors label {
  width: 36px;
  height: 36px;
  cursor: pointer;
  margin-right: 10px;
}

#bootstrapoverride .shop-container .item-options .colors label.blue {
  background-image: url("../img/color_tickets/01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#bootstrapoverride .shop-container .item-options .colors label.pink {
  background-image: url("../img/color_tickets/02.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#bootstrapoverride .shop-container .item-options .colors label.yellow {
  background-image: url("../img/color_tickets/03.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#bootstrapoverride .shop-container .item-options .colors label.red {
  background-image: url("../img/color_tickets/04.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#bootstrapoverride .shop-container .item-options .colors input[type="radio"]:checked + label {
  box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(0, 0, 0, 0.4);
}

#bootstrapoverride .shop-container .item-options a.size-select,
#bootstrapoverride .shop-container .item-options .size-info {
  display: inline-block;
  color: #808080;
  padding: 0 7px;
  line-height: 16px;
}

#bootstrapoverride .shop-container .item-options .size-info {
  cursor: pointer;
}

#bootstrapoverride .shop-container .item-options a.size-select {
  padding: 0 12px 0 0;
  border-right: 1px solid #808080;
}

#bootstrapoverride .shop-container .item-options .size-view {
  width: 90%;
  margin-top: 15px;
  margin-bottom: 25px;
}

#bootstrapoverride .shop-container .item-options .size-view input[type="radio"] {
  display: none;
}

#bootstrapoverride .shop-container .item-options .size-view label {
  cursor: pointer;
}

#bootstrapoverride .shop-container .item-options .size-view .size-bubble {
  display: inline-block;
  width: 50px;
  padding: 3px;
  text-align: center;
  border: 2px solid #595757;
  border-radius: 15px;
  margin: 0 10px 10px 0;
}

#bootstrapoverride .shop-container .item-options .size-view input[type="radio"]:checked + label {
  color: #fff;
  background-color: #f0190f;
  border: 2px solid #f0190f;
}

#bootstrapoverride .shop-container .item-options .order-amount > .quantity-adjust {
  width: 90px;
  margin: 0;
}

#bootstrapoverride .shop-container .item-options button[type="submit"] {
  appearance: none;
  padding: 4px 22px;
  color: #fff;
  background-color: #f0190f;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 2px;
  border-radius: 20px;
  border: none;
  margin: 20px 0 30px;
}

#bootstrapoverride .shop-container .item-options .shop-btn {
  float: left;
}

#bootstrapoverride .shop-container .item-options .shop-like-btn {
  position: relative;
  display: flex;
}

#bootstrapoverride .shop-container .item-options .shop-like-btn img {
  margin: 18px 2px 32px;
  position: relative;
}

#bootstrapoverride .shop-container .item-options .shop-like-btn span {
  position: absolute;
  width: 0px;
  height: 32px;
  background-color: #f0190f;
  border-radius: 16px;
  top: 19px;
  left: 15px;
  font-size: 16px;
  color: #ffffff;
  transition: all .5s ease-in-out;
  z-index: -1;
  line-height: 2;
  font-weight: bold;
}

#bootstrapoverride .shop-container .item-options .shop-like-btn:hover span {
  width: 140px;
  position: absolute;
}

#bootstrapoverride .shop-container .delivery-info h4 {
  position: relative;
  color: #f0190f;
  margin-bottom: 20px;
}

#bootstrapoverride .shop-container .delivery-info h4:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  left: -10px;
  width: calc(100% + 10px);
  height: 0;
  border-bottom: 1px solid #545454;
}

#bootstrapoverride .shop-container .delivery-info p {
  color: #545454;
  font-size: 14px;
  margin-bottom: 30px;
}

#bootstrapoverride .shop-container .item-detail-info h5, #bootstrapoverride .shop-container .item-detail-info p {
  font-size: 14px;
}

#bootstrapoverride .shop-container .item-detail-info h5 {
  color: #f0190f;
  margin-bottom: 10px;
}

#bootstrapoverride .shop-container .shopping-from {
  min-height: 10px;
  overflow: hidden;
}

#bootstrapoverride .shop-container .similar-products h4 {
  font-weight: bold;
  margin-bottom: 20px;
}

#bootstrapoverride .shop-container .recommend-container {
  width: 58.3%;
  min-height: 206px;
  float: left;
}

#bootstrapoverride .shop-container .recommend-container .tab {
  width: 175px;
  height: 60px;
  top: 3px;
  padding: 13px;
}

#bootstrapoverride .shop-container .recommend-container .tab:after {
  top: 28px;
  left: 168px;
}

#bootstrapoverride .shop-container .recommend-container .tab p {
  font-size: 18px;
}

#bootstrapoverride .shop-container .recommend-container .inner-container {
  width: 100%;
  min-height: 206px;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick {
  margin: 19px auto;
  width: 90%;
  height: 150px;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick a {
  margin: 0px 5px;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick .img {
  width: 150px;
  height: 150px;
  background-size: cover;
  float: left;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev, #bootstrapoverride .shop-container .recommend-container .inner-container .slick-next {
  z-index: 20;
  background-image: none !important;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev:before, #bootstrapoverride .shop-container .recommend-container .inner-container .slick-next:before {
  opacity: 1;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 21px 0 21px 18px;
  font-size: 0px;
  border-color: transparent transparent transparent #f0190f;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev {
  left: -20px;
  top: 44%;
}

#bootstrapoverride .shop-container .recommend-container .inner-container .slick-next {
  right: -14px;
  top: 55%;
}

.slick-prev {
  left: 0;
  top: 35%;
  transform: rotateZ(180deg);
}

.slick-next {
  right: 0;
}

.zoomImg {
  background-color: white;
}

.mobile-shop-nav {
  width: 200px;
  height: 60px;
  color: white;
  padding: 18px 0px;
  font-size: 12px !important;
  margin-left: -40px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
  display: none;
}

.mobile-shop-nav a {
  font-size: 12px !important;
}

.mobile-shop-nav input {
  height: 23px;
  width: 112px !important;
  background-color: white !important;
  color: black;
  position: relative;
}

.mobile-shop-nav .search {
  position: absolute;
  top: 20px;
  right: 47px;
}

.mobile-shop-nav #delete-input {
  position: absolute;
  display: none;
  top: 21px;
  right: 45px;
}

.mobile-shop-nav .login-pic {
  position: absolute;
  top: 20px;
  left: 38px;
}

#bootstrapoverride .return-confirm-container {
  width: 700px;
  margin: 50px auto;
}

@media all and (max-width: 500px) {
  .mobile-shop-nav {
    display: block;
  }
  #bootstrapoverride .shop-container {
    max-width: 100vw;
    margin: 60px auto 100px;
  }
  #bootstrapoverride .shop-container .shop-nav-container-open {
    left: 0px !important;
  }
  #bootstrapoverride .shop-container .shop-nav-container {
    position: fixed;
    padding: 0px 40px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0px;
    left: -200px;
    background-color: #f0190f;
    transition: all 0.2s linear;
  }
  #bootstrapoverride .shop-container .shop-nav-container li {
    color: white;
  }
  #bootstrapoverride .shop-container .shop-nav-container li a, #bootstrapoverride .shop-container .shop-nav-container li span {
    font-size: 12px !important;
  }
  #bootstrapoverride .shop-container .shop-nav-container .main-category .color-node, #bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-boy, #bootstrapoverride .shop-container .shop-nav-container .main-category .color-node-girl {
    top: 8px;
    left: -18px;
    width: 7px;
    height: 7px;
  }
  #bootstrapoverride .shop-container .shop-nav-container .active-li .active-dec {
    width: 9px;
    height: 9px;
    top: 7px;
    left: -19px;
    border: solid 1px white !important;
  }
  #bootstrapoverride .shop-container .shop-nav {
    margin: 20px 0px;
  }
  #bootstrapoverride .shop-container #shop-mobile-title {
    width: 90vw;
    margin: 0px auto -20px;
    font-size: 11px;
    font-weight: 500;
    color: #808080;
  }
  #bootstrapoverride .shop-container .mini-photos {
    width: 93vw;
  }
  #bootstrapoverride .shop-container .product-list-container {
    float: left;
    width: 100vw;
    padding: 20px 5vw;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-pic {
    margin: 2vw 2vw 0vw 0px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-pic .intro {
    padding: 40px 50px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-one-pic {
    width: 90vw;
    height: 54vw;
    margin-right: 0px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-one-pic .intro {
    display: none;
    width: 90vw;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-two-pic {
    width: 44vw;
    height: 55vw;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-two-pic .intro {
    display: none;
    width: 44vw;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-three-pic {
    width: 28vw;
    height: 26vw;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-home-three-pic .intro {
    display: none;
    width: 28vw;
  }
  #bootstrapoverride .shop-container .product-list-container .last-pic {
    margin-right: 0px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-banner-container-home {
    width: 90vw;
    height: 56vw;
    margin: 11px auto 60px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-banner-container-home img {
    width: 100%;
    height: 100%;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-banner-container {
    width: 90vw;
    height: 56vw;
    margin: 11px auto 60px;
  }
  #bootstrapoverride .shop-container .product-list-container .shop-banner-container img {
    width: 100%;
    height: 100%;
  }
  #bootstrapoverride .shop-container .product-list-container .row .col-md-4 {
    width: 44vw;
    height: 50vw;
    margin-right: 2vw;
    margin-bottom: 20px;
    float: left;
  }
  #bootstrapoverride .shop-container .product-list-container .row .col-md-4:nth-child(2n) {
    margin-right: 0px;
  }
  #bootstrapoverride .shop-container .product-list-container .row .col-md-4 img {
    width: 44vw;
    height: 39vw;
  }
  #bootstrapoverride .shop-container .product-list-container .row .col-md-4 p {
    font-size: 11px !important;
    line-height: 15px;
    width: 44vw;
  }
  #bootstrapoverride .shop-container .product-list-container .img-center {
    margin-bottom: 0px;
  }
  #bootstrapoverride .shop-container .active-photo img {
    height: 90vw !important;
  }
  #bootstrapoverride .shop-container .size-table {
    top: 5vh;
    left: 0vw;
    width: 100vw;
    height: 90vh;
    padding: 20px;
  }
  #bootstrapoverride .shop-container .size-table .size-div {
    width: 85vw;
    margin: 10px auto;
    padding: 0px 15px;
    font-size: 11px;
  }
  #bootstrapoverride .shop-container .size-table table {
    width: 78vw;
    margin: 20px auto;
    font-size: 11px;
  }
  #bootstrapoverride .shop-container .size-table table th, #bootstrapoverride .shop-container .size-table table td {
    min-width: 3%;
    width: 3%;
  }
  #bootstrapoverride .shop-container .size-table table th:first-child, #bootstrapoverride .shop-container .size-table table td:first-child {
    width: 12%;
  }
  #bootstrapoverride .shop-container .size-table table .table-title-2 {
    letter-spacing: 15px;
  }
  #bootstrapoverride .shop-container .size-table table .table-title-3 {
    letter-spacing: 1px;
  }
  #bootstrapoverride .shop-container .size-table .size img {
    display: block;
    margin: 20px auto;
    width: 100%;
  }
  #bootstrapoverride .shop-container .size-table .size-chart-img {
    background-image: url("../img/mobile-sizechart.svg");
    height: 645px;
  }
  #bootstrapoverride .shop-container .size-table .measure-diagram {
    background-image: url("../img/mobile-measurediagram.svg");
    height: 1620px;
    margin: 0px auto;
  }
  #bootstrapoverride .shop-container .size-table .orange-line {
    width: 77vw;
    height: 2px;
    background-color: #f0190f;
    margin: 20px auto;
  }
  .product-view {
    padding: 0px;
  }
  .product-view .item-photo p {
    margin-bottom: 20px;
    font-size: 10px !important;
  }
  .product-view .item-options .item-name {
    font-size: 16px;
  }
  .product-view .item-options .colors label {
    width: 25px;
    height: 25px;
  }
  .product-view .shop-like-btn:hover span {
    width: 0px !important;
  }
  #bootstrapoverride .shop-container .delivery-info h4 {
    font-size: 18px !important;
  }
  #bootstrapoverride .shop-container .recommend-container {
    width: 90vw;
    min-height: 104px;
    float: left;
    margin: 30px 5vw;
  }
  #bootstrapoverride .shop-container .recommend-container .tab {
    width: 90px;
    height: 60px;
    top: 17px;
    padding: 3px;
  }
  #bootstrapoverride .shop-container .recommend-container .tab:after {
    top: 28px;
    left: 168px;
  }
  #bootstrapoverride .shop-container .recommend-container .tab p {
    font-size: 11px;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container {
    width: 100%;
    min-height: 126px;
    height: 126px;
    border: 2px solid #f0190f;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick {
    margin: 19px auto;
    width: 90%;
    height: 100px;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick a {
    margin: 0px 2px;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .recommend-slick .img {
    width: 100%;
    height: 80px;
    margin: 0px;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev, #bootstrapoverride .shop-container .recommend-container .inner-container .slick-next {
    z-index: 20;
    background-image: none !important;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev:before, #bootstrapoverride .shop-container .recommend-container .inner-container .slick-next:before {
    opacity: 1;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 0 9px 10px;
    font-size: 0px;
    border-color: transparent transparent transparent #f0190f;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .slick-prev {
    left: -16px;
    top: 24%;
  }
  #bootstrapoverride .shop-container .recommend-container .inner-container .slick-next {
    right: -17px;
    top: 41%;
  }
  #bootstrapoverride .return-confirm-container {
    width: 100vw;
    padding: 20px;
  }
  #bootstrapoverride .return-confirm-container .return-btn {
    float: left !important;
    margin: 10px 0;
  }
}

#bootstrapoverride .payment-container {
  max-width: 960px;
  margin: 50px auto 100px;
}

#bootstrapoverride .payment-steps {
  width: 640px;
  margin: 0 auto;
  padding-left: 23px;
}

#bootstrapoverride .step {
  position: relative;
  float: left;
  width: 175px;
  height: 45px;
  color: #fff;
  font-size: 16px;
  background-color: grey;
  padding: 12px 0;
  border-radius: 0 30px 30px 0;
  text-align: center;
}

#bootstrapoverride .step.current {
  background-color: #f0190f;
}

#bootstrapoverride .step.current:after {
  color: #f0190f;
  border: 3px solid #f0190f;
}

#bootstrapoverride .step:nth-of-type(2) {
  margin: 0 45px;
}

#bootstrapoverride .step:after {
  content: "";
  position: absolute;
  left: -22px;
  top: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  color: grey;
  border: 3px solid grey;
  font-size: 22px;
  font-weight: bold;
  padding: 8px 0;
}

#bootstrapoverride .step:nth-of-type(1):after {
  content: "1";
}

#bootstrapoverride .step:nth-of-type(2):after {
  content: "2";
}

#bootstrapoverride .step:nth-of-type(3):after {
  content: "3";
}

#bootstrapoverride .payment-delivery-options {
  position: relative;
  margin-bottom: 100px;
}

#bootstrapoverride .payment-delivery-options.first-step:after {
  display: none;
  content: url("../img/checkout/img-checkout-box.svg");
  position: absolute;
  top: 180px;
  right: 100px;
}

#bootstrapoverride .payment-delivery-options form {
  margin-top: 70px;
}

#bootstrapoverride .payment-delivery-options .radio-container {
  position: relative;
  width: 700px;
  margin-left: 100px;
  margin-bottom: 30px;
  font-size: 16px;
}

#bootstrapoverride .payment-delivery-options .radio-container input[type="radio"] {
  position: absolute;
  top: 1px;
  left: -25px;
}

#bootstrapoverride .payment-delivery-options .radio-container span.bold {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

#bootstrapoverride .checkout-header {
  position: relative;
  color: #f0190f;
  margin: 50px 0 40px 30px;
}

#bootstrapoverride .checkout-header:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: -30px;
  width: calc(100% + 30px);
  height: 0;
  border-bottom: 2px solid #f0190f;
}

#bootstrapoverride .mobile-cart-table-header {
  display: none;
}

#bootstrapoverride .mobile-cart-table {
  display: none;
}

#bootstrapoverride .cart-detail-container-editable {
  position: relative;
  padding-bottom: 5px;
  margin: 50px 0;
  border-bottom: 2px solid #f0190f;
}

#bootstrapoverride .cart-detail-container-editable .product-info {
  border: 1px solid #f0190f;
  border-radius: 25px;
  margin-bottom: 15px;
  padding-top: 6px;
}

#bootstrapoverride .cart-detail-container-editable .product-info .item-code {
  font-size: 12px;
}

#bootstrapoverride .cart-detail-container-editable .product-info .item-note {
  font-size: 10px;
  color: #f0190f;
  margin-top: -6px;
}

#bootstrapoverride .cart-detail-container-editable .product-info .item-color {
  margin-top: 8px;
}

#bootstrapoverride .cart-detail-container-editable .product-info .add-next-time {
  font-size: 17px;
  font-weight: bold;
  color: #f0190f;
  cursor: pointer;
}

#bootstrapoverride .cart-detail-container-editable .row {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}

#bootstrapoverride .cart-detail-container-editable .table-header {
  color: #f0190f;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
}

#bootstrapoverride .cart-detail-container-editable div[class*="col"] {
  padding: 0;
}

#bootstrapoverride .cart-detail-container-editable:after {
  content: url("../img/checkout/img-checkout-list.svg");
  position: absolute;
  top: -50px;
  left: 200px;
}

#bootstrapoverride .cart-detail-container {
  position: relative;
  padding-bottom: 5px;
  border: 3px solid #f0190f;
  border-radius: 25px;
  margin: 50px 0;
}

#bootstrapoverride .cart-detail-container .row {
  margin-left: 0;
  margin-right: 0;
}

#bootstrapoverride .cart-detail-container .table-header {
  color: #f0190f;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
}

#bootstrapoverride .cart-detail-container .cart-detail .row {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
}

#bootstrapoverride .cart-detail-container .cart-detail .row:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 98%;
  height: 2px;
  background-color: #f0190f;
}

#bootstrapoverride .cart-detail-container div[class*="col"] {
  padding: 0;
}

#bootstrapoverride .cart-detail-container .product-info {
  height: 130px;
  padding-top: 6px;
}

#bootstrapoverride .cart-detail-container .product-info .item-code {
  font-size: 12px;
}

#bootstrapoverride .cart-detail-container .product-info .item-note {
  font-size: 10px;
  color: #f0190f;
  margin-top: -6px;
}

#bootstrapoverride .cart-detail-container .product-info .item-color {
  margin-top: 8px;
}

#bootstrapoverride .cart-detail-container .product-info .add-next-time {
  font-size: 17px;
  font-weight: bold;
  color: #f0190f;
  cursor: pointer;
}

#bootstrapoverride .cart-detail-container .order-summary-container {
  margin: 20px 0;
}

#bootstrapoverride .order-summary-container {
  margin: 30px 0 100px;
  color: #f0190f;
}

#bootstrapoverride .order-summary-container .row {
  margin-bottom: 10px;
}

#bootstrapoverride .order-summary-container .price-plus-delivery {
  font-size: 22px;
}

#bootstrapoverride .order-summary-container .delivery-fee-note {
  width: 100%;
  background-color: #00b9ff;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin-top: 10px;
}

#bootstrapoverride .order-summary-container .continue-shopping-btn {
  float: left;
  width: 130px;
  height: 35px;
  color: #f0190f;
  font-size: 16px;
  padding: 5px 0;
  border: 2px solid #f0190f;
  border-radius: 30px;
  text-align: center;
}

#bootstrapoverride .checkout-summary-header {
  width: 460px;
  font-size: 22px;
  font-weight: bold;
  color: #f0190f;
  margin: 50px auto;
}

#bootstrapoverride .checkout-summary-container {
  position: relative;
  padding-bottom: 5px;
  border: 3px solid #f0190f;
  border-radius: 25px;
  margin: 50px 0 20px;
}

#bootstrapoverride .checkout-summary-container .header {
  position: relative;
  height: 60px;
  padding: 17px 30px;
  color: #f0190f;
}

#bootstrapoverride .checkout-summary-container .header div {
  font-size: 22px;
  font-weight: bold;
  padding: 0;
}

#bootstrapoverride .checkout-summary-container .header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 98%;
  height: 2px;
  background-color: #f0190f;
}

#bootstrapoverride .checkout-summary-container .header.overscore:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 98%;
  height: 2px;
  background-color: #f0190f;
}

#bootstrapoverride .checkout-summary-container .row {
  margin: 0 0 30px 0;
  padding-left: 30px;
}

#bootstrapoverride .checkout-summary-container .row .col-xs-3,
#bootstrapoverride .checkout-summary-container .row .col-xs-9 {
  padding: 5px 0;
  font-size: 16px;
}

#bootstrapoverride .checkout-summary-container .row .col-xs-3 {
  font-weight: bold;
}

#bootstrapoverride .continue-buttons {
  margin-top: 40px;
}

#bootstrapoverride .customer-info-container {
  border-bottom: 2px solid #f0190f;
}

#bootstrapoverride .customer-info-container .info-section {
  width: 650px;
  margin: 50px auto;
}

#bootstrapoverride .customer-info-container .info-section a {
  color: white;
}

#bootstrapoverride .customer-info-container .store-information {
  border: 1px solid #808080;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 12px;
  color: #6d6d6d;
}

#bootstrapoverride .indent-label {
  padding-left: 26px;
}

#bootstrapoverride .margin-right {
  margin-right: 30px;
}

#bootstrapoverride .step-navigate {
  position: relative;
  height: 35px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background-color: #f0190f;
  border-radius: 30px;
  text-align: center;
}

#bootstrapoverride .step-navigate:after {
  content: "";
  position: absolute;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  color: #f0190f;
  border: 2px solid #f0190f;
  font-size: 20px;
  font-weight: bold;
  padding: 5px 0;
}

#bootstrapoverride .step-next {
  float: right;
  width: 150px;
  padding: 8px 20px 8px 0;
}

#bootstrapoverride .step-next:after {
  right: 0;
}

#bootstrapoverride .step-prev {
  float: left;
  width: 170px;
  padding: 8px 0 8px 20px;
  margin-left: 40px;
}

#bootstrapoverride .step-prev:after {
  left: 0;
}

#bootstrapoverride .step-1:after {
  content: "1";
}

#bootstrapoverride .step-2:after {
  content: "2";
}

#bootstrapoverride .step-3:after {
  content: "3";
}

#bootstrapoverride .seven-eleven-btn,
#bootstrapoverride .family-mart-btn,
#bootstrapoverride .hi-life-btn {
  display: none;
  width: 170px;
  height: 30px;
  background-color: #f0190f;
  color: #fff;
  padding-top: 3px;
  border-radius: 20px;
  text-align: center;
}

#bootstrapoverride .seven-eleven-btn.selected,
#bootstrapoverride .family-mart-btn.selected,
#bootstrapoverride .hi-life-btn.selected {
  display: block;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .checkout-summary-header {
    width: 80vw;
    font-size: 18px;
    text-align: center;
  }
  .cart-detail-container {
    padding: 10px;
  }
  .cart-detail-container div[class*="col"] {
    width: 20%;
  }
  .cart-detail-container img {
    width: 100%;
  }
  .checkout-summary-container .col-xs-3 {
    width: 45%;
    font-size: 11px !important;
  }
  .checkout-summary-container .col-xs-9 {
    width: 50%;
    margin-left: 5px;
    font-size: 11px !important;
  }
  #bootstrapoverride .payment-container {
    max-width: 90vw;
    margin: 100px auto 100px;
  }
  #bootstrapoverride .payment-steps {
    width: 240px;
    margin: 3px auto;
    padding-left: 10px;
  }
  #bootstrapoverride .step {
    position: relative;
    float: initial;
    width: 240px;
    height: 30px;
    font-size: 14px;
    padding: 3px 0px;
  }
  #bootstrapoverride .step:nth-of-type(2) {
    margin: 3px 0px;
  }
  #bootstrapoverride .step:after {
    left: -17px;
    width: 30px;
    height: 30px;
    padding: 2px 0px;
  }
  #bootstrapoverride .payment-delivery-options form {
    margin-top: 0px;
  }
  #bootstrapoverride .payment-delivery-options .radio-container {
    position: relative;
    width: 80vw;
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 11px;
  }
  #bootstrapoverride .payment-delivery-options .radio-container input[type="radio"] {
    position: absolute;
    top: 1px;
    left: -25px;
  }
  #bootstrapoverride .payment-delivery-options .radio-container span.bold {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  #bootstrapoverride .checkout-header {
    font-size: 12px;
    margin: 30px auto 15px;
    width: 85vw;
  }
  #bootstrapoverride .checkout-header:after {
    width: 90vw;
    bottom: 0px;
    left: -2vw;
  }
  #bootstrapoverride .dec-underline:after {
    content: "";
    width: 76%;
    height: 1px;
    position: absolute;
    top: 21px;
    left: 12%;
    background-color: #f0190f;
  }
  #bootstrapoverride .desktop-cart-table-header {
    display: none;
  }
  #bootstrapoverride .mobile-cart-table-header {
    display: block;
  }
  #bootstrapoverride .desktop-cart-table {
    display: none;
  }
  #bootstrapoverride .mobile-cart-table {
    display: block;
  }
  #bootstrapoverride .cart-detail {
    width: 90vw;
    margin: 0px auto;
  }
  #bootstrapoverride .cart-detail.favorite {
    width: 100%;
  }
  #bootstrapoverride .order-data-cart-detail {
    width: 80vw;
  }
  #bootstrapoverride .cart-detail-container-editable {
    width: 90vw;
    margin: 0px auto;
  }
  #bootstrapoverride .cart-detail-container-editable .product-info {
    border-radius: 8px;
  }
  #bootstrapoverride .cart-detail-container-editable .item-photo img {
    width: 90%;
  }
  #bootstrapoverride .cart-detail-container-editable div[class*="col"] {
    width: 20%;
    font-size: 12px !important;
  }
  #bootstrapoverride .cart-detail-container-editable div[class*="col"] p {
    font-size: 11px !important;
  }
  #bootstrapoverride .cart-detail-container-editable .item-discount {
    font-size: 12px !important;
  }
  .cart-detail-container {
    border-radius: 8px;
  }
  .cart-detail-container .table-header {
    padding: 10px 0;
  }
  .cart-detail-container div[class*="col"] {
    width: 20%;
    font-size: 12px !important;
    position: relative;
  }
  .cart-detail-container div[class*="col"] p {
    font-size: 11px !important;
  }
  .cart-detail-container .col-sm-4 {
    width: 100% !important;
  }
  .cart-detail-container .product-info {
    height: 130px;
    padding-top: 6px;
  }
  .cart-detail-container .checkout-remove img {
    width: 50%;
  }
  .order-summary-container .summary-num {
    width: 40%;
    margin-left: 60% !important;
  }
  .order-summary-container .summary-num div {
    padding: 0px;
    width: 50% !important;
    float: left;
  }
  .order-summary-container .delivery-fee {
    width: 100%;
  }
  .customer-info-container {
    padding-bottom: 30px;
  }
  .customer-info-container .info-section {
    width: 90vw !important;
  }
  .customer-info-container .info-section a {
    color: white;
  }
  .customer-info-container .info-section label {
    font-size: 11px !important;
    padding: 0px !important;
  }
  .customer-info-container .info-section input {
    height: 23px;
  }
  .customer-info-container input + label {
    position: absolute;
    top: 5px;
    left: 40px;
  }
  .customer-info-container div[class*="col"] {
    padding-right: 0px;
    width: 100%;
  }
  .customer-info-container .store-information {
    border: 1px solid #808080;
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 12px;
    color: #6d6d6d;
  }
  .customer-info-container .row {
    width: 95vw !important;
  }
  .customer-info-container .row-title {
    min-width: 25vw;
  }
  .customer-info-container .blue-remark span.small {
    width: 70vw !important;
    top: 30px;
  }
  .large-margin {
    margin-bottom: 40px !important;
  }
  .step-prev {
    margin-left: 0px !important;
  }
  .continue-buttons button[type='submit'] {
    float: right;
  }
  .favortie-container .item-photo img {
    width: 50px;
    height: 50px;
    margin: 5px;
  }
  .continue-shopping-btn {
    font-size: 12px !important;
  }
  .step-navigate {
    font-size: 12px !important;
  }
  .step-next {
    width: 130px !important;
  }
  .step-prev {
    width: 120px !important;
  }
  .button-note-right {
    float: right;
    text-align: right;
  }
  #bootstrapoverride .payment-container .cart-detail {
    width: 84vw;
  }
  #bootstrapoverride .payment-container div[class*="col"] {
    width: 100%;
  }
  #bootstrapoverride .payment-container .checkout-summary-container div[class*="col"] {
    width: 49%;
  }
  #bootstrapoverride .payment-container .checkout-summary-container .header {
    width: 80vw;
    margin: 10px auto;
  }
  #bootstrapoverride .payment-container .checkout-summary-container .header:after {
    width: 80vw;
  }
  #bootstrapoverride .payment-container .checkout-summary-container .header.overscore:before {
    width: 80vw;
  }
  #bootstrapoverride .addr-col {
    min-width: 72px;
    padding: 3px;
  }
}

#bootstrapoverride .quantity-adjust {
  position: relative;
  width: 80px;
  height: 35px;
  padding: 4px 0;
  text-align: center;
  border: 2px solid #f0190f;
  border-radius: 20px;
  margin: 0 auto;
}

#bootstrapoverride .quantity-adjust .decrease-qty,
#bootstrapoverride .quantity-adjust .increase-qty {
  position: absolute;
  display: inline-block;
  top: 7px;
  width: 17px;
  height: 17px;
  background-color: #f0190f;
  border-radius: 50%;
  cursor: pointer;
}

#bootstrapoverride .quantity-adjust .decrease-qty:before,
#bootstrapoverride .quantity-adjust .increase-qty:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 2px;
  width: 13px;
  height: 1px;
  background-color: #fff;
}

#bootstrapoverride .quantity-adjust .decrease-qty {
  left: 6px;
}

#bootstrapoverride .quantity-adjust .increase-qty {
  right: 6px;
}

#bootstrapoverride .quantity-adjust .increase-qty:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 8px;
  width: 1px;
  height: 13px;
  background-color: #fff;
}

#bootstrapoverride .quantity-adjust .show-qty {
  font-size: 16px;
}

#bootstrapoverride .item-quantity.out-of-stock .quantity-adjust {
  border: 2px solid #bcbcbc;
}

#bootstrapoverride .item-quantity.out-of-stock .quantity-adjust .decrease-qty,
#bootstrapoverride .item-quantity.out-of-stock .quantity-adjust .increase-qty {
  display: none;
}

#bootstrapoverride .item-quantity.out-of-stock .quantity-adjust .show-qty {
  color: #bcbcbc;
}

#bootstrapoverride .remove-item-btn {
  position: relative;
  width: 19px;
  height: 19px;
  background-color: grey;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
}

#bootstrapoverride .remove-item-btn:before, #bootstrapoverride .remove-item-btn:after {
  content: "";
  position: absolute;
  top: 9px;
  width: 15px;
  height: 1px;
  background-color: #fff;
}

#bootstrapoverride .remove-item-btn:before {
  left: 2px;
  transform: rotate(45deg);
}

#bootstrapoverride .remove-item-btn:after {
  right: 2px;
  transform: rotate(-45deg);
}

@media all and (max-width: 500px) {
  #bootstrapoverride .quantity-adjust {
    width: 66px;
    height: 24px;
    padding: 1px 0;
    border-radius: 12px;
  }
  #bootstrapoverride .quantity-adjust .decrease-qty,
  #bootstrapoverride .quantity-adjust .increase-qty {
    position: absolute;
    display: inline-block;
    top: 2px;
  }
  #bootstrapoverride .quantity-adjust .decrease-qty:before,
  #bootstrapoverride .quantity-adjust .increase-qty:before {
    content: "";
    position: absolute;
    top: 8px;
    left: 2px;
    width: 13px;
    height: 1px;
    background-color: #fff;
  }
  #bootstrapoverride .quantity-adjust .decrease-qty {
    left: 2px;
  }
  #bootstrapoverride .quantity-adjust .increase-qty {
    right: 2px;
  }
  #bootstrapoverride .remove-item-btn {
    position: absolute;
    top: -30px;
    right: 15px;
  }
}

.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 15px;
  font-weight: 200;
  background-color: #2e353d;
  position: fixed;
  top: 0px;
  width: 300px;
  height: 100%;
  color: #e1ffff;
}

.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 17px;
}

.nav-side-menu .toggle-btn {
  display: none;
}

.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
  /*    
    .collapsed{
       .arrow:before{
                 font-family: FontAwesome;
                 content: "\f053";
                 display: inline-block;
                 padding-left:10px;
                 padding-right: 10px;
                 vertical-align: middle;
                 float:right;
            }
     }
*/
}

.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}

.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
}

.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}

.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #020203;
}

.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  font-family: FontAwesome;
  /*content: "\f111";*/
  content: "•";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}

.nav-side-menu li a {
  text-decoration: none;
  color: #e1ffff;
}

.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.nav-side-menu li:hover {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.nav-side-menu li.sign {
  background-color: #708090;
  cursor: default;
  text-align: center;
  border: none;
}

.backend-body {
  margin: 0px;
  padding: 0px;
}

.content-block {
  margin-left: 300px;
}

.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.control-label {
  font-weight: bold;
}

.form-heading {
  font-size: 30px;
}

.arrow-small {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow-small.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid black;
}

.arrow-small.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
}

.list-container {
  width: 80%;
  margin: 0 auto;
}

.visual-img-box {
  width: 90%;
}

.visual-index-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 50%;
  vertical-align: middle;
}

.visual-img-box img {
  width: 100%;
  height: auto;
}

.img-box {
  width: 100%;
  margin-bottom: 20px;
}

.img-box img {
  width: 100%;
  height: auto;
}

.visual-index-heading {
  font-size: 18px;
}

.element-block p {
  text-align: center;
  margin-top: 5px;
}

.element-block form {
  margin-top: 15px;
}

.center-content {
  margin-top: 100px;
  text-align: center;
}

.fill-container {
  width: 40%;
  margin: 0 auto;
}

.w3-container {
  padding: 16px;
}

.hidden {
  display: none;
}

@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }
  .brand {
    text-align: left !important;
    font-size: 25px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}

@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}

#bootstrapoverride .loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 9999;
}

#bootstrapoverride .loading-mask.hidden {
  display: none;
}

#bootstrapoverride .about-container {
  width: 100%;
  max-width: 1780px;
  margin: 200px auto 0;
}

#bootstrapoverride .about-container img {
  position: absolute;
}

#bootstrapoverride .about-container .about-inner-container {
  position: relative;
  width: 1400px;
  height: 8700px;
  margin: 0 auto;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud {
  position: absolute;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(1) {
  top: -70px;
  left: 960px;
  width: 450px;
  height: 370px;
  animation-name: float-scale-06;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -2s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(1) path {
  fill: #b5ffff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(2) {
  top: 90px;
  left: 990px;
  width: 200px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -4s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(2) path {
  fill: #b7ecff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(3) {
  top: 50px;
  left: -100px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.9s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(3) path {
  fill: #b7ecff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(4) {
  top: 250px;
  left: 500px;
  display: none;
  animation-name: float-scale-06;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -1.5s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(5) {
  top: 550px;
  left: 1100px;
  width: 200px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -2s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(5) path {
  fill: #b7ecff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(6) {
  top: 800px;
  left: 0;
  animation-name: float-scale-06;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.7s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(7) {
  top: 950px;
  left: 850px;
  width: 380px;
  transform: scale(1.4);
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(8) {
  top: 1200px;
  left: 300px;
  animation-name: float-scale-06;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -4s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(9) {
  top: 3000px;
  left: 900px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(10) {
  display: none;
  top: 2800px;
  left: 380px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.5s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(11) {
  display: none;
  top: 2920px;
  left: 200px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.2s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(12) {
  display: none;
  top: 3000px;
  left: 750px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -1s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(13) {
  top: 3200px;
  left: 50px;
  animation-name: float-scale-08;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -2s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(14) {
  top: 4450px;
  left: 200px;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(14) path {
  fill: #e6e6e5;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(15) {
  top: 4650px;
  left: 1000px;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(15) path {
  fill: #cccbcb;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(16) {
  top: 6550px;
  left: 980px;
  animation-name: float-scale-14;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: 0s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(17) {
  top: 6650px;
  left: 1170px;
  width: 170px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.6s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(18) {
  top: 7050px;
  left: 600px;
  animation-name: float-scale-12;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -2.5s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(18) path {
  fill: #80d9ff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(19) {
  top: 7350px;
  left: 1120px;
  animation-name: float-scale-12;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -2.5s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(19) path {
  fill: #b1ffff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(20) {
  top: 7650px;
  left: -50px;
  animation-name: floating-cloud;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -3s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(20) path {
  fill: #50d9ff;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(21) {
  top: 7700px;
  left: 1000px;
  animation-name: float-scale-07;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-delay: -0.4s;
  animation-fill-mode: none;
}

#bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(21) path {
  fill: #b7ecff;
}

#bootstrapoverride .about-container .flying-bear-wrapper {
  position: fixed;
  top: 50vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 151px;
  height: 190px;
  z-index: 13;
}

#bootstrapoverride .about-container .flying-bear-wrapper-right {
  margin: 0px 0px 0px 60vw;
}

#bootstrapoverride .about-container .flying-bear {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#bootstrapoverride .about-container .flying-bear .balloon {
  position: absolute;
  top: -150px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-1 {
  left: 80px;
  transform: rotate(30deg);
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-2 {
  left: 90px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-3 {
  left: 95px;
  transform: rotate(-20deg);
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-4 {
  left: 95px;
  transform: rotate(-20deg);
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-5 {
  left: 130px;
  top: -130px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-6 {
  left: 100px;
  top: -120px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-7 {
  top: -130px;
  left: 20px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-8 {
  left: 55px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-9 {
  left: 100px;
}

#bootstrapoverride .about-container .flying-bear .balloon.balloon-10 {
  top: -170px;
  left: 80px;
}

#bootstrapoverride .about-container .flying-bear .sparkle {
  position: absolute;
  display: none;
  opacity: 0;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle {
  display: inline-block;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-1 {
  top: 140px;
  left: 20px;
  animation-delay: .5s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-2 {
  top: 160px;
  left: 30px;
  animation-delay: .2s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-3 {
  top: 180px;
  left: 40px;
  animation-delay: .3s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-4 {
  top: 190px;
  left: 110px;
  animation-delay: .6s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-5 {
  top: 180px;
  left: 80px;
  animation-delay: .1s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-6 {
  top: 150px;
  left: 130px;
  animation-delay: 1s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-7 {
  top: 160px;
  left: 50px;
  animation-delay: .2s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-8 {
  top: 175px;
  left: 115px;
  animation-delay: .5s;
}

#bootstrapoverride .about-container .flying-bear .sparkles-container.sparkling .sparkle-9 {
  top: 150px;
  left: 100px;
  animation-delay: 9s;
}

#bootstrapoverride .about-container .dialog-container {
  position: absolute;
  width: 430px;
}

#bootstrapoverride .about-container .dialog-container p {
  display: inline-block;
  position: absolute;
  top: 110px;
  left: 50px;
  width: 320px;
  color: white;
  text-align: center;
  font-size: 26px;
  font-family: "Yuanti";
  font-weight: bold;
  line-height: 32px;
  letter-spacing: 2px;
}

#bootstrapoverride .about-container .dialog-container .english-font {
  font-family: "Heiti TC", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-size: 22px;
}

#bootstrapoverride .about-container .zone {
  position: absolute;
  left: 0;
  width: 100%;
}

#bootstrapoverride .about-container .zone-1 {
  top: 600px;
}

#bootstrapoverride .about-container .zone-1 .dialog-container {
  top: 0;
  left: 150px;
  transform: rotate(-5deg);
}

#bootstrapoverride .about-container .zone-1 .dialog-container p {
  transform: rotate(-5deg);
}

#bootstrapoverride .about-container .zone-1 .flying-house {
  position: absolute;
  top: 200px;
  left: 900px;
}

#bootstrapoverride .about-container .zone-1 .flying-house-smoke {
  position: absolute;
  top: 35px;
  left: 910px;
}

#bootstrapoverride .about-container .zone-1 .flying-house-plate {
  position: absolute;
  top: 380px;
  left: 870px;
}

#bootstrapoverride .about-container .zone-2 {
  top: 1560px;
}

#bootstrapoverride .about-container .zone-2 .svg {
  position: absolute;
}

#bootstrapoverride .about-container .zone-2 .about-bubble {
  transform: rotateZ(190deg) rotateX(180deg);
}

#bootstrapoverride .about-container .zone-2 .about-bubble path {
  fill: #ff3caa;
}

#bootstrapoverride .about-container .zone-2 .dialog-container {
  top: -120px;
  right: 150px;
}

#bootstrapoverride .about-container .zone-2 .dialog-container p {
  top: 90px;
  left: 70px;
  transform: rotate(15deg);
}

#bootstrapoverride .about-container .zone-2 svg[class*="cloud"] {
  width: 250px;
}

#bootstrapoverride .about-container .zone-2 .clouds-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 500px;
}

#bootstrapoverride .about-container .zone-2 .cloud-1 {
  top: 0;
  left: -265px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-1 path {
  fill: #20c7ff;
}

#bootstrapoverride .about-container .zone-2 .cloud-2 {
  top: 220px;
  left: -160px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-2 path {
  fill: #ffee00;
}

#bootstrapoverride .about-container .zone-2 .cloud-3 {
  top: 450px;
  left: -265px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-3 path {
  fill: #ff9400;
}

#bootstrapoverride .about-container .zone-2 .cloud-4 {
  top: 0;
  left: 100px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-4 path {
  fill: #8d54c8;
}

#bootstrapoverride .about-container .zone-2 .cloud-5 {
  top: 0;
  left: 465px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-5 path {
  fill: #ffee00;
}

#bootstrapoverride .about-container .zone-2 .cloud-6 {
  top: 220px;
  left: 200px;
}

#bootstrapoverride .about-container .zone-2 .cloud-6 path {
  fill: #ff0000;
}

#bootstrapoverride .about-container .zone-2 .cloud-7 {
  top: 220px;
  left: 560px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-7 path {
  fill: #aae600;
}

#bootstrapoverride .about-container .zone-2 .cloud-8 {
  top: 450px;
  left: 100px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-8 path {
  fill: #ff3caa;
}

#bootstrapoverride .about-container .zone-2 .cloud-9 {
  top: 450px;
  left: 465px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-9 path {
  fill: #0092ff;
}

#bootstrapoverride .about-container .zone-2 .clouds-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 500px;
}

#bootstrapoverride .about-container .zone-2 .cloud-10 {
  display: none;
  top: 0;
  left: 100px;
}

#bootstrapoverride .about-container .zone-2 .cloud-10 path {
  fill: #00b400;
}

#bootstrapoverride .about-container .zone-2 .cloud-11 {
  top: 0;
  left: 440px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-11 path {
  fill: #ff9400;
}

#bootstrapoverride .about-container .zone-2 .cloud-12 {
  top: 220px;
  left: 200px;
}

#bootstrapoverride .about-container .zone-2 .cloud-12 path {
  fill: #80ffff;
}

#bootstrapoverride .about-container .zone-2 .cloud-13 {
  top: 220px;
  left: 520px;
}

#bootstrapoverride .about-container .zone-2 .cloud-13 path {
  fill: #ffee00;
}

#bootstrapoverride .about-container .zone-2 .cloud-14 {
  top: 450px;
  left: 100px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-14 path {
  fill: #00b400;
}

#bootstrapoverride .about-container .zone-2 .cloud-15 {
  top: 450px;
  left: 440px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-15 path {
  fill: #20c7ff;
}

#bootstrapoverride .about-container .zone-2 .cloud-16 {
  top: 0;
  left: 750px;
}

#bootstrapoverride .about-container .zone-2 .cloud-16 path {
  fill: #ff3caa;
}

#bootstrapoverride .about-container .zone-2 .cloud-17 {
  top: 220px;
  left: 850px;
  transform: rotate(10deg);
}

#bootstrapoverride .about-container .zone-2 .cloud-17 path {
  fill: #ff0000;
}

#bootstrapoverride .about-container .zone-2 .cloud-18 {
  top: 450px;
  left: 750px;
}

#bootstrapoverride .about-container .zone-2 .cloud-18 path {
  fill: #8d54c8;
}

#bootstrapoverride .about-container .zone-3 {
  top: 2650px;
}

#bootstrapoverride .about-container .zone-3 .heart-1 {
  position: absolute;
  top: -20px;
  left: 220px;
  transform: scale(1.8) rotate(15deg);
  width: 260px;
  height: 220px;
}

#bootstrapoverride .about-container .zone-3 .heart-1 path {
  fill: #ffee00;
}

#bootstrapoverride .about-container .zone-3 .heart-pink {
  position: absolute;
  top: 140px;
  left: 125px;
  z-index: 1;
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
}

#bootstrapoverride .about-container .zone-3 .heart-pink .heart-2 {
  width: 250px;
  height: 200px;
}

#bootstrapoverride .about-container .zone-3 .heart-pink .heart-2 path {
  fill: #ff72aa;
}

#bootstrapoverride .about-container .zone-3 .heart-pink.pop {
  transform: scale(1.4);
}

#bootstrapoverride .about-container .zone-3 .svg-container {
  position: absolute;
  top: -25px;
  left: 440px;
}

#bootstrapoverride .about-container .zone-3 .svg-container .heart-3 {
  transform: rotateZ(170deg) rotateX(180deg) scale(0.3);
  display: none;
}

#bootstrapoverride .about-container .zone-3 .svg-container .heart-3 path {
  fill: #ff0000;
}

#bootstrapoverride .about-container .zone-3 .mom-pinkbear {
  position: absolute;
  top: 0;
  left: 140px;
}

#bootstrapoverride .about-container .zone-3 .dialog-container {
  position: absolute;
  top: -270px;
  left: 650px;
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
}

#bootstrapoverride .about-container .zone-3 .dialog-container.pop {
  transform: scale(1);
}

#bootstrapoverride .about-container .zone-3 .dialog-container p {
  top: 70px;
  left: 60px;
  width: 340px;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
  transform: rotate(15deg);
}

#bootstrapoverride .about-container .zone-3 .dialog-container .about-bubble {
  transform: rotateZ(190deg) rotateX(180deg);
}

#bootstrapoverride .about-container .zone-3 .dialog-container .about-bubble path {
  fill: #ff6600;
}

#bootstrapoverride .about-container .zone-4 {
  top: 3740px;
}

#bootstrapoverride .about-container .zone-4 .dad-bluebear {
  position: absolute;
  top: -8px;
  left: 190px;
  z-index: 2;
}

#bootstrapoverride .about-container .zone-4 .dad-bluebear-grassland {
  position: absolute;
  top: 400px;
  left: 50px;
}

#bootstrapoverride .about-container .zone-4 .dad-number-2 {
  position: absolute;
  top: -140px;
  left: 770px;
}

#bootstrapoverride .about-container .zone-4 .dialog-container {
  position: absolute;
  top: -350px;
  left: 150px;
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
}

#bootstrapoverride .about-container .zone-4 .dialog-container.pop {
  transform: scale(1);
}

#bootstrapoverride .about-container .zone-4 .dialog-container p {
  top: 70px;
  left: 50px;
  width: 340px;
  font-size: 25px;
  line-height: 31px;
  letter-spacing: 0;
  transform: rotate(-5deg);
  color: #19b3ff;
}

#bootstrapoverride .about-container .zone-4 .dialog-container .about-bubble path {
  fill: #fff100;
}

#bootstrapoverride .about-container .zone-5 {
  top: 4600px;
}

#bootstrapoverride .about-container .zone-5 .sea-frog {
  top: 250px;
  left: 650px;
}

#bootstrapoverride .about-container .zone-5 img {
  position: absolute;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(2) {
  top: 0;
  left: 410px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(3) {
  top: 130px;
  left: 280px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(4) {
  top: 150px;
  left: 660px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(5) {
  top: 40px;
  left: 590px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(6) {
  top: 90px;
  left: 490px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(7) {
  top: 270px;
  left: 540px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(8) {
  top: 200px;
  left: 430px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(9) {
  top: 400px;
  left: 600px;
}

#bootstrapoverride .about-container .zone-5 img:nth-of-type(n+10) {
  display: none;
}

#bootstrapoverride .about-container .zone-5 .dialog-container {
  position: absolute;
  top: -170px;
  left: 550px;
}

#bootstrapoverride .about-container .zone-5 .dialog-container p {
  top: 90px;
  left: 160px;
  width: 260px;
  font-size: 25px;
  transform: rotate(20deg);
}

#bootstrapoverride .about-container .zone-5 .dialog-container .about-bubble {
  transform: rotateZ(190deg) rotateX(180deg);
}

#bootstrapoverride .about-container .zone-5 .dialog-container path {
  fill: #ff0066;
  transform: scale(0.75);
}

#bootstrapoverride .about-container .zone-6 {
  top: 5220px;
}

#bootstrapoverride .about-container .zone-6 .sea-sea {
  position: absolute;
  top: 200px;
  left: -270px;
  right: 0;
  margin: 0 auto;
}

#bootstrapoverride .about-container .zone-6 .sea-dolphin {
  position: absolute;
  top: 230px;
  left: 420px;
}

#bootstrapoverride .about-container .zone-6 .dialog-container {
  position: absolute;
  top: -330px;
  left: 200px;
  transform: rotate(-15deg);
}

#bootstrapoverride .about-container .zone-6 .dialog-container p {
  top: 70px;
  left: 0;
  width: 260px;
  font-size: 25px;
  line-height: 35px;
  letter-spacing: 1px;
  transform: rotate(-10deg);
}

#bootstrapoverride .about-container .zone-6 .dialog-container path {
  fill: #0057f1;
  transform: scale(0.6);
}

#bootstrapoverride .about-container .zone-6 .sea-fish-left {
  position: absolute;
  top: 450px;
  left: 100px;
}

#bootstrapoverride .about-container .zone-6 .sea-fish-right {
  position: absolute;
  top: 400px;
  left: 820px;
}

#bootstrapoverride .about-container .zone-7 {
  top: 6220px;
}

#bootstrapoverride .about-container .zone-7 .dialog-container.left {
  position: absolute;
  top: -30px;
  left: 400px;
  transform: rotate(-10deg);
}

#bootstrapoverride .about-container .zone-7 .dialog-container.left p {
  top: 80px;
  left: 0;
  width: 260px;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 1px;
  transform: rotate(-5deg);
}

#bootstrapoverride .about-container .zone-7 .dialog-container.left path {
  fill: #00b400;
  transform: scale(0.6);
}

#bootstrapoverride .about-container .zone-7 .dialog-container.right {
  position: absolute;
  top: -100px;
  left: 790px;
}

#bootstrapoverride .about-container .zone-7 .dialog-container.right p {
  top: 70px;
  left: 170px;
  width: 260px;
  line-height: 40px;
  font-size: 25px;
  transform: rotate(20deg);
}

#bootstrapoverride .about-container .zone-7 .dialog-container.right .about-bubble {
  transform: rotateZ(190deg) rotateX(180deg);
}

#bootstrapoverride .about-container .zone-7 .dialog-container.right path {
  fill: #ff3caa;
  transform: scale(0.7);
}

#bootstrapoverride .about-container .zone-7 .book-pegasus {
  position: absolute;
  top: 250px;
  left: 870px;
}

#bootstrapoverride .about-container .zone-7 .book-owl {
  position: absolute;
  top: 300px;
  left: 50px;
}

#bootstrapoverride .about-container .zone-7 .book-paris {
  position: absolute;
  top: 180px;
  left: 160px;
}

#bootstrapoverride .about-container .zone-7 .book-us {
  position: absolute;
  top: 520px;
  left: 170px;
}

#bootstrapoverride .about-container .zone-7 .star-1 {
  position: absolute;
  top: 0;
  left: 100px;
  transform: scale(0.8) rotate(-20deg);
}

#bootstrapoverride .about-container .zone-7 .star-1 path {
  fill: #ffff00;
}

#bootstrapoverride .about-container .zone-7 .star-2 {
  position: absolute;
  top: -250px;
  left: 330px;
  transform: rotate(75deg) scale(0.5);
}

#bootstrapoverride .about-container .zone-7 .star-2 path {
  fill: #8d54c8;
}

#bootstrapoverride .about-container .zone-7 .star-3 {
  position: absolute;
  top: -200px;
  left: 1200px;
}

#bootstrapoverride .about-container .zone-7 .star-4 {
  position: absolute;
  top: 610px;
  left: 640px;
  z-index: 1;
  transform: rotate(180deg);
}

#bootstrapoverride .about-container .zone-7 .star-4 path {
  fill: #52cd28;
}

#bootstrapoverride .about-container .zone-7 .star-5 {
  position: absolute;
  top: 770px;
  left: 710px;
}

#bootstrapoverride .about-container .zone-7 .star-5 path {
  fill: #0092ff;
}

#bootstrapoverride .about-container .zone-7 .star-6 {
  position: absolute;
  top: 880px;
  left: 510px;
  transform: scale(0.8);
}

#bootstrapoverride .about-container .zone-7 .star-7 {
  position: absolute;
  top: 830px;
  left: 1200px;
}

#bootstrapoverride .about-container .zone-7 .star-7 path {
  fill: #ff9400;
}

#bootstrapoverride .about-container .zone-7 .star-8 {
  position: absolute;
  top: 650px;
  left: 980px;
  transform: rotate(-50deg) scale(0.8);
}

#bootstrapoverride .about-container .zone-7 .star-8 path {
  fill: #ffec00;
}

#bootstrapoverride .about-container .zone-8 {
  position: absolute;
  bottom: 0;
  left: -50px;
  height: 730px;
}

#bootstrapoverride .about-container .zone-8 svg {
  position: absolute;
}

#bootstrapoverride .about-container .zone-8 .dialog-container {
  position: absolute;
  top: -400px;
  left: 260px;
  transform: rotate(-5deg);
}

#bootstrapoverride .about-container .zone-8 .dialog-container p {
  width: 350px;
  line-height: 38px;
  transform: rotate(-5deg);
}

#bootstrapoverride .about-container .zone-8 .dialog-container path {
  fill: #ff007b;
}

#bootstrapoverride .about-container .zone-8 .family-bg {
  bottom: -2px;
  left: -310px;
  z-index: 11;
}

#bootstrapoverride .about-container .zone-8 .family-bear-1 {
  top: 300px;
  left: 550px;
  z-index: 14;
}

#bootstrapoverride .about-container .zone-8 .family-bear-2 {
  top: 300px;
  left: 630px;
  z-index: 13;
}

#bootstrapoverride .about-container .zone-8 .family-bear-3 {
  top: 380px;
  left: 690px;
  z-index: 13;
}

#bootstrapoverride .about-container .zone-8 .family-bear-4 {
  top: 300px;
  left: 720px;
  z-index: 13;
}

#bootstrapoverride .about-container .zone-8 .family-bear-5 {
  top: 310px;
  left: 800px;
  z-index: 14;
}

#bootstrapoverride .about-container .zone-8 .heart-1 {
  top: 320px;
  left: 230px;
  z-index: 11;
}

#bootstrapoverride .about-container .zone-8 .heart-1 path {
  fill: #ff98ca;
}

#bootstrapoverride .about-container .zone-8 .heart-2 {
  top: 445px;
  left: 450px;
  z-index: 15;
  transform: scale(1.1);
}

#bootstrapoverride .about-container .zone-8 .heart-2 path {
  fill: #ff0000;
}

#bootstrapoverride .about-container .zone-8 .heart-3 {
  top: 490px;
  left: 565px;
  z-index: 16;
  transform: scale(1.1);
}

#bootstrapoverride .about-container .zone-8 .heart-3 path {
  fill: #ffee00;
}

#bootstrapoverride .about-container .zone-8 .heart-4 {
  top: 440px;
  left: 690px;
  z-index: 15;
  transform: scale(1.1);
}

#bootstrapoverride .about-container .zone-8 .heart-4 path {
  fill: #ff98ca;
}

#bootstrapoverride .about-container .zone-8 .heart-5 {
  top: 500px;
  left: 770px;
  z-index: 16;
  transform: scale(1.1);
}

#bootstrapoverride .about-container .zone-8 .heart-5 path {
  fill: #00dbff;
}

#bootstrapoverride .about-container .zone-8 .heart-6 {
  top: 490px;
  left: 835px;
  z-index: 15;
  transform: scale(1.1);
}

#bootstrapoverride .about-container .zone-8 .heart-6 path {
  fill: #008cff;
}

#bootstrapoverride .about-container .zone-8 .heart-7 {
  top: 280px;
  left: 1120px;
  z-index: 10;
}

#bootstrapoverride .about-container .zone-8 .heart-7 path {
  fill: #8d54c8;
}

#bootstrapoverride .about-container .zone-8 .last-heart {
  position: absolute;
  top: 330px;
  left: 630px;
  width: 50px;
  height: 50px;
  z-index: 12;
}

#bootstrapoverride .about-container .zone-8 .last-heart .heart-8 {
  transform: rotate(15deg) scale(0.3);
}

#bootstrapoverride .about-container .zone-8 .last-heart .heart-8 path {
  fill: #ff0000;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .about-container {
    max-width: 100vw;
  }
  #bootstrapoverride .about-container .about-inner-container {
    width: 100vw;
    height: 7000px;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud {
    position: absolute;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(1) {
    top: -30px;
    left: 45vw;
    width: 90vw;
    height: 370px;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(2) {
    top: 80px;
    left: 60vw;
    width: 20vw;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -4s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(2) path {
    fill: #b7ecff;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(3) {
    top: -125;
    left: -2vw;
    width: 38vw;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(4) {
    top: 300px;
    left: -40vw;
    width: 80vw;
    display: block;
    z-index: 2;
    animation-name: float-scale-06;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -1.5s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(5) {
    top: 200;
    left: 70vw;
    width: 20vw;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -2s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(5) path {
    fill: #b7ecff;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(6) {
    top: 752px;
    left: 50vw;
    width: 43vw;
    animation-name: float-scale-06;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -0.7s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(7) {
    top: 478px;
    left: 57vw;
    width: 49vw;
    transform: scale(1.4);
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(8) {
    top: 2400px;
    left: 7vw;
    width: 30vw;
    animation-name: float-scale-06;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -4s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(9) {
    top: 2500px;
    left: 50vw;
    width: 45vw;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: 0s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(10) {
    display: none;
    top: 2800px;
    left: 380px;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -0.5s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(11) {
    display: none;
    top: 2920px;
    left: 200px;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -0.2s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(12) {
    display: none;
    top: 3000px;
    left: 750px;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -1s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(13) {
    top: 3200px;
    left: 50px;
    display: none;
    animation-name: float-scale-08;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -2s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(14) {
    top: 3505px;
    left: 2vw;
    width: 40vw;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(15) {
    top: 3940px;
    left: 7vw;
    width: 30vw;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(16) {
    top: 5004px;
    left: 40vw;
    width: 60vw;
    animation-name: float-scale-14;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: 0s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(17) {
    top: 5070px;
    left: 67vw;
    width: 24vw;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -0.6s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(18) {
    top: 6000px;
    left: 48vw;
    width: 27vw;
    animation-name: float-scale-12;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -2.5s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(19) {
    top: 6100px;
    left: 65vw;
    width: 45vw;
    animation-name: float-scale-12;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -2.5s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(20) {
    top: 6434px;
    left: -20px;
    width: 60vw;
    animation-name: floating-cloud;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -3s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .about-inner-container .bg-cloud:nth-of-type(21) {
    top: 6515px;
    left: 72vw;
    width: 40vw;
    animation-name: float-scale-07;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-delay: -0.4s;
    animation-fill-mode: none;
  }
  #bootstrapoverride .about-container .flying-bear .bear-img, #bootstrapoverride .about-container .flying-bear .home-container .bear-img-2, #bootstrapoverride .home-container .about-container .flying-bear .bear-img-2 {
    width: 100px;
    top: -40px;
    left: 26px;
  }
  #bootstrapoverride .about-container .flying-bear .balloon {
    width: 60px;
  }
  #bootstrapoverride .about-container .flying-bear .balloon.balloon-5 {
    left: 110px;
    top: -137px;
  }
  #bootstrapoverride .about-container .flying-bear .balloon.balloon-6 {
    left: 85px;
    top: -147px;
  }
  #bootstrapoverride .about-container .flying-bear .balloon.balloon-7 {
    width: 100px;
    left: 26px;
    top: -150px;
  }
  #bootstrapoverride .about-container .dialog-container {
    width: 70vw;
  }
  #bootstrapoverride .about-container .dialog-container p {
    top: 167px;
    left: 13px;
    width: 60vw;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 2px;
  }
  #bootstrapoverride .about-container .dialog-container .english-font {
    font-family: "Heiti TC", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-size: 16px;
  }
  #bootstrapoverride .about-container .zone-1 {
    top: 100px;
  }
  #bootstrapoverride .about-container .zone-1 .dialog-container {
    left: 20px;
    transform: rotate(-5deg);
  }
  #bootstrapoverride .about-container .zone-1 .dialog-container svg {
    width: 65vw;
  }
  #bootstrapoverride .about-container .zone-1 .dialog-container p {
    transform: rotate(-12deg);
  }
  #bootstrapoverride .about-container .zone-1 .flying-house {
    width: 45vw;
    top: 400px;
    left: 65vw;
  }
  #bootstrapoverride .about-container .zone-1 .flying-house-smoke {
    width: 12vw;
    top: 310px;
    left: 67vw;
  }
  #bootstrapoverride .about-container .zone-1 .flying-house-plate {
    width: 20vw;
    top: 495px;
    left: 60vw;
  }
  #bootstrapoverride .about-container .zone-2 {
    top: 1260px;
  }
  #bootstrapoverride .about-container .zone-2 .svg {
    position: absolute;
  }
  #bootstrapoverride .about-container .zone-2 .about-bubble {
    transform: rotateZ(190deg) rotateX(180deg);
  }
  #bootstrapoverride .about-container .zone-2 .about-bubble path {
    fill: #ff3caa;
  }
  #bootstrapoverride .about-container .zone-2 .dialog-container {
    top: -380px;
    left: 10vw;
  }
  #bootstrapoverride .about-container .zone-2 .dialog-container svg {
    width: 60vw;
  }
  #bootstrapoverride .about-container .zone-2 .dialog-container p {
    top: 170px;
    left: 13px;
    font-size: 15px;
    transform: rotate(23deg);
  }
  #bootstrapoverride .about-container .zone-2 svg[class*="cloud"] {
    width: 23vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-4 {
    top: 0;
    left: -5vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-5 {
    top: 0;
    left: 24vw;
    transform: rotate(0deg);
  }
  #bootstrapoverride .about-container .zone-2 .cloud-6 {
    top: 70px;
    left: 7vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-7 {
    top: 60px;
    left: 37vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-8 {
    top: 140px;
    left: -5vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-9 {
    top: 140px;
    left: 24vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-10 {
    display: none;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-11 {
    top: 0;
    left: 30vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-12 {
    top: 70px;
    left: 16vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-13 {
    top: 64px;
    left: 42vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-14 {
    top: 130px;
    left: 3vw;
  }
  #bootstrapoverride .about-container .zone-2 .cloud-15 {
    top: 140px;
    left: 30vw;
  }
  #bootstrapoverride .about-container .zone-3 {
    top: 2300px;
  }
  #bootstrapoverride .about-container .zone-3 .heart-1 {
    top: -250px;
    left: 10vw;
    width: 46vw;
  }
  #bootstrapoverride .about-container .zone-3 .heart-pink {
    position: absolute;
    top: -100px;
    left: 0vw;
    transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
  }
  #bootstrapoverride .about-container .zone-3 .heart-pink .heart-2 {
    width: 30vw;
    fill: #ff72aa;
  }
  #bootstrapoverride .about-container .zone-3 .heart-pink.pop {
    transform: scale(1.4);
  }
  #bootstrapoverride .about-container .zone-3 .svg-container {
    position: absolute;
    top: -25px;
    left: 500px;
  }
  #bootstrapoverride .about-container .zone-3 .svg-container .heart-3 {
    transform: rotateZ(170deg) rotateX(180deg) scale(0.3);
  }
  #bootstrapoverride .about-container .zone-3 .mom-pinkbear {
    top: -175px;
    left: 0vw;
    width: 85vw;
  }
  #bootstrapoverride .about-container .zone-3 .dialog-container {
    position: absolute;
    top: -700px;
    left: 18vw;
    transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
  }
  #bootstrapoverride .about-container .zone-3 .dialog-container.pop {
    transform: scale(1);
  }
  #bootstrapoverride .about-container .zone-3 .dialog-container svg {
    width: 75vw;
  }
  #bootstrapoverride .about-container .zone-3 .dialog-container p {
    top: 150px;
    left: -11px;
    width: 82vw;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0;
    transform: rotate(8deg);
  }
  #bootstrapoverride .about-container .zone-3 .dialog-container .about-bubble {
    transform: rotateZ(175deg) rotateX(180deg);
  }
  #bootstrapoverride .about-container .zone-4 {
    top: 3000px;
  }
  #bootstrapoverride .about-container .zone-4 .dad-bluebear {
    top: 182px;
    left: -3vw;
    width: 70vw;
  }
  #bootstrapoverride .about-container .zone-4 .dad-bluebear-grassland {
    top: 370px;
    left: -7vw;
    width: 110vw;
  }
  #bootstrapoverride .about-container .zone-4 .dad-number-2 {
    position: absolute;
    width: 20vw;
    top: 130px;
    left: 64vw;
  }
  #bootstrapoverride .about-container .zone-4 .dialog-container {
    position: absolute;
    top: -350px;
    left: 5vw;
    transform: scale(0);
    transition: transform 0.8s cubic-bezier(0.42, 0.05, 0.56, 1.58);
  }
  #bootstrapoverride .about-container .zone-4 .dialog-container.pop {
    transform: scale(1);
  }
  #bootstrapoverride .about-container .zone-4 .dialog-container svg {
    width: 80vw;
  }
  #bootstrapoverride .about-container .zone-4 .dialog-container p {
    top: 130px;
    left: -6px;
    width: 85vw;
    font-size: 16px;
    line-height: 1;
  }
  #bootstrapoverride .about-container .zone-4 .dialog-container .about-bubble path {
    fill: #fff100;
  }
  #bootstrapoverride .about-container .zone-5 {
    top: 3650px;
  }
  #bootstrapoverride .about-container .zone-5 .sea-frog {
    top: 430px;
    left: 46vw;
    width: 80vw;
  }
  #bootstrapoverride .about-container .zone-5 .sea-rain {
    width: 5vw;
  }
  #bootstrapoverride .about-container .zone-5 img {
    position: absolute;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(2) {
    top: 0;
    left: 26vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(3) {
    top: 56px;
    left: 13vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(4) {
    top: 20px;
    left: 39vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(5) {
    top: 40px;
    left: 32vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(6) {
    top: 90px;
    left: 27vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(7) {
    top: 139px;
    left: 35vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(8) {
    top: 190px;
    left: 45vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(9) {
    top: 230px;
    left: 38vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(n+10) {
    display: block;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(10) {
    top: 250px;
    left: 27vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(11) {
    top: 260px;
    left: 47vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(12) {
    top: 313px;
    left: 41vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(13) {
    top: 300px;
    left: 85vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(14) {
    top: 320px;
    left: 76vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(15) {
    top: 367px;
    left: 80vw;
  }
  #bootstrapoverride .about-container .zone-5 img:nth-of-type(16) {
    top: 380px;
    left: 70vw;
  }
  #bootstrapoverride .about-container .zone-5 .dialog-container {
    position: absolute;
    top: -190px;
    left: 25vw;
  }
  #bootstrapoverride .about-container .zone-5 .dialog-container svg {
    width: 65vw;
    transform: rotateY(180deg) rotateZ(2deg);
  }
  #bootstrapoverride .about-container .zone-5 .dialog-container p {
    top: 180px;
    left: 19vw;
    width: 48vw;
    font-size: 14px;
    transform: rotate(22deg);
  }
  #bootstrapoverride .about-container .zone-5 .dialog-container .about-bubble {
    transform: rotateZ(190deg) rotateX(180deg);
  }
  #bootstrapoverride .about-container .zone-5 .dialog-container path {
    transform: scale(0.75);
  }
  #bootstrapoverride .about-container .zone-6 {
    top: 4320px;
  }
  #bootstrapoverride .about-container .zone-6 .sea-sea {
    top: 100px;
    left: -73vw;
    width: 240vw;
  }
  #bootstrapoverride .about-container .zone-6 .sea-dolphin {
    top: 289px;
    left: 58vw;
    width: 40vw;
  }
  #bootstrapoverride .about-container .zone-6 .dialog-container {
    position: absolute;
    top: -250px;
    left: 3vw;
    transform: rotate(0deg);
  }
  #bootstrapoverride .about-container .zone-6 .dialog-container svg {
    width: 70vw;
  }
  #bootstrapoverride .about-container .zone-6 .dialog-container p {
    top: 142px;
    left: 2vw;
    width: 39vw;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
    transform: rotate(-10deg);
  }
  #bootstrapoverride .about-container .zone-6 .sea-fish-left {
    top: 205px;
    left: 0vw;
    width: 50vw;
  }
  #bootstrapoverride .about-container .zone-6 .sea-fish-right {
    top: 205px;
    left: 50vw;
    width: 50vw;
  }
  #bootstrapoverride .about-container .zone-7 {
    top: 5320px;
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.left {
    position: absolute;
    top: -40px;
    left: 30vw;
    transform: rotate(0deg);
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.left svg {
    width: 85vw;
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.left p {
    top: 135px;
    left: 0;
    width: 50vw;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 1px;
    transform: rotate(-5deg);
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.left path {
    fill: #00b400;
    transform: scale(0.6);
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.right {
    position: absolute;
    top: -662px;
    left: 16vw;
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.right svg {
    width: 76vw;
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.right p {
    top: 157px;
    left: 29vw;
    width: 50vw;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 1px;
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.right .about-bubble {
    transform: rotateZ(190deg) rotateX(180deg);
  }
  #bootstrapoverride .about-container .zone-7 .dialog-container.right path {
    fill: #ff3caa;
    transform: scale(0.7);
  }
  #bootstrapoverride .about-container .zone-7 .book-pegasus {
    position: absolute;
    top: -300px;
    left: 30vw;
    width: 60vw;
  }
  #bootstrapoverride .about-container .zone-7 .book-owl {
    position: absolute;
    top: 230px;
    left: -30vw;
    width: 130vw;
  }
  #bootstrapoverride .about-container .zone-7 .book-paris {
    position: absolute;
    top: 150px;
    left: -10vw;
    width: 52vw;
  }
  #bootstrapoverride .about-container .zone-7 .book-us {
    position: absolute;
    top: 376px;
    left: -7vw;
    width: 68vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-1 {
    top: -120px;
    left: 0vw;
    width: 32vw;
    transform: scale(0.8) rotate(-15deg);
  }
  #bootstrapoverride .about-container .zone-7 .star-2 {
    top: -550px;
    left: 4vw;
    width: 30vw;
    transform: rotate(150deg) scale(0.5);
  }
  #bootstrapoverride .about-container .zone-7 .star-3 {
    top: -440px;
    left: 85vw;
    width: 10vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-4 {
    top: 427px;
    left: 65vw;
    width: 16vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-5 {
    top: 540px;
    left: 75vw;
    width: 7vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-6 {
    top: 700px;
    left: 10vw;
    width: 28vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-7 {
    position: absolute;
    top: 720px;
    left: 86vw;
    width: 6vw;
  }
  #bootstrapoverride .about-container .zone-7 .star-8 {
    top: 600px;
    left: 56vw;
    width: 30vw;
  }
  #bootstrapoverride .about-container .zone-8 {
    position: absolute;
    bottom: 0;
    left: -50px;
    height: 730px;
  }
  #bootstrapoverride .about-container .zone-8 svg {
    position: absolute;
  }
  #bootstrapoverride .about-container .zone-8 .dialog-container {
    top: -100px;
    left: 20vw;
  }
  #bootstrapoverride .about-container .zone-8 .dialog-container svg {
    width: 70vw;
  }
  #bootstrapoverride .about-container .zone-8 .dialog-container p {
    width: 60vw;
    top: 159px;
    line-height: 1.2;
    transform: rotate(-14deg);
  }
  #bootstrapoverride .about-container .zone-8 .family-bg {
    bottom: 0;
    left: -65vw;
    width: 250vw;
    z-index: 11;
  }
  #bootstrapoverride .about-container .zone-8 img[class*="family-bear"] {
    width: 18vw;
  }
  #bootstrapoverride .about-container .zone-8 .family-bear-1 {
    top: auto;
    bottom: 70px;
    left: 37vw;
  }
  #bootstrapoverride .about-container .zone-8 .family-bear-2 {
    top: auto;
    bottom: 60px;
    left: 43vw;
  }
  #bootstrapoverride .about-container .zone-8 .family-bear-3 {
    top: auto;
    bottom: 100px;
    left: 54vw;
    width: 13vw !important;
  }
  #bootstrapoverride .about-container .zone-8 .family-bear-4 {
    top: auto;
    bottom: 70px;
    left: 61vw;
  }
  #bootstrapoverride .about-container .zone-8 .family-bear-5 {
    top: auto;
    bottom: 70px;
    left: 69vw;
  }
  #bootstrapoverride .about-container .zone-8 .heart-1 {
    top: auto;
    left: 2vw;
    bottom: 60px;
    width: 40vw;
  }
  #bootstrapoverride .about-container .zone-8 .heart-2 {
    top: auto;
    bottom: -25px;
    left: 32vw;
    width: 20vw;
  }
  #bootstrapoverride .about-container .zone-8 .heart-3 {
    top: auto;
    bottom: -20px;
    left: 46vw;
    width: 16vw;
    transform: scale(1.1) rotate(-17deg);
  }
  #bootstrapoverride .about-container .zone-8 .heart-4 {
    top: auto;
    bottom: -10px;
    left: 54vw;
    width: 21vw;
  }
  #bootstrapoverride .about-container .zone-8 .heart-5 {
    top: auto;
    bottom: -20px;
    left: 62vw;
    width: 20vw;
    z-index: 16;
  }
  #bootstrapoverride .about-container .zone-8 .heart-6 {
    top: auto;
    bottom: -16px;
    left: 70vw;
    width: 26vw;
  }
  #bootstrapoverride .about-container .zone-8 .heart-7 {
    top: auto;
    bottom: 70px;
    left: 90vw;
    width: 27vw;
    z-index: -1;
  }
  #bootstrapoverride .about-container .zone-8 .last-heart {
    position: absolute;
    top: 330px;
    left: 630px;
    width: 50px;
    height: 50px;
    z-index: 12;
  }
  #bootstrapoverride .about-container .zone-8 .last-heart .heart-8 {
    transform: rotate(15deg) scale(0.3);
  }
  #bootstrapoverride .about-container .zone-8 .last-heart .heart-8 path {
    fill: #ff0000;
  }
}

@media all and (max-width: 340px) {
  .zone-4 .dad-bluebear-grassland {
    top: 340px !important;
  }
}

#bootstrapoverride .login-container {
  position: relative;
  width: 685px;
  height: 330px;
  margin: 40px auto 140px;
}

#bootstrapoverride .login-container .login-box {
  position: absolute;
  top: 44px;
  left: 0;
  width: 685px;
  background-color: white;
  border: 5px solid #f0190f;
  border-radius: 20px;
}

#bootstrapoverride .login-container .login-box:after {
  content: url("../img/signin-lowerleftbear.png");
  position: absolute;
  bottom: -36px;
  left: 20px;
}

#bootstrapoverride .login-container .login-box .row {
  width: 65%;
  margin: 0 auto 20px;
}

#bootstrapoverride .login-container .login-box label {
  font-size: 16px;
  color: #595757;
}

#bootstrapoverride .login-container .login-box input {
  width: 100%;
  appearance: none;
  border: 1px solid #808080;
  border-radius: 20px;
  padding: 5px 15px;
  background: transparent;
}

#bootstrapoverride .login-container .login-box input[type="checkbox"] {
  -webkit-appearance: checkbox;
  width: auto;
}

#bootstrapoverride .login-container .login-box .password-col {
  position: relative;
}

#bootstrapoverride .login-container .login-box .password-col .forgot-password {
  position: absolute;
  bottom: -24px;
  right: 18px;
  font-size: 12px;
  color: #f0190f;
}

#bootstrapoverride .login-container .login-box .login-buttons {
  margin-top: 70px;
  margin-bottom: 40px;
}

#bootstrapoverride .login-container .login-box .login-buttons .no-padding {
  padding: 0;
}

#bootstrapoverride .login-container .login-box .red-button, #bootstrapoverride .login-container .login-box .white-button {
  width: 110px;
  margin-right: 0;
  border-color: none;
}

#bootstrapoverride .login-container .login-box .white-button {
  width: 110px;
}

#bootstrapoverride .login-container .login-box .warning {
  margin: 0 0 30px 50px;
}

#bootstrapoverride .login-container .login-box .warning img {
  display: inline-block;
  margin-right: 7px;
}

#bootstrapoverride .login-container .login-box .warning p {
  font-size: 16px;
  color: #f0190f;
}

#bootstrapoverride .login-container form[name="form-login"] {
  margin-top: 50px;
}

#bootstrapoverride .login-container #reset-password-tab {
  display: inline;
}

#bootstrapoverride .login-container form[name="form-reset-password"] {
  display: block;
  margin-top: 30px;
}

#bootstrapoverride .login-container form[name="form-reset-password"] .red-button, #bootstrapoverride .login-container form[name="form-reset-password"] .white-button {
  width: 140px;
}

#bootstrapoverride .login-container form[name="form-reset-password-code"] {
  display: none;
  margin-top: 30px;
}

#bootstrapoverride .login-container form[name="form-reset-password-code"] .red-button, #bootstrapoverride .login-container form[name="form-reset-password-code"] .white-button {
  width: 140px;
}

#bootstrapoverride .login-container form[name="form-new-password"] {
  display: block;
  margin-top: 30px;
}

#bootstrapoverride .login-container form[name="form-new-password"] .red-button, #bootstrapoverride .login-container form[name="form-new-password"] .white-button {
  width: 140px;
}

#bootstrapoverride form[name="user-registration-form"] {
  width: 70%;
  margin: 50px auto;
}

#bootstrapoverride form[name="user-registration-form"] .row:first-of-type {
  margin-bottom: 70px;
}

#bootstrapoverride form[name="user-registration-form"] .remark {
  position: absolute;
  display: inline-block;
  bottom: -50px;
  left: 15px;
  width: 82%;
}

#bootstrapoverride form[name="user-registration-form"] .remark p {
  font-size: 10px;
  color: #6d6d6d;
  letter-spacing: 1px;
}

#bootstrapoverride form[name="user-registration-form"] .remark span {
  vertical-align: middle;
}

#bootstrapoverride form[name="user-registration-form"] .remark .red-button, #bootstrapoverride form[name="user-registration-form"] .remark .white-button {
  display: inline-block;
  width: 72px;
  height: 20px;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

#bootstrapoverride form[name="user-registration-form"] .confirmation-code-btn {
  width: 125px;
  height: 35px;
  background-color: #808080;
  color: white;
  font-size: 18px;
  padding: 4px;
  text-align: center;
  border: none;
}

#bootstrapoverride form[name="user-registration-form"] .margin-top {
  margin-top: 50px;
}

#bootstrapoverride form[name="user-registration-form"] .row-tighter {
  margin-bottom: 10px;
}

#bootstrapoverride form[name="user-registration-form"] .red-button, #bootstrapoverride form[name="user-registration-form"] .white-button {
  width: 110px;
  border: none;
  margin-top: 30px;
}

#bootstrapoverride .conditions-container .inner-container {
  padding: 50px 40px;
}

#bootstrapoverride .conditions-container .conditions-button {
  position: absolute;
  bottom: -50px;
  right: 0;
  width: 85px;
  height: 26px;
  font-size: 18px;
  padding: 2px 0 2px 14px;
}

#bootstrapoverride .conditions-container .conditions-button .arrow-up {
  top: 5px;
  left: -2px;
}

#conditions-page-top {
  position: absolute;
  top: 0;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .login-container {
    width: 90vw;
    margin: 80px auto 140px;
  }
  #bootstrapoverride .login-container .login-box {
    width: 90vw;
    border-radius: 8px;
  }
  #bootstrapoverride .login-container .login-box:after {
    background-size: 80px 80px;
    content: "";
    background-image: url("../img/signin-lowerleftbear.png");
    height: 80px;
    width: 80px;
  }
  #bootstrapoverride .login-container .login-box .row {
    width: 90%;
  }
  #bootstrapoverride .login-container .login-box .col-sm-4 {
    min-width: 100px;
  }
  #bootstrapoverride .login-container .login-box .red-button, #bootstrapoverride .login-container .login-box .white-button {
    float: right;
    margin-left: 9px;
    width: 100px;
  }
  #bootstrapoverride .login-container .login-box .warning {
    margin: 0px 0px 30px 15px;
  }
  #bootstrapoverride form[name="user-registration-form"] {
    width: 90%;
  }
  #bootstrapoverride form[name="user-registration-form"] .row:first-of-type {
    margin-bottom: 125px;
  }
  #bootstrapoverride form[name="user-registration-form"] .remark {
    position: absolute;
    display: inline-block;
    bottom: -110px;
    left: 25px;
    width: 82%;
  }
  #bootstrapoverride form[name="user-registration-form"] .remark p {
    font-size: 10px;
    color: #6d6d6d;
    letter-spacing: 1px;
  }
  #bootstrapoverride form[name="user-registration-form"] .remark span {
    vertical-align: middle;
  }
  #bootstrapoverride form[name="user-registration-form"] .remark .red-button, #bootstrapoverride form[name="user-registration-form"] .remark .white-button {
    display: inline-block;
    width: 72px;
    height: 20px;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  #bootstrapoverride form[name="user-registration-form"] .confirmation-code-btn {
    width: 125px;
    height: 35px;
    background-color: #808080;
    color: white;
    font-size: 18px;
    padding: 4px;
    text-align: center;
    border: none;
  }
  #bootstrapoverride form[name="user-registration-form"] .margin-top {
    margin-top: 50px;
  }
  #bootstrapoverride form[name="user-registration-form"] .row-tighter {
    margin-bottom: 10px;
  }
  #bootstrapoverride form[name="user-registration-form"] .red-button, #bootstrapoverride form[name="user-registration-form"] .white-button {
    width: 110px;
    border: none;
    margin-top: 30px;
  }
  .register-container .col-sm-9, .register-container .col-sm-5 {
    width: 100%;
  }
  .register-container .col-sm-5.name {
    padding-right: 3px;
  }
  .register-container .col-sm-2.gender {
    display: inline-table;
    padding: 3px;
  }
  .register-container .col-sm-2.gender.male {
    padding-right: 60px;
  }
  .register-container .col-sm-2.gender label {
    padding-left: 3px !important;
  }
  .register-container .dropdown-col {
    padding-right: 0px;
    width: 23%;
    min-width: 20% !important;
  }
  #bootstrapoverride .conditions-container .inner-container {
    padding: 15px;
  }
}

#bootstrapoverride .account-container .inner-container {
  padding: 50px 20px;
}

#bootstrapoverride .account-container .inner-container:after {
  content: url("../img/container-righttop-yellowbear.svg");
  position: absolute;
  top: -94px;
  right: 100px;
}

#bootstrapoverride .account-container .order-detail {
  color: #808080;
}

#bootstrapoverride .account-container .order-detail .row {
  margin-left: 0;
  margin-right: 0;
  padding: 5px 0;
}

#bootstrapoverride .account-container .order-detail .table-header {
  color: #f0190f;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 5px;
}

#bootstrapoverride .account-container .order-detail .row.order-detail {
  border: 1px solid #f0190f;
  border-radius: 15px;
  margin-bottom: 20px;
}

#bootstrapoverride .account-container .order-detail .row.order-detail .order-number {
  cursor: pointer;
}

#bootstrapoverride .account-container .order-detail .row.order-detail .order-number:hover {
  color: #231f20;
  text-decoration: underline;
}

#bootstrapoverride .account-container .order-detail div[class*="col"] {
  padding: 0;
  text-align: center;
}

#bootstrapoverride .account-container form[name="user-info-change-form"] {
  width: 71%;
  margin: 0 auto;
}

#bootstrapoverride .account-container form[name="user-info-change-form"] .confirmation-code-btn {
  width: 125px;
  height: 35px;
  background-color: #808080;
  color: white;
  font-size: 18px;
  padding: 4px;
  text-align: center;
  border: none;
}

#bootstrapoverride .account-container form[name="user-info-change-form"] .red-button, #bootstrapoverride .account-container form[name="user-info-change-form"] .white-button {
  border: none;
  margin-right: 0;
}

#bootstrapoverride .account-container .old-order-details .row {
  margin-left: 0;
  margin-right: 0;
}

#bootstrapoverride .account-container .old-order-details .cart-detail-container {
  margin: 20px 0 0 0;
}

#bootstrapoverride .account-container .old-order-details .checkout-summary-container {
  margin: 20px 0 0 0;
}

#bootstrapoverride .account-container .old-order-details .order-number {
  height: 50px;
  background-color: #f0190f;
  border-radius: 25px;
}

#bootstrapoverride .account-container .old-order-details .order-number p {
  font-size: 18px;
  color: white;
  margin-left: 20px;
}

.mobile-table-header {
  display: none;
}

#bootstrapoverride .favorites-container .inner-container {
  padding: 10px 10px 30px;
}

#bootstrapoverride .favorites-container .inner-container:after {
  content: url("../img/container-righttop-yellowbear.svg");
  position: absolute;
  top: -95px;
  right: 100px;
}

#bootstrapoverride .favorites-container .row {
  margin-left: 0;
  margin-right: 0;
}

#bootstrapoverride .favorites-container .table-header {
  color: #f0190f;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0;
}

#bootstrapoverride .favorites-container .cart-detail .row {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
}

#bootstrapoverride .favorites-container .product-info {
  border: 1px solid #f0190f;
  border-radius: 25px;
  margin-bottom: 15px;
  padding-top: 6px;
}

#bootstrapoverride .favorites-container .product-info .favorite-add-to-cart {
  font-size: 17px;
  color: #f0190f;
  letter-spacing: 2px;
}

#bootstrapoverride .favorites-container .product-info .remove-item-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0190f;
}

#bootstrapoverride .favorites-container div[class*="col"] {
  padding: 0;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .account-container .mobile-table-header {
    display: block;
  }
  #bootstrapoverride .account-container .desktop-table-header {
    display: none;
    margin: -20px auto 10px;
  }
  #bootstrapoverride .account-container .inner-container {
    padding: 10px 5px 40px;
    border-radius: 8px;
  }
  #bootstrapoverride .account-container .inner-container:after {
    display: none;
  }
  #bootstrapoverride .account-container .mobile-not-show {
    display: none;
  }
  #bootstrapoverride .account-container .mobile-show {
    display: initial;
  }
  #bootstrapoverride .account-container .order-detail div[class*="col"] {
    float: left;
    width: 20%;
  }
  #bootstrapoverride .account-container .order-detail .row.order-detail {
    border-radius: 8px;
  }
  #bootstrapoverride .account-container .order-number {
    text-decoration: underline;
  }
  #bootstrapoverride .account-container .old-order-details .order-number {
    height: 22px;
    border-radius: 100px;
  }
  #bootstrapoverride .account-container .old-order-details .order-number p {
    font-size: 11px;
  }
  #bootstrapoverride .account-container form[name="user-info-change-form"] {
    width: 90%;
  }
  #bootstrapoverride .account-container form[name="user-info-change-form"] .col-sm-9 {
    width: 100%;
  }
  #bootstrapoverride .account-container form[name="user-info-change-form"] .col-sm-3 {
    min-width: 25% !important;
    padding-right: 0px;
  }
  #bootstrapoverride .favorites-container {
    font-size: 11px;
  }
  #bootstrapoverride .favorites-container div[class*="col"] {
    font-size: 11px;
    width: 100%;
  }
  #bootstrapoverride .favorites-container div[class*="col"] p {
    font-size: 11px;
    line-height: 1.36;
  }
  #bootstrapoverride .favorites-container .inner-container:after {
    right: 0px;
  }
  #bootstrapoverride .favorites-container .cart-detail {
    width: 83vw;
  }
  #bootstrapoverride .favorites-container .cart-detail .row {
    border-radius: 8px;
  }
  #bootstrapoverride .favorites-container .cart-detail .item-photo img {
    width: 50px;
    height: 50px;
    padding: 5px;
  }
  #bootstrapoverride .favorites-container .cart-detail .favorite-add-to-cart img {
    width: 27px;
    height: 23px;
    margin-top: 20px;
  }
  #bootstrapoverride .favorites-container .cart-detail .col-sm-3 {
    min-width: auto;
  }
  #bootstrapoverride .favorites-container .cart-detail .quantity-adjust {
    width: 55px;
  }
  #bootstrapoverride .old-order-details .order-number {
    height: 22px;
  }
  #bootstrapoverride .old-order-details .order-number p {
    font-size: 11px;
  }
  #bootstrapoverride .old-order-details .cart-detail-container {
    border-radius: 8px !important;
  }
  #bootstrapoverride .old-order-details .cart-detail {
    width: 80vw !important;
  }
  #bootstrapoverride .checkout-summary-container {
    border-radius: 8px !important;
  }
  #bootstrapoverride .checkout-summary-container .header {
    padding: 5px 30px;
    height: 33px;
    margin-bottom: 10px;
  }
  #bootstrapoverride .checkout-summary-container .header div {
    font-size: 12px;
  }
  #bootstrapoverride .checkout-summary-container .header:after {
    width: 90%;
  }
  #bootstrapoverride .checkout-summary-container .header.overscore:before {
    width: 90%;
  }
}

#bootstrapoverride .faq-container .position-1 {
  left: 0 !important;
}

#bootstrapoverride .faq-container .position-2 {
  left: 150px !important;
}

#bootstrapoverride .faq-container .position-3 {
  left: 300px !important;
}

#bootstrapoverride .faq-container .position-4 {
  left: 450px !important;
}

#bootstrapoverride .faq-container .position-5 {
  left: 600px !important;
}

#bootstrapoverride .faq-container .position-6 {
  left: 750px !important;
}

#bootstrapoverride .faq-container .faq-nav-tab {
  width: 150px !important;
}

#bootstrapoverride .faq-container .tab-inactive {
  font-size: 18px !important;
}

#bootstrapoverride .faq-container .tab-inactive p {
  font-size: 18px !important;
}

#bootstrapoverride .faq-container .tab {
  font-size: 18px !important;
}

#bootstrapoverride .faq-container .tab:after {
  left: 147px !important;
}

#bootstrapoverride .faq-container .tab p {
  font-size: 18px !important;
}

#bootstrapoverride .faq-container .qa-list-note {
  margin-left: 40px;
}

#bootstrapoverride .faq-container .inner-container {
  color: #595757;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap {
  width: 600px;
  margin: 50px auto;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-title {
  font-size: 15px;
  line-height: 2;
  color: #595757;
  cursor: pointer;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-title span {
  position: relative;
  color: white;
  margin-right: 10px;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-title span:after {
  position: absolute;
  top: -2px;
  left: -6px;
  content: "";
  width: 20px;
  height: 20px;
  background-color: #f47e20;
  border-radius: 50%;
  z-index: -1;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-title-2 span {
  margin-left: -4px;
  margin-right: 7px;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-title-2 span:after {
  left: -1px;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-content {
  padding: 0px 30px;
}

#bootstrapoverride .faq-container .inner-container .inner-wrap .q-content .split-line {
  width: 600px;
  height: 1px;
  margin: 8px 0px 8px -30px;
  border-top: dashed 1px #f0190f;
}

#bootstrapoverride .faq-container .inner-container li {
  position: relative;
  margin-left: 40px;
  line-height: 2;
}

#bootstrapoverride .faq-container .inner-container li:before {
  content: ".";
  padding-right: 8px;
  color: blue;
  display: block;
  position: absolute;
  top: -5px;
  left: -24px;
}

#bootstrapoverride .faq-container .inner-container .dark-title {
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #231f20;
}

#bootstrapoverride .faq-container .inner-container .step-title {
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 2.4px;
  color: #f0190f;
  line-height: 2;
  margin-top: 5px;
}

#bootstrapoverride .faq-container .inner-container .step-title > div {
  line-height: 2;
}

#bootstrapoverride .faq-container .inner-container .sub-title {
  color: #595757;
}

#bootstrapoverride .faq-container .inner-container table {
  width: 600px;
  margin: 20px 0px 0px -30px;
  border-bottom: solid 2px #f0190f;
}

#bootstrapoverride .faq-container .inner-container table th {
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #231f20;
  border-bottom: solid 2px #f0190f;
  min-width: 120px;
  text-align: center;
}

#bootstrapoverride .faq-container .inner-container table tr {
  font-size: 15px;
  line-height: 2;
  color: #595757;
  border-bottom: dashed 1px #f0190f;
  min-width: 120px;
}

#bootstrapoverride .faq-container .inner-container table tr td {
  padding: 20px;
}

#bootstrapoverride .faq-container .inner-container .note-table {
  border-bottom: none;
  border-bottom-border-bottom: none;
}

#bootstrapoverride .faq-container .inner-container .note-table tr {
  width: 500px;
  border-bottom: none;
}

#bootstrapoverride .faq-container .inner-container .note-table tr th {
  border-bottom: none;
  min-width: 60px;
}

#bootstrapoverride .faq-container .inner-container .note-table tr td {
  padding: 5px;
  line-height: 1.5;
}

#bootstrapoverride .faq-button {
  width: 85px;
  height: 26px;
  float: right;
  margin-top: 20px;
  font-size: 16px;
  padding: 3px 0px 0px 20px;
}

#bootstrapoverride .faq-button span {
  font-size: 16px;
}

#bootstrapoverride .faq-button span:after {
  bottom: 7px;
}

@media all and (max-width: 500px) {
  #bootstrapoverride .faq-container div {
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .faq-nav-tab {
    width: 90vw !important;
  }
  #bootstrapoverride .faq-container .inner-container .inner-wrap {
    width: 75vw;
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container .inner-wrap .q-title {
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container .inner-wrap .q-content {
    font-size: 11px;
    padding: 0px 15px;
  }
  #bootstrapoverride .faq-container .inner-container .inner-wrap .q-content .split-line {
    width: 75vw;
    margin: 8px 0px 8px -20px;
  }
  #bootstrapoverride .faq-container .inner-container .dark-title {
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container li {
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container table {
    width: 75vw;
    margin: 20px 0px 0px -15px;
  }
  #bootstrapoverride .faq-container .inner-container table th {
    min-width: 25vw;
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container table tr {
    min-width: 25vw;
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container table tr td {
    padding: 0px;
    font-size: 11px;
  }
  #bootstrapoverride .faq-container .inner-container .step-title {
    font-size: 11px;
    letter-spacing: 1.4px;
  }
}

.order-number-container {
  width: 820px;
  height: 50px;
  border-radius: 100px;
  background-color: #f0190f;
  margin: 40px auto 20px;
  font-size: 18px !important;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 2px;
  color: #ffffff;
  padding: 15px 35px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track-piece {
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: orange;
  background: red;
  color: blue;
  border-radius: 10px;
}

.chatbox {
  border-bottom: solid 6px #f0190f;
  height: 400px;
  overflow-y: auto;
  padding: 0px 20px;
  scrollbar-face-color: green;
  scrollbar-track-color: blue;
}

.chatbox img {
  margin: 0px 16px;
}

.chatbox .message-box {
  position: relative;
  max-width: 440px;
  min-height: 50px;
  height: auto;
  word-break: break-all;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.chatbox .message-box:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.chatbox .time-stamp {
  width: 70px;
  margin: 0px 5px;
  color: #808080;
}

.chatbox .user-box {
  min-height: 40px;
  overflow: hidden;
}

.chatbox .user-box .message-box {
  background-color: #d9f5ff;
}

.chatbox .user-box .message-box:after {
  border-width: 20px 20px 0 0;
  border-color: #d9f5ff transparent transparent transparent;
  top: 0px;
  right: -13px;
}

.chatbox .server-box {
  min-height: 40px;
  overflow: hidden;
}

.chatbox .server-box .message-box {
  background-color: #ececec;
}

.chatbox .server-box .message-box:after {
  border-width: 0 20px 20px 0;
  border-color: transparent #ececec transparent transparent;
  top: 0px;
  left: -13px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.message-form {
  width: 820px;
  height: 60px;
  border-radius: 20px;
  border: solid 1px #ff372d;
  margin: 17px auto;
}

.message-form .messages {
  border: none !important;
  height: inherit !important;
  width: 725px !important;
  float: left;
  padding: 20px !important;
  overflow: hidden;
  font-size: 20px;
}

.message-form .message-send-btn {
  width: 66px !important;
  float: left;
  margin: 13px !important;
}

@media all and (max-width: 500px) {
  .order-number-container {
    width: 80vw;
    height: 22px;
    margin: 16px auto 20px;
    font-size: 11px !important;
    padding: 1px 20px;
  }
  .chatbox {
    padding: 0px;
  }
  .chatbox img {
    margin: 0px 2px;
    width: 15%;
    margin-top: 10px;
  }
  .chatbox .message-box {
    max-width: 40vw;
  }
  .message-form {
    width: 80vw;
    height: 35px;
    border-radius: 12px;
  }
  .message-form .messages {
    padding: 6px 10px !important;
    width: 60vw !important;
  }
  .message-form .message-send-btn {
    width: 40px !important;
    height: 20px !important;
    margin: 7px !important;
    float: right;
    padding: 0px !important;
  }
}
