

#bootstrapoverride .account-container {
	.inner-container {
		padding: 50px 20px;

		&:after {
			content: url("../img/container-righttop-yellowbear.svg");
			position: absolute;
			top: -94px;
			right: 100px; } }


	.order-detail {
		color: #808080;
		.row {
			margin-left: 0;
			margin-right: 0;
			padding: 5px 0; }

		.table-header {
			color: $vermillion;
			font-size: 16px;
			font-weight: bold;
			padding: 10px 0;
			margin-bottom: 5px; }

		.row.order-detail {
			border: 1px solid $vermillion;
			border-radius: 15px;
			margin-bottom: 20px;

			.order-number {
				cursor: pointer;

				&:hover {
					color: #231f20;
					text-decoration: underline; } } }

		div[class*="col"] {
			padding: 0;
			text-align: center; } }


	form[name="user-info-change-form"] {
		width: 71%;
		margin: 0 auto;

		.confirmation-code-btn {
			width: 125px;
			height: 35px;
			background-color: #808080;
			color: white;
			font-size: 18px;
			padding: 4px;
			text-align: center;
			border: none; }

		.red-button {
			border: none;
			margin-right: 0; } }


	.old-order-details {
		.row {
			margin-left: 0;
			margin-right: 0; }

		.cart-detail-container {
			margin: 20px 0 0 0; }

		.checkout-summary-container {
			margin: 20px 0 0 0; }

		.order-number {
			height: 50px;
			background-color: $vermillion;
			border-radius: 25px;

			p {
				font-size: 18px;
				color: white;
				margin-left: 20px; } } } }

.mobile-table-header {
	display: none; }



#bootstrapoverride .favorites-container {
	.inner-container {
		padding: 10px 10px 30px;

		&:after {
			content: url("../img/container-righttop-yellowbear.svg");
			position: absolute;
			top: -95px;
			right: 100px; } }

	.row {
		margin-left: 0;
		margin-right: 0; }


	.table-header {
		color: $vermillion;
		font-size: 16px;
		font-weight: bold;
		padding: 10px 0; }

	.cart-detail .row {
		position: relative;
		display: flex;
		align-items: center;
		text-align: center; }

	.product-info {
		border: 1px solid $vermillion;
		border-radius: 25px;
		margin-bottom: 15px;
		padding-top: 6px;

		.favorite-add-to-cart {
			font-size: 17px;
			color: $vermillion;
			letter-spacing: 2px; }

		.remove-item-btn {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: $vermillion; } }


	div[class*="col"] {
		padding: 0; } }

@media all and (max-width: $mobile) {
	#bootstrapoverride .account-container {
		.mobile-table-header {
			display: block; }
		.desktop-table-header {
			display: none;
			margin: -20px auto 10px; }

		.inner-container {
			padding: 10px 5px 40px;
			border-radius: 8px;
			&:after {
				display: none; } }
		.mobile-not-show {
			display: none; }
		.mobile-show {
			display: initial; }
		.order-detail {
			div[class*="col"] {
				float: left;
				width: 20%; }

			.row.order-detail {
				border-radius: 8px; } }
		.order-number {
			text-decoration: underline; }
		.old-order-details {
			.order-number {
				height: 22px;
				border-radius: 100px;
				p {
					font-size: 11px; } } }

		form[name="user-info-change-form"] {
			width: 90%;

			.col-sm-9 {
				width: 100%; }
			.col-sm-3 {
				min-width: 25% !important;
				padding-right: 0px; } } }
	#bootstrapoverride .favorites-container {
		font-size: 11px;
		div[class*="col"] {
			font-size: 11px;
			width: 100%;
			p {
				font-size: 11px;
				line-height: 1.36; } }
		.inner-container {
			&:after {
				right: 0px; } }
		.cart-detail {
			width: 83vw;
			.row {
				border-radius: 8px; }
			.item-photo {
				img {
					width: 50px;
					height: 50px;
					padding: 5px; } }
			.favorite-add-to-cart {
				img {
					width: 27px;
					height: 23px;
					margin-top: 20px; } }
			.col-sm-3 {
				min-width: auto; }

			.quantity-adjust {
				width: 55px; } } }
	#bootstrapoverride .old-order-details {
		.order-number {
			height: 22px;
			p {
				font-size: 11px; } }
		.cart-detail-container {
			border-radius: 8px !important; }
		.cart-detail {
			width: 80vw !important; } }

	#bootstrapoverride .checkout-summary-container {
		border-radius: 8px !important;

		.header {
			padding: 5px 30px;
			height: 33px;
			margin-bottom: 10px;
			div {
				font-size: 12px; }
			&:after {
				width: 90%; }
			&.overscore:before {
				width: 90%; } } } }

