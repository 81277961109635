
// colors
$blue: #0092ff;
$light-blue: #97e4ff;
$pink: #ff3caa;
$light-pink: #ff72aa;
$red: #ff0000;
$light-green: #aae600;
$medium-green: #52cd28;
$green: #00b400;
$yellow: #ffee00;
$orange: #ff9400;
$purple: #8d54c8;


#bootstrapoverride .loading-mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 9999;

	&.hidden {
		display: none; } }


#bootstrapoverride .about-container {
	width: 100%;
	max-width: 1780px;
	margin: 200px auto 0;

	img {
		position: absolute; }


	.about-inner-container {
		position: relative;
		width: 1400px;
		height: 8700px;
		margin: 0 auto;

		.bg-cloud {
			position: absolute;

			&:nth-of-type(1) {
				top: -70px;
				left: 960px;
				width: 450px;
				height: 370px;
				@include animation(float-scale-06, ease-in-out, infinite, 5s, -2s, none);
				path {
					fill: #b5ffff; } }
			&:nth-of-type(2) {
				top: 90px;
				left: 990px;
				width: 200px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -4s, none);
				path {
					fill: #b7ecff; } }
			&:nth-of-type(3) {
				top: 50px;
				left: -100px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -.9s, none);
				path {
					fill: #b7ecff; } }
			&:nth-of-type(4) {
				top: 250px;
				left: 500px;
				display: none;
				@include animation(float-scale-06, ease-in-out, infinite, 5s, -1.5s, none); }
			&:nth-of-type(5) {
				top: 550px;
				left: 1100px;
				width: 200px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -2s, none);
				path {
					fill: #b7ecff; } }
			&:nth-of-type(6) {
				top: 800px;
				left: 0;
				@include animation(float-scale-06, ease-in-out, infinite, 5s, -.7s, none); }
			&:nth-of-type(7) {
				top: 950px;
				left: 850px;
				width: 380px;
				transform: scale(1.4); }
			&:nth-of-type(8) {
				top: 1200px;
				left: 300px;
				@include animation(float-scale-06, ease-in-out, infinite, 5s, -4s, none); }
			&:nth-of-type(9) {
				top: 3000px;
				left: 900px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, 0s, none); }
			&:nth-of-type(10) {
				display: none;
				top: 2800px;
				left: 380px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -.5s, none); }
			&:nth-of-type(11) {
				display: none;
				top: 2920px;
				left: 200px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -.2s, none); }
			&:nth-of-type(12) {
				display: none;
				top: 3000px;
				left: 750px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -1s, none); }
			&:nth-of-type(13) {
				top: 3200px;
				left: 50px;
				@include animation(float-scale-08, ease-in-out, infinite, 5s, -2s, none); }
			&:nth-of-type(14) {
				top: 4450px;
				left: 200px;
				path {
					fill: #e6e6e5; } }

			&:nth-of-type(15) {
				top: 4650px;
				left: 1000px;
				path {
					fill: #cccbcb; } }
			&:nth-of-type(16) {
				top: 6550px;
				left: 980px;
				@include animation(float-scale-14, ease-in-out, infinite, 5s, 0s, none); }
			&:nth-of-type(17) {
				top: 6650px;
				left: 1170px;
				width: 170px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -.6s, none); }
			&:nth-of-type(18) {
				top: 7050px;
				left: 600px;
				@include animation(float-scale-12, ease-in-out, infinite, 5s, -2.5s, none);
				path {
					fill: #80d9ff; } }
			&:nth-of-type(19) {
				top: 7350px;
				left: 1120px;
				@include animation(float-scale-12, ease-in-out, infinite, 5s, -2.5s, none);
				path {
					fill: #b1ffff; } }
			&:nth-of-type(20) {
				top: 7650px;
				left: -50px;
				@include animation(floating-cloud, ease-in-out, infinite, 5s, -3s, none);
				path {
					fill: #50d9ff; } }
			&:nth-of-type(21) {
				top: 7700px;
				left: 1000px;
				@include animation(float-scale-07, ease-in-out, infinite, 5s, -.4s, none);
				path {
					fill: #b7ecff; } } } }

	.flying-bear-wrapper {
		position: fixed;
		top: 50vh;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 151px;
		height: 190px;
		z-index: 13; }

	.flying-bear-wrapper-right {
		margin: 0px 0px 0px 60vw; }

	.flying-bear {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		// position: fixed
		// top: 50vh
		// left: 0
		// right: 0
		// margin: 0 auto
		// width: 151px
		// height: 190px
		// z-index: 10


		.balloon {
			position: absolute;
			top: -150px;

			&.balloon-1 {
				left: 80px;
				transform: rotate(30deg); }
			&.balloon-2 {
				left: 90px;
				transform: rotate(10deg); }
			&.balloon-3 {
				left: 95px;
				transform: rotate(-20deg); }
			&.balloon-4 {
				left: 95px;
				transform: rotate(-20deg); }
			&.balloon-5 {
				left: 130px;
				top: -130px; }
			&.balloon-6 {
				left: 100px;
				top: -120px; }
			&.balloon-7 {
				top: -130px;
				left: 20px; }
			&.balloon-8 {
				left: 55px; }
			&.balloon-9 {
				left: 100px; }
			&.balloon-10 {
				top: -170px;
				left: 80px; } }

		.sparkle {
			position: absolute;
			display: none;
			opacity: 0; }

		.sparkles-container.sparkling {
			.sparkle {
				display: inline-block; }

			.sparkle-1 {
				top: 140px;
				left: 20px;
				animation-delay: .5s; }
			.sparkle-2 {
				top: 160px;
				left: 30px;
				animation-delay: .2s; }
			.sparkle-3 {
				top: 180px;
				left: 40px;
				animation-delay: .3s; }
			.sparkle-4 {
				top: 190px;
				left: 110px;
				animation-delay: .6s; }
			.sparkle-5 {
				top: 180px;
				left: 80px;
				animation-delay: .1s; }
			.sparkle-6 {
				top: 150px;
				left: 130px;
				animation-delay: 1s; }
			.sparkle-7 {
				top: 160px;
				left: 50px;
				animation-delay: .2s; }
			.sparkle-8 {
				top: 175px;
				left: 115px;
				animation-delay: .5s; }
			.sparkle-9 {
				top: 150px;
				left: 100px;
				animation-delay: 9s; } } }




	.dialog-container {
		position: absolute;
		width: 430px;

		p {
			display: inline-block;
			position: absolute;
			top: 110px;
			left: 50px;
			width: 320px;
			color: white;
			text-align: center;
			font-size: 26px;
			font-family: "Yuanti";
			font-weight: bold;
			line-height: 32px;
			letter-spacing: 2px; }

		.english-font {
			font-family: $primary-style;
			font-size: 22px; } }


	.zone {
		position: absolute;
		left: 0;
		width: 100%; }


	.zone-1 {
		top: 600px;

		.dialog-container {
			top: 0;
			left: 150px;
			transform: rotate(-5deg);

			p {
				transform: rotate(-5deg); } }

		.flying-house {
			position: absolute;
			top: 200px;
			left: 900px; }

		.flying-house-smoke {
			position: absolute;
			top: 35px;
			left: 910px; }

		.flying-house-plate {
			position: absolute;
			top: 380px;
			left: 870px; } }


	.zone-2 {
		top: 1560px;

		.svg {
			position: absolute; }

		.about-bubble {
			transform: rotateZ(190deg) rotateX(180deg);

			path {
				fill: $pink; } }

		.dialog-container {
			top: -120px;
			right: 150px;

			p {
				top: 90px;
				left: 70px;
				transform: rotate(15deg); } }
		svg[class*="cloud"] {
			width: 250px; }


		.clouds-left {
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 500px; }

		.cloud-1 {
			top: 0;
			left: -265px;
			transform: rotate(10deg);
			path {
				fill: #20c7ff; } }
		.cloud-2 {
			top: 220px;
			left: -160px;
			transform: rotate(10deg);
			path {
				fill: #ffee00; } }
		.cloud-3 {
			top: 450px;
			left: -265px;
			transform: rotate(10deg);
			path {
				fill: #ff9400; } }

		.cloud-4 {
			top: 0;
			left: 100px;
			transform: rotate(10deg);
			path {
				fill: #8d54c8; } }
		.cloud-5 {
			top: 0;
			left: 465px;
			transform: rotate(10deg);
			path {
				fill: #ffee00; } }
		.cloud-6 {
			top: 220px;
			left: 200px;
			path {
				fill: #ff0000; } }
		.cloud-7 {
			top: 220px;
			left: 560px;
			transform: rotate(10deg);
			path {
				fill: #aae600; } }
		.cloud-8 {
			top: 450px;
			left: 100px;
			transform: rotate(10deg);
			path {
				fill: #ff3caa; } }
		.cloud-9 {
			top: 450px;
			left: 465px;
			transform: rotate(10deg);
			path {
				fill: #0092ff; } }


		.clouds-right {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 500px; }

		.cloud-10 {
			display: none;
			top: 0;
			left: 100px;
			path {
				fill: $green; } }
		.cloud-11 {
			top: 0;
			left: 440px;
			transform: rotate(10deg);
			path {
				fill: #ff9400; } }
		.cloud-12 {
			top: 220px;
			left: 200px;
			path {
				fill: #80ffff; } }
		.cloud-13 {
			top: 220px;
			left: 520px;
			path {
				fill: #ffee00; } }
		.cloud-14 {
			top: 450px;
			left: 100px;
			transform: rotate(10deg);
			path {
				fill: #00b400; } }
		.cloud-15 {
			top: 450px;
			left: 440px;
			transform: rotate(10deg);
			path {
				fill: #20c7ff; } }

		.cloud-16 {
			top: 0;
			left: 750px;
			path {
				fill: #ff3caa; } }
		.cloud-17 {
			top: 220px;
			left: 850px;
			transform: rotate(10deg);
			path {
				fill: #ff0000; } }
		.cloud-18 {
			top: 450px;
			left: 750px;
			path {
				fill: #8d54c8; } } }


	.zone-3 {
		top: 2650px;

		.heart-1 {
			position: absolute;
			top: -20px;
			left: 220px;
			transform: scale(1.8) rotate(15deg);
			width: 260px;
			height: 220px;

			path {
				fill: $yellow; } }


		.heart-pink {
			position: absolute;
			top: 140px;
			left: 125px;
			z-index: 1;
			transform: scale(0);
			transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

			.heart-2 {
				width: 250px;
				height: 200px;
				path {
					fill: $light-pink; } }

			&.pop {
				transform: scale(1.4); } }


		.svg-container {
			position: absolute;
			top: -25px;
			left: 440px;

			.heart-3 {
				transform: rotateZ(170deg) rotateX(180deg) scale(.3);
				display: none;

				path {
					fill: $red; } } }


		.mom-pinkbear {
			position: absolute;
			top: 0;
			left: 140px; }

		.dialog-container {
			position: absolute;
			top: -270px;
			left: 650px;
			transform: scale(0);
			transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

			&.pop {
				transform: scale(1); }

			p {
				top: 70px;
				left: 60px;
				width: 340px;
				font-size: 24px;
				line-height: 28px;
				letter-spacing: 0;
				transform: rotate(15deg); }

			.about-bubble {
				transform: rotateZ(190deg) rotateX(180deg);

				path {
					fill: #ff6600; } } } }



	.zone-4 {
		top: 3740px;

		.dad-bluebear {
			position: absolute;
			top: -8px;
			left: 190px;
			z-index: 2; }
		.dad-bluebear-grassland {
			position: absolute;
			top: 400px;
			left: 50px; }

		.dad-number-2 {
			position: absolute;
			top: -140px;
			left: 770px; }

		.dialog-container {
			position: absolute;
			top: -350px;
			left: 150px;
			transform: scale(0);
			transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

			&.pop {
				transform: scale(1); }


			p {
				top: 70px;
				left: 50px;
				width: 340px;
				font-size: 25px;
				line-height: 31px;
				letter-spacing: 0;
				transform: rotate(-5deg);
				color: #19b3ff; }

			.about-bubble path {
				fill: #fff100; } } }


	.zone-5 {
		top: 4600px;

		.sea-frog {
			top: 250px;
			left: 650px; }

		img {
			position: absolute;

			&:nth-of-type(2) {
				top: 0;
				left: 410px; }
			&:nth-of-type(3) {
				top: 130px;
				left: 280px; }
			&:nth-of-type(4) {
				top: 150px;
				left: 660px; }
			&:nth-of-type(5) {
				top: 40px;
				left: 590px; }
			&:nth-of-type(6) {
				top: 90px;
				left: 490px; }
			&:nth-of-type(7) {
				top: 270px;
				left: 540px; }
			&:nth-of-type(8) {
				top: 200px;
				left: 430px; }
			&:nth-of-type(9) {
				top: 400px;
				left: 600px; }

			&:nth-of-type(n+10) {
				display: none; } }


		.dialog-container {
			position: absolute;
			top: -170px;
			left: 550px;

			p {
				top: 90px;
				left: 160px;
				width: 260px;
				font-size: 25px;
				transform: rotate(20deg); }

			.about-bubble {
				transform: rotateZ(190deg) rotateX(180deg); }

			path {
				fill: #ff0066;
				transform: scale(.75); } } }


	.zone-6 {
		top: 5220px;

		.sea-sea {
			position: absolute;
			top: 200px;
			left: -270px;
			right: 0;
			margin: 0 auto; }

		.sea-dolphin {
			position: absolute;
			top: 230px;
			left: 420px; }

		.dialog-container {
			position: absolute;
			top: -330px;
			left: 200px;
			transform: rotate(-15deg);

			p {
				top: 70px;
				left: 0;
				width: 260px;
				font-size: 25px;
				line-height: 35px;
				letter-spacing: 1px;
				transform: rotate(-10deg); }

			path {
				fill: #0057f1;
				transform: scale(.6); } }

		.sea-fish-left {
			position: absolute;
			top: 450px;
			left: 100px; }

		.sea-fish-right {
			position: absolute;
			top: 400px;
			left: 820px; } }


	.zone-7 {
		top: 6220px;

		.dialog-container.left {
			position: absolute;
			top: -30px;
			left: 400px;
			transform: rotate(-10deg);

			p {
				top: 80px;
				left: 0;
				width: 260px;
				font-size: 25px;
				line-height: 38px;
				letter-spacing: 1px;
				transform: rotate(-5deg); }

			path {
				fill: $green;
				transform: scale(.6); } }

		.dialog-container.right {
			position: absolute;
			top: -100px;
			left: 790px;

			p {
				top: 70px;
				left: 170px;
				width: 260px;
				line-height: 40px;
				font-size: 25px;
				transform: rotate(20deg); }

			.about-bubble {
				transform: rotateZ(190deg) rotateX(180deg); }

			path {
				fill: $pink;
				transform: scale(.7); } }

		.book-pegasus {
			position: absolute;
			top: 250px;
			left: 870px; }

		.book-owl {
			position: absolute;
			top: 300px;
			left: 50px; }

		.book-paris {
			position: absolute;
			top: 180px;
			left: 160px; }

		.book-us {
			position: absolute;
			top: 520px;
			left: 170px; }


		.star-1 {
			position: absolute;
			top: 0;
			left: 100px;
			transform: scale(.8) rotate(-20deg);

			path {
				fill: #ffff00; } }

		.star-2 {
			position: absolute;
			top: -250px;
			left: 330px;
			transform: rotate(75deg) scale(.5);

			path {
				fill: $purple; } }

		.star-3 {
			position: absolute;
			top: -200px;
			left: 1200px; }

		.star-4 {
			position: absolute;
			top: 610px;
			left: 640px;
			z-index: 1;
			transform: rotate(180deg);

			path {
				fill: $medium-green; } }

		.star-5 {
			position: absolute;
			top: 770px;
			left: 710px;

			path {
				fill: $blue; } }

		.star-6 {
			position: absolute;
			top: 880px;
			left: 510px;
			transform: scale(.8); }


		.star-7 {
			position: absolute;
			top: 830px;
			left: 1200px;

			path {
				fill: $orange; } }

		.star-8 {
			position: absolute;
			top: 650px;
			left: 980px;
			transform: rotate(-50deg) scale(.8);

			path {
				fill: #ffec00; } } }


	.zone-8 {
		position: absolute;
		bottom: 0;
		left: -50px;
		height: 730px;

		svg {
			position: absolute; }

		.dialog-container {
			position: absolute;
			top: -400px;
			left: 260px;
			transform: rotate(-5deg);

			p {
				width: 350px;
				line-height: 38px;
				transform: rotate(-5deg); }

			path {
				fill: #ff007b; } }

		.family-bg {
			bottom: -2px;
			left: -310px;
			z-index: 11; }

		.family-bear-1 {
			top: 300px;
			left: 550px;
			z-index: 14; }
		.family-bear-2 {
			top: 300px;
			left: 630px;
			z-index: 13; }
		.family-bear-3 {
			top: 380px;
			left: 690px;
			z-index: 13; }
		.family-bear-4 {
			top: 300px;
			left: 720px;
			z-index: 13; }
		.family-bear-5 {
			top: 310px;
			left: 800px;
			z-index: 14; }

		.heart-1 {
			top: 320px;
			left: 230px;
			z-index: 11;
			path {
				fill: #ff98ca; } }
		.heart-2 {
			top: 445px;
			left: 450px;
			z-index: 15;
			transform: scale(1.1);
			path {
				fill: #ff0000; } }
		.heart-3 {
			top: 490px;
			left: 565px;
			z-index: 16;
			transform: scale(1.1);
			path {
				fill: #ffee00; } }
		.heart-4 {
			top: 440px;
			left: 690px;
			z-index: 15;
			transform: scale(1.1);
			path {
				fill: #ff98ca; } }
		.heart-5 {
			top: 500px;
			left: 770px;
			z-index: 16;
			transform: scale(1.1);
			path {
				fill: #00dbff; } }
		.heart-6 {
			top: 490px;
			left: 835px;
			z-index: 15;
			transform: scale(1.1);
			path {
				fill: #008cff; } }
		.heart-7 {
			top: 280px;
			left: 1120px;
			z-index: 10;
			path {
				fill: #8d54c8; } }

		.last-heart {
			position: absolute;
			top: 330px;
			left: 630px;
			width: 50px;
			height: 50px;
			z-index: 12;

			.heart-8 {
				transform: rotate(15deg) scale(.3);

				path {
					fill: $red; } } } } }

@media all and (max-width: $mobile) {
	#bootstrapoverride .about-container {
		max-width: 100vw;
		.about-inner-container {
			width: 100vw;
			height: 7000px;

			.bg-cloud {
				position: absolute;

				&:nth-of-type(1) {
					top: -30px;
					left: 45vw;
					width: 90vw;
					height: 370px; }

				&:nth-of-type(2) {
					top: 80px;
					left: 60vw;
					width: 20vw;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -4s, none);
					path {
						fill: #b7ecff; } }
				&:nth-of-type(3) {
					top: -125;
					left: -2vw;
					width: 38vw; }

				&:nth-of-type(4) {
					top: 300px;
					left: -40vw;
					width: 80vw;
					display: block;
					z-index: 2;
					@include animation(float-scale-06, ease-in-out, infinite, 5s, -1.5s, none); }
				&:nth-of-type(5) {
					top: 200;
					left: 70vw;
					width: 20vw;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -2s, none);
					path {
						fill: #b7ecff; } }
				&:nth-of-type(6) {
					top: 752px;
					left: 50vw;
					width: 43vw;
					@include animation(float-scale-06, ease-in-out, infinite, 5s, -.7s, none); }
				&:nth-of-type(7) {
					top: 478px;
					left: 57vw;
					width: 49vw;
					transform: scale(1.4); }
				&:nth-of-type(8) {
					top: 2400px;
					left: 7vw;
					width: 30vw;
					@include animation(float-scale-06, ease-in-out, infinite, 5s, -4s, none); }
				&:nth-of-type(9) {
					top: 2500px;
					left: 50vw;
					width: 45vw;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, 0s, none); }
				&:nth-of-type(10) {
					display: none;
					top: 2800px;
					left: 380px;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -.5s, none); }
				&:nth-of-type(11) {
					display: none;
					top: 2920px;
					left: 200px;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -.2s, none); }
				&:nth-of-type(12) {
					display: none;
					top: 3000px;
					left: 750px;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -1s, none); }
				&:nth-of-type(13) {
					top: 3200px;
					left: 50px;
					display: none;
					@include animation(float-scale-08, ease-in-out, infinite, 5s, -2s, none); }
				&:nth-of-type(14) {
					top: 3505px;
					left: 2vw;
					width: 40vw; }

				&:nth-of-type(15) {
					top: 3940px;
					left: 7vw;
					width: 30vw; }
				&:nth-of-type(16) {
					top: 5004px;
					left: 40vw;
					width: 60vw;
					@include animation(float-scale-14, ease-in-out, infinite, 5s, 0s, none); }
				&:nth-of-type(17) {
					top: 5070px;
					left: 67vw;
					width: 24vw;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -.6s, none); }
				&:nth-of-type(18) {
					top: 6000px;
					left: 48vw;
					width: 27vw;
					@include animation(float-scale-12, ease-in-out, infinite, 5s, -2.5s, none); }

				&:nth-of-type(19) {
					top: 6100px;
					left: 65vw;
					width: 45vw;
					@include animation(float-scale-12, ease-in-out, infinite, 5s, -2.5s, none); }

				&:nth-of-type(20) {
					top: 6434px;
					left: -20px;
					width: 60vw;
					@include animation(floating-cloud, ease-in-out, infinite, 5s, -3s, none); }

				&:nth-of-type(21) {
					top: 6515px;
					left: 72vw;
					width: 40vw;
					@include animation(float-scale-07, ease-in-out, infinite, 5s, -.4s, none); } } }


		.flying-bear-wrapper {}

		.flying-bear {
			.bear-img {
				width: 100px;
				top: -40px;
				left: 26px; }


			.balloon {
				width: 60px;

				&.balloon-5 {
					left: 110px;
					top: -137px; }
				&.balloon-6 {
					left: 85px;
					top: -147px; }
				&.balloon-7 {
					width: 100px;
					left: 26px;
					top: -150px; } } }


		// 	.sparkle
		// 		position: absolute
		// 		display: none
		// 		opacity: 0

		// 	.sparkles-container.sparkling
		// 		.sparkle
		// 			display: inline-block

		// 		.sparkle-1
		// 			top: 140px
		// 			left: 20px
		// 			animation-delay: .5s
		// 		.sparkle-2
		// 			top: 160px
		// 			left: 30px
		// 			animation-delay: .2s
		// 		.sparkle-3
		// 			top: 180px
		// 			left: 40px
		// 			animation-delay: .3s
		// 		.sparkle-4
		// 			top: 190px
		// 			left: 110px
		// 			animation-delay: .6s
		// 		.sparkle-5
		// 			top: 180px
		// 			left: 80px
		// 			animation-delay: .1s
		// 		.sparkle-6
		// 			top: 150px
		// 			left: 130px
		// 			animation-delay: 1s
		// 		.sparkle-7
		// 			top: 160px
		// 			left: 50px
		// 			animation-delay: .2s
		// 		.sparkle-8
		// 			top: 175px
		// 			left: 115px
		// 			animation-delay: .5s
		// 		.sparkle-9
		// 			top: 150px
		// 			left: 100px
		// 			animation-delay: 9s




		.dialog-container {
			width: 70vw;

			p {
				top: 167px;
				left: 13px;
				width: 60vw;
				font-size: 16px;
				font-weight: bold;
				line-height: 1;
				letter-spacing: 2px; }

			.english-font {
				font-family: $primary-style;
				font-size: 16px; } }


		// .zone
		// 	position: absolute
		// 	left: 0
		// 	width: 100%


		.zone-1 {
			top: 100px;
			.dialog-container {
				left: 20px;
				transform: rotate(-5deg);
				svg {
					width: 65vw; }
				p {
					transform: rotate(-12deg); } }

			.flying-house {
				width: 45vw;
				top: 400px;
				left: 65vw; }

			.flying-house-smoke {
				width: 12vw;
				top: 310px;
				left: 67vw; }

			.flying-house-plate {
				width: 20vw;
				top: 495px;
				left: 60vw; } }


		.zone-2 {
			top: 1260px;

			.svg {
				position: absolute; }

			.about-bubble {
				transform: rotateZ(190deg) rotateX(180deg);

				path {
					fill: $pink; } }

			.dialog-container {
				top: -380px;
				left: 10vw;
				svg {
					width: 60vw; }
				p {
					top: 170px;
					left: 13px;
					font-size: 15px;
					transform: rotate(23deg); } }
			svg[class*="cloud"] {
				width: 23vw; }

			.cloud-4 {
				top: 0;
				left: -5vw; }
			.cloud-5 {
				top: 0;
				left: 24vw;
				transform: rotate(0deg); }
			.cloud-6 {
				top: 70px;
				left: 7vw; }
			.cloud-7 {
				top: 60px;
				left: 37vw; }
			.cloud-8 {
				top: 140px;
				left: -5vw; }
			.cloud-9 {
				top: 140px;
				left: 24vw; }

			.cloud-10 {
				display: none; }
			.cloud-11 {
				top: 0;
				left: 30vw; }
			.cloud-12 {
				top: 70px;
				left: 16vw; }
			.cloud-13 {
				top: 64px;
				left: 42vw; }
			.cloud-14 {
				top: 130px;
				left: 3vw; }
			.cloud-15 {
				top: 140px;
				left: 30vw; } }

		.zone-3 {
			top: 2300px;

			.heart-1 {
				top: -250px;
				left: 10vw;
				width: 46vw; }


			.heart-pink {
				position: absolute;
				top: -100px;
				left: 0vw;
				transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

				.heart-2 {
					width: 30vw;
					fill: $light-pink; }

				&.pop {
					transform: scale(1.4); } }


			.svg-container {
				position: absolute;
				top: -25px;
				left: 500px;

				.heart-3 {
					transform: rotateZ(170deg) rotateX(180deg) scale(.3); } }


			.mom-pinkbear {
				top: -175px;
				left: 0vw;
				width: 85vw; }

			.dialog-container {
				position: absolute;
				top: -700px;
				left: 18vw;
				transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

				&.pop {
					transform: scale(1); }
				svg {
					width: 75vw; }

				p {
					top: 150px;
					left: -11px;
					width: 82vw;
					font-size: 16px;
					line-height: 1;
					letter-spacing: 0;
					transform: rotate(8deg); }

				.about-bubble {
					transform: rotateZ(175deg) rotateX(180deg); } } }


		.zone-4 {
			top: 3000px;

			.dad-bluebear {
				top: 182px;
				left: -3vw;
				width: 70vw; }
			.dad-bluebear-grassland {
				top: 370px;
				left: -7vw;
				width: 110vw; }


			.dad-number-2 {
				position: absolute;
				width: 20vw;
				top: 130px;
				left: 64vw; }

			.dialog-container {
				position: absolute;
				top: -350px;
				left: 5vw;
				transform: scale(0);
				transition: transform .8s cubic-bezier(.42,.05,.56,1.58);

				&.pop {
					transform: scale(1); }

				svg {
					width: 80vw; }

				p {
					top: 130px;
					left: -6px;
					width: 85vw;
					font-size: 16px;
					line-height: 1; }

				.about-bubble path {
					fill: #fff100; } } }


		.zone-5 {
			top: 3650px;

			.sea-frog {
				top: 430px;
				left: 46vw;
				width: 80vw; }
			.sea-rain {
				width: 5vw; }

			img {
				position: absolute;

				&:nth-of-type(2) {
					top: 0;
					left: 26vw; }
				&:nth-of-type(3) {
					top: 56px;
					left: 13vw; }
				&:nth-of-type(4) {
					top: 20px;
					left: 39vw; }
				&:nth-of-type(5) {
					top: 40px;
					left: 32vw; }
				&:nth-of-type(6) {
					top: 90px;
					left: 27vw; }
				&:nth-of-type(7) {
					top: 139px;
					left: 35vw; }
				&:nth-of-type(8) {
					top: 190px;
					left: 45vw; }
				&:nth-of-type(9) {
					top: 230px;
					left: 38vw; }
				//show-in-mobile
				&:nth-of-type(n+10) {
					display: block; }
				&:nth-of-type(10) {
					top: 250px;
					left: 27vw; }
				&:nth-of-type(11) {
					top: 260px;
					left: 47vw; }
				&:nth-of-type(12) {
					top: 313px;
					left: 41vw; }
				&:nth-of-type(13) {
					top: 300px;
					left: 85vw; }
				&:nth-of-type(14) {
					top: 320px;
					left: 76vw; }
				&:nth-of-type(15) {
					top: 367px;
					left: 80vw; }
				&:nth-of-type(16) {
					top: 380px;
					left: 70vw; } }


			.dialog-container {
				position: absolute;
				top: -190px;
				left: 25vw;
				svg {
					width: 65vw;
					transform: rotateY(180deg) rotateZ(2deg); }


				p {
					top: 180px;
					left: 19vw;
					width: 48vw;
					font-size: 14px;
					transform: rotate(22deg); }

				.about-bubble {
					transform: rotateZ(190deg) rotateX(180deg); }

				path {
					transform: scale(.75); } } }


		.zone-6 {
			top: 4320px;

			.sea-sea {
				top: 100px;
				left: -73vw;
				width: 240vw; }

			.sea-dolphin {
				top: 289px;
				left: 58vw;
				width: 40vw; }

			.dialog-container {
				position: absolute;
				top: -250px;
				left: 3vw;
				transform: rotate(0deg);
				svg {
					width: 70vw; }

				p {
					top: 142px;
					left: 2vw;
					width: 39vw;
					font-size: 16px;
					line-height: 1.2;
					letter-spacing: 1px;
					transform: rotate(-10deg); } }

			.sea-fish-left {
				top: 205px;
				left: 0vw;
				width: 50vw; }

			.sea-fish-right {
				top: 205px;
				left: 50vw;
				width: 50vw; } }


		.zone-7 {
			top: 5320px;

			.dialog-container.left {
				position: absolute;
				top: -40px;
				left: 30vw;
				transform: rotate(0deg);
				svg {
					width: 85vw; }
				p {
					top: 135px;
					left: 0;
					width: 50vw;
					font-size: 20px;
					line-height: 1.2;
					letter-spacing: 1px;
					transform: rotate(-5deg); }

				path {
					fill: $green;
					transform: scale(.6); } }

			.dialog-container.right {
				position: absolute;
				top: -662px;
				left: 16vw;

				svg {
					width: 76vw; }
				p {
					top: 157px;
					left: 29vw;
					width: 50vw;
					font-size: 16px;
					line-height: 1.2;
					letter-spacing: 1px; }

				.about-bubble {
					transform: rotateZ(190deg) rotateX(180deg); }

				path {
					fill: $pink;
					transform: scale(.7); } }

			.book-pegasus {
				position: absolute;
				top: -300px;
				left: 30vw;
				width: 60vw; }

			.book-owl {
				position: absolute;
				top: 230px;
				left: -30vw;
				width: 130vw; }

			.book-paris {
				position: absolute;
				top: 150px;
				left: -10vw;
				width: 52vw; }

			.book-us {
				position: absolute;
				top: 376px;
				left: -7vw;
				width: 68vw; }


			.star-1 {
				top: -120px;
				left: 0vw;
				width: 32vw;
				transform: scale(.8) rotate(-15deg); }

			.star-2 {
				top: -550px;
				left: 4vw;
				width: 30vw;
				transform: rotate(150deg) scale(.5); }


			.star-3 {
				top: -440px;
				left: 85vw;
				width: 10vw; }

			.star-4 {
				top: 427px;
				left: 65vw;
				width: 16vw; }

			.star-5 {
				top: 540px;
				left: 75vw;
				width: 7vw; }

			.star-6 {
				top: 700px;
				left: 10vw;
				width: 28vw; }


			.star-7 {
				position: absolute;
				top: 720px;
				left: 86vw;
				width: 6vw; }

			.star-8 {
				top: 600px;
				left: 56vw;
				width: 30vw; } }


		.zone-8 {
			position: absolute;
			bottom: 0;
			left: -50px;
			height: 730px;

			svg {
				position: absolute; }

			.dialog-container {
				top: -100px;
				left: 20vw;
				svg {
					width: 70vw; }

				p {
					width: 60vw;
					top: 159px;
					line-height: 1.2;
					transform: rotate(-14deg); } }

			.family-bg {
				bottom: 0;
				left: -65vw;
				width: 250vw;
				z-index: 11; }

			img[class*="family-bear"] {
				width: 18vw; }
			.family-bear-1 {
				top: auto;
				bottom: 70px;
				left: 37vw; }
			.family-bear-2 {
				top: auto;
				bottom: 60px;
				left: 43vw; }
			.family-bear-3 {
				top: auto;
				bottom: 100px;
				left: 54vw;
				width: 13vw !important; }
			.family-bear-4 {
				top: auto;
				bottom: 70px;
				left: 61vw; }
			.family-bear-5 {
				top: auto;
				bottom: 70px;
				left: 69vw; }

			.heart-1 {
				top: auto;
				left: 2vw;
				bottom: 60px;
				width: 40vw; }
			.heart-2 {
				top: auto;
				bottom: -25px;
				left: 32vw;
				width: 20vw; }
			.heart-3 {
				top: auto;
				bottom: -20px;
				left: 46vw;
				width: 16vw;
				transform: scale(1.1) rotate(-17deg); }
			.heart-4 {
				top: auto;
				bottom: -10px;
				left: 54vw;
				width: 21vw; }
			.heart-5 {
				top: auto;
				bottom: -20px;
				left: 62vw;
				width: 20vw;
				z-index: 16; }
			.heart-6 {
				top: auto;
				bottom: -16px;
				left: 70vw;
				width: 26vw; }
			.heart-7 {
				top: auto;
				bottom: 70px;
				left: 90vw;
				width: 27vw;
				z-index: -1; }

			.last-heart {
				position: absolute;
				top: 330px;
				left: 630px;
				width: 50px;
				height: 50px;
				z-index: 12;

				.heart-8 {
					transform: rotate(15deg) scale(.3);

					path {
						fill: $red; } } } } } }
@media all and (max-width: 340px) {
	.zone-4 {
		.dad-bluebear-grassland {
			top: 340px !important; } } }











