

#bootstrapoverride .newclothes-container {
	position: relative;
	width: 1100px;
	margin: 0 auto;
	padding-top: 65px;

	.catalogue-outer-container {
		width: 100%;
		padding-top: 65px;
		margin-bottom: 60px;
		background-color: $sunflower-yellow; }

	.catalogue-container {
		width: 90%;
		height: 790px;
		margin: 0 auto 65px;
		box-shadow: $catalogue-shadow; }

	.catalogue-description {
		width: 100%;
		background-color: #fff9cc;
		padding: 5px 45px;

		h2 {
			font-size: 24px;
			line-height: 34px;
			color: #fa5f0f; } }


	.catalogue-collection {
		width: 1000px;
		margin: 0 auto 100px;

		.col-sm-12 {
			margin-bottom: 15px; }

		.category-name {
			display: inline-block;
			padding: 5px 20px;
			border-radius: 25px;
			font-weight: bold;
			color: $white;
			background-color: $barbie-pink; } }


	.img-wrapper {
		width: 225px;
		height: 280px; } }
@media all and (max-width: $mobile) {
	#bootstrapoverride .newclothes-container {
		width: 100vw;
		padding-top: 100px;
		.catalogue-outer-container {
			display: none; }

		.catalogue-collection {
			width: 220px;

			.col-sm-12 {
				margin-bottom: 15px; }

			.category-name {
				padding: 2px 5px;
				font-size: 13px; } }
		.img-wrapper {
			width: 156px;
			height: 194px;
			margin-bottom: 5px; } } }
