//payment css

#bootstrapoverride {
	.payment-container {
		max-width: 960px;
		margin: 50px auto 100px; }

	.payment-steps {
		width: 640px;
		margin: 0 auto;
		padding-left: 23px; }

	.step {
		position: relative;
		float: left;
		width: 175px;
		height: 45px;
		color: $white;
		font-size: 16px;
		background-color: grey;
		padding: 12px 0;
		border-radius: 0 30px 30px 0;
		text-align: center;

		&.current {
			background-color: $vermillion;

			&:after {
				color: $vermillion;
				border: 3px solid $vermillion; } }

		&:nth-of-type(2) {
			margin: 0 45px; }

		&:after {
			content: "";
			position: absolute;
			left: -22px;
			top: 0;
			width: 45px;
			height: 45px;
			border-radius: 50%;
			background-color: white;
			color: grey;
			border: 3px solid grey;
			font-size: 22px;
			font-weight: bold;
			padding: 8px 0; }

		&:nth-of-type(1):after {
			content: "1"; }

		&:nth-of-type(2):after {
			content: "2"; }

		&:nth-of-type(3):after {
			content: "3"; } }


	.payment-delivery-options {
		position: relative;
		margin-bottom: 100px;

		&.first-step:after {
			display: none;
			content: url("../img/checkout/img-checkout-box.svg");
			position: absolute;
			top: 180px;
			right: 100px; }

		form {
			margin-top: 70px; }

		.radio-container {
			position: relative;
			width: 700px;
			margin-left: 100px;
			margin-bottom: 30px;
			font-size: 16px;

			input[type="radio"] {
				position: absolute;
				top: 1px;
				left: -25px; }

			span.bold {
				display: inline-block;
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 15px; } } }


	.checkout-header {
		position: relative;
		color: $vermillion;
		margin: 50px 0 40px 30px;

		&:after {
			content: "";
			position: absolute;
			bottom: -15px;
			left: -30px;
			width: calc(100% + 30px);
			height: 0;
			border-bottom: 2px solid $vermillion; } }
	.mobile-cart-table-header {
		display: none; }

	.mobile-cart-table {
		display: none; }

	.cart-detail-container-editable {
		position: relative;
		padding-bottom: 5px;
		margin: 50px 0;
		border-bottom: 2px solid $vermillion;

		.product-info {
			border: 1px solid $vermillion;
			border-radius: 25px;
			margin-bottom: 15px;
			padding-top: 6px;

			.item-code {
				font-size: 12px; }

			.item-note {
				font-size: 10px;
				color: $vermillion;
				margin-top: -6px; }

			.item-color {
				margin-top: 8px; }

			.add-next-time {
				font-size: 17px;
				font-weight: bold;
				color: $vermillion;
				cursor: pointer; } }

		.row {
			position: relative;
			display: flex;
			align-items: center;
			text-align: center;
			margin-left: 0;
			margin-right: 0; }

		.table-header {
			color: $vermillion;
			font-size: 16px;
			font-weight: bold;
			padding: 10px 0; }

		div[class*="col"] {
			padding: 0; }

		&:after {
			content: url("../img/checkout/img-checkout-list.svg");
			position: absolute;
			top: -50px;
			left: 200px; } }


	.cart-detail-container {
		position: relative;
		padding-bottom: 5px;
		border: 3px solid $vermillion;
		border-radius: 25px;
		margin: 50px 0;

		.row {
			margin-left: 0;
			margin-right: 0; }

		.table-header {
			color: $vermillion;
			font-size: 16px;
			font-weight: bold;
			padding: 10px 0; }

		.cart-detail .row {
			position: relative;
			display: flex;
			align-items: center;
			text-align: center;

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 98%;
				height: 2px;
				background-color: $vermillion; } }


		div[class*="col"] {
			padding: 0; }

		.product-info {
			height: 130px;
			padding-top: 6px;

			.item-code {
				font-size: 12px; }

			.item-note {
				font-size: 10px;
				color: $vermillion;
				margin-top: -6px; }

			.item-color {
				margin-top: 8px; }

			.add-next-time {
				font-size: 17px;
				font-weight: bold;
				color: $vermillion;
				cursor: pointer; } }


		.order-summary-container {
			margin: 20px 0; } }




	.order-summary-container {
		margin: 30px 0 100px;
		color: $vermillion;

		.row {
			margin-bottom: 10px; }

		.price-plus-delivery {
			font-size: 22px; }

		.delivery-fee-note {
			width: 100%;
			background-color: $bright-sky-blue;
			font-size: 14px;
			color: $white;
			text-align: center;
			border-radius: 10px;
			margin-top: 10px; }


		.continue-shopping-btn {
			float: left;
			width: 130px;
			height: 35px;
			color: $vermillion;
			font-size: 16px;
			padding: 5px 0;
			border: 2px solid $vermillion;
			border-radius: 30px;
			text-align: center; } }


	.checkout-summary-header {
		width: 460px;
		font-size: 22px;
		font-weight: bold;
		color: $vermillion;
		margin: 50px auto; }


	.checkout-summary-container {
		position: relative;
		padding-bottom: 5px;
		border: 3px solid $vermillion;
		border-radius: 25px;
		margin: 50px 0 20px;

		.header {
			position: relative;
			height: 60px;
			padding: 17px 30px;
			color: $vermillion;

			div {
				font-size: 22px;
				font-weight: bold;
				padding: 0; }

			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 98%;
				height: 2px;
				background-color: $vermillion; }

			&.overscore:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				width: 98%;
				height: 2px;
				background-color: $vermillion; } }

		.row {
			margin: 0 0 30px 0;
			padding-left: 30px;

			.col-xs-3,
			.col-xs-9 {
				padding: 5px 0;
				font-size: 16px; }
			.col-xs-3 {
				font-weight: bold; } } }


	.continue-buttons {
		margin-top: 40px; }


	.customer-info-container {
		border-bottom: 2px solid $vermillion;

		.info-section {
			width: 650px;
			margin: 50px auto;

			a {
				color: white; } }

		.store-information {
			border: 1px solid #808080;
			border-radius: 20px;
			padding: 10px 15px;
			font-size: 12px;
			color: #6d6d6d; } }


	.indent-label {
		padding-left: 26px; }

	.margin-right {
		margin-right: 30px; }


	.step-navigate {
		position: relative;
		height: 35px;
		color: $white;
		font-size: 16px;
		font-weight: bold;
		background-color: $vermillion;
		border-radius: 30px;
		text-align: center;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: white;
			color: $vermillion;
			border: 2px solid $vermillion;
			font-size: 20px;
			font-weight: bold;
			padding: 5px 0; } }


	.step-next {
		float: right;
		width: 150px;
		padding: 8px 20px 8px 0;
		&:after {
			right: 0; } }


	.step-prev {
		float: left;
		width: 170px;
		padding: 8px 0 8px 20px;
		margin-left: 40px;
		&:after {
			left: 0; } }

	.step-1:after {
		content: "1"; }
	.step-2:after {
		content: "2"; }
	.step-3:after {
		content: "3"; }


	.seven-eleven-btn,
	.family-mart-btn,
	.hi-life-btn {
		display: none;
		width: 170px;
		height: 30px;
		background-color: $vermillion;
		color: $white;
		padding-top: 3px;
		border-radius: 20px;
		text-align: center;

		&.selected {
			display: block; } } }

@media all and (max-width: $mobile) {
	#bootstrapoverride {
		.checkout-summary-header {
			width: 80vw;
			font-size: 18px;
			text-align: center; } }
	.cart-detail-container {
		padding: 10px;
		div[class*="col"] {
			width: 20%; }
		img {
			width: 100%; } }


	.checkout-summary-container {

		.col-xs-3 {
			width: 45%;
			font-size: 11px !important; }
		.col-xs-9 {
			width: 50%;
			margin-left: 5px;
			font-size: 11px !important; } }

	#bootstrapoverride {
		.payment-container {
			max-width: 90vw;
			margin: 100px auto 100px; }

		.payment-steps {
			width: 240px;
			margin: 3px auto;
			padding-left: 10px; }

		.step {
			position: relative;
			float: initial;
			width: 240px;
			height: 30px;
			font-size: 14px;
			padding: 3px 0px;

			&:nth-of-type(2) {
				margin: 3px 0px; }

			&:after {
				left: -17px;
				width: 30px;
				height: 30px;
				padding: 2px 0px; } }

		.payment-delivery-options {
			form {
				margin-top: 0px; }

			.radio-container {
				position: relative;
				width: 80vw;
				margin-left: 30px;
				margin-bottom: 10px;
				font-size: 11px;

				input[type="radio"] {
					position: absolute;
					top: 1px;
					left: -25px; }

				span.bold {
					display: inline-block;
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 0px; } } }
		.checkout-header {
			font-size: 12px;
			margin: 30px auto 15px;
			width: 85vw;

			&:after {
				width: 90vw;
				bottom: 0px;
				left: -2vw; } }
		.dec-underline {
			&:after {
				content: "";
				width: 76%;
				height: 1px;
				position: absolute;
				top: 21px;
				left: 12%;
				background-color: #f0190f; } }
		.desktop-cart-table-header {
			display: none; }
		.mobile-cart-table-header {
			display: block; }
		.desktop-cart-table {
			display: none; }
		.mobile-cart-table {
			display: block; }
		.cart-detail {
			width: 90vw;
			margin: 0px auto;
			&.favorite {
				width: 100%; } }
		.order-data-cart-detail {
			width: 80vw; }
		.cart-detail-container-editable {
			width: 90vw;
			margin: 0px auto;
			.product-info {
				border-radius: 8px; }
			.item-photo {
				img {
					width: 90%; } }
			div[class*="col"] {
				width: 20%;
				font-size: 12px !important;
				p {
					font-size: 11px !important; } }
			.item-discount {
				font-size: 12px !important; } } }

	.cart-detail-container {
		border-radius: 8px;

		.table-header {
			padding: 10px 0; }

		div[class*="col"] {
			width: 20%;
			font-size: 12px !important;
			position: relative;
			p {
				font-size: 11px !important; } }
		.col-sm-4 {
			width: 100% !important; }
		.product-info {
			height: 130px;
			padding-top: 6px; }
		.checkout-remove {
			img {
				width: 50%; } } }

	.order-summary-container {
		.summary-num {
			width: 40%;
			margin-left: 60% !important;
			div {
				padding: 0px;
				width: 50% !important;
				float: left; } }
		.delivery-fee {
			width: 100%; } }
	.customer-info-container {
		padding-bottom: 30px;
		.info-section {
			width: 90vw !important;
			a {
				color: white; }
			label {
				font-size: 11px !important;
				padding: 0px !important; }

			input {
				height: 23px; } }
		input+label {
			position: absolute;
			top: 5px;
			left: 40px; }
		div[class*="col"] {
			padding-right: 0px;
			width: 100%; }

		.store-information {
			border: 1px solid #808080;
			border-radius: 20px;
			padding: 10px 15px;
			font-size: 12px;
			color: #6d6d6d; }
		.row {
			width: 95vw !important; }
		.row-title {
			min-width: 25vw; }

		.blue-remark {
			span.small {
				width: 70vw !important;
				top: 30px; } } }
	.large-margin {
		margin-bottom: 40px !important; }
	.step-prev {
		margin-left: 0px !important; }
	.continue-buttons {
		button[type='submit'] {
			float: right; } }
	.favortie-container {
		div[class*="col"] {}

		.item-photo {
			img {
				width: 50px;
				height: 50px;
				margin: 5px; } } }
	.continue-shopping-btn {
		font-size: 12px !important; }
	.step-navigate {
		font-size: 12px !important; }
	.step-next {
		width: 130px !important; }
	.step-prev {
		width: 120px !important; }
	.button-note-right {
		float: right;
		text-align: right; }
	#bootstrapoverride {
		.payment-container {
			.cart-detail {
				width: 84vw; }
			div[class*="col"] {
				width: 100%; }
			.checkout-summary-container {
				div[class*="col"] {
					width: 49%; }

				.header {
					width: 80vw;
					margin: 10px auto;

					&:after {
						width: 80vw; }

					&.overscore:before {
						width: 80vw; } } } }
		.addr-col {
			min-width: 72px;
			padding: 3px; } } }













