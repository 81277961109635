
#bootstrapoverride {
	.clearfix:after {
		content: "";
		clear: both;
		display: table; }


	.img-center {
		width: 100%;

		img {
			width: 100%;
			object-fit: cover; } }

	.red-button {
		display: block;
		width: 143px;
		height: 32px;
		background-color: $vermillion;
		border-radius: 20px;
		text-align: center;
		font-size: 18px;
		color: white !important;
		padding: 5px 0;
		margin: 0 auto;
		border: none; }


	.white-button {
		@extend .red-button;
		background-color: white;
		color: $vermillion !important;
		border: 2px solid $vermillion;
		padding: 3px 0; }

	.favorite-button {
		float: right;
		margin: 50px 0px 20px 10px; }


	.red-button-small {
		display: inline-block;
		height: 20px;
		background-color: $vermillion;
		border-radius: 20px;
		text-align: center;
		font-size: 12px;
		color: white !important;
		padding: 0 8px; }


	.vertical-align-middle {
		display: flex;
		align-items: center; }


	.align-right {
		text-align: right; }



	// tabs
	.red-tab-container {
		position: relative;
		width: 900px;
		margin: 40px auto 140px;
		padding-top: 45px;

		.inner-container {
			position: relative;
			width: 900px;
			background-color: white;
			border: 5px solid $vermillion;
			border-radius: 20px;
			z-index: 2; }

		.inner-container.conditions p {
			font-size: 15px;
			line-height: 30px;

			.text-indent {
				text-indent: 25px; } }

		.position-1 {
			left: 0; }
		.position-2 {
			left: 190px; }
		.position-3 {
			left: 380px; }
		.position-4 {
			left: 570px; }

		.mobile-tab-nav {
			display: none; }


		.tab-inactive {
			position: absolute;
			top: 5px;
			width: 220px;
			height: 60px;
			border: 5px solid $vermillion;
			border-radius: 20px 20px 0 0;
			background-color: white;
			padding: 7px;
			text-align: center;
			transition: all .1s ease-in-out;

			&:hover {
				cursor: pointer; }

			p {
				color: $vermillion;
				font-size: 22px;
				font-weight: bold; } }


		.tab {
			position: absolute;
			top: -10px;
			width: 220px;
			height: 70px;
			border: 0 solid transparent;
			border-radius: 20px 20px 0 0;
			background-color: $vermillion;
			padding: 17px 5px;
			text-align: center;
			z-index: 1;

			&:hover {
				cursor: pointer; }

			&:after {
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				top: 40px;
				left: 215px;
				border-style: solid;
				border-color: $vermillion;
				border-width: 0 0 5px 5px;
				border-radius: 0 0 0 20px;
				box-shadow: -5px 5px 0 $vermillion; }

			&.tab-middle:before {
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				top: 40px;
				left: -15px;
				border-style: solid;
				border-color: $vermillion;
				border-width: 0 5px 5px 0;
				border-radius: 0 0 20px 0;
				box-shadow: 5px 5px 0 $vermillion; }

			&.tab-last {
				&:before {
					content: "";
					position: absolute;
					width: 20px;
					height: 20px;
					top: 40px;
					left: -15px;
					border-style: solid;
					border-color: $vermillion;
					border-width: 0 5px 5px 0;
					border-radius: 0 0 20px 0;
					box-shadow: 5px 5px 0 $vermillion; }
				&:after {
					display: none; } }


			p {
				color: white;
				font-size: 22px;
				font-weight: bold; } } }


	.hidden {
		display: none; }



	// form styling
	label {
		font-size: 16px;
		color: #595757; }

	form {
		.row {
			margin-bottom: 22px;

			&.margin-bottom {
				margin-bottom: 35px; } }


		.indent {
			margin-left: 20px; } }


	.row-title {
		color: #595757;
		font-size: 16px;
		font-weight: bold; }


	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	textarea,
	select {
		width: 100%;
		appearance: none;
		border: 1px solid #808080;
		border-radius: 20px;
		padding: 5px 15px;
		background: transparent; }

	textarea {
		width: 100%;
		height: 135px;
		padding: 10px 15px;
		font-family: $primary-style; }


	.indent-for-radio {
		padding-left: 10px; }

	.indent-for-radio-row {
		padding-left: 40px; }


	.grey-remark {
		position: relative;
		display: inline;

		span.small {
			position: absolute;
			display: inline-block;
			width: 200px;
			bottom: -18px;
			right: 0;
			font-size: 10px;
			color: #6d6d6d;
			text-align: right;
			letter-spacing: 1px; } }

	.blue-remark {
		position: relative;
		margin-bottom: 10px;

		span.small {
			position: absolute;
			display: inline-block;
			width: 600px;
			bottom: -24px;
			right: 0;
			font-size: 11px;
			font-weight: bold;
			color: $bright-sky-blue;
			text-align: right; } }


	select[name="expireMM"] {
		padding-left: 25px; }
	select[name="expireYY"] {
		padding-left: 15px; }


	.dropdown-wrapper {
		position: relative;
		width: 100%;
		height: 35px;

		//small arrow
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: 15px;
			top: 20px;
			margin-top: -6px;
			border-width: 7px 7px 0 7px;
			border-style: solid;
			border-color: #777777 transparent;
			pointer-events: none; }

		.dropdown {
			width: 100%;
			height: 35px;
			border: solid 1px #808080;
			border-radius: 20px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			outline: none;
			font-size: 14px;
			color: #777777; } }


	// hack for centering imgs
	.img-wrapper {
		position: relative;
		overflow: hidden;

		img {
			position: absolute;
			height: 100%;
			width: auto;
			top: -100%;
			bottom: -100%;
			left: -100%;
			right: -100%;
			margin: auto; } }


	.arrow-up {
		position: relative;

		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			bottom: 14px;
			right: 4px;
			border-width: 0 6px 6px 6px;
			border-style: solid;
			border-color: $white transparent; } }


	.pagination {
		float: right;
		margin: 0 0 20px 0;

		li {
			display: inline-block;
			width: 33px;
			height: 20px;
			padding: 1px 0;
			border: 1px solid $vermillion;
			border-radius: 10px;
			font-size: 11px;
			line-height: 15px;
			text-align: center;
			color: $vermillion;

			&:hover {
				cursor: pointer; }

			&.active {
				background-color: $vermillion;
				color: white; } } } }
.underline {
	text-decoration: underline; }

.slick-dots {
	text-align: right; }

@media all and (max-width: $mobile) {
	.mobile-hidden {
		display: none; }
	#rc-imageselect, .g-recaptcha {
		transform: scale(0.77);
		-webkit-transform: scale(0.77);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0; }
	#bootstrapoverride {
		.favorites-container {
			margin: 100px auto 140px; }
		.red-tab-container {
			width: 90vw;
			.inner-container {
				width: 90vw;
				&.conditions {
					p {
						font-size: 11px; } } }
			.tab {
				top: 19px;
				width: 110px;
				border-radius: 8px 8px 0px 0px;
				padding: 4px;

				&:after {
					top: 16px;
					left: 105px; }

				p {
					font-size: 11px; } }

			.mobile-tab-nav {
				display: initial;
				.nav-title {
					width: 90vw;
					height: 55px;
					background-color: red;
					position: relative;
					color: white;
					padding: 13px;
					margin-bottom: -13px;
					border-radius: 8px 8px 0px 0px;
					margin-top: 90px;
					p {
						color: white; }
					&:after {
						position: absolute;
						content: "";
						top: 11px;
						right: 20px;
						border: solid white;
						border-width: 0 4px 4px 0;
						display: inline-block;
						padding: 6px;
						transform: rotate(45deg);
						-webkit-transform: rotate(45deg); }
					&:before {
						content: url("../img/container-righttop-yellowbear.svg");
						position: absolute;
						top: -88px;
						right: 20px; } }
				.active-title {
					margin-bottom: 0px; }
				.mobile-tab {
					width: 90vw;
					background-color: white;
					padding: 6px 13px;
					border: solid 1px #f0190f;
					height: 35px;
					position: relative;
					left: 0px !important;
					&:after {
						content: "";
						position: absolute;
						width: 15px;
						height: 15px;
						top: 11px;
						right: 20px;
						border-radius: 50%;
						border: solid 1px #f0190f; }
					&:first-child {
						border-radius: 8px 8px 0px 0px; }
					&:last-child {
						border-radius: 0px 0px 8px 8px; } }
				.active-mobile-tab {
					&:before {
						content: "";
						position: absolute;
						width: 9px;
						height: 9px;
						top: 14px;
						right: 23px;
						border-radius: 50%;
						background-color: #f0190f; } } }

			.desktop-tab-nav {
				display: none; } }

		.row-title {
			font-size: 11px; }
		.col-sm-3 {
			min-width: 90px; }
		.grey-remark {
			span.small {
				left: 0;
				text-align: left; } }
		.red-button, .white-button {
			width: 100px;
			font-size: 11px; }

		input[type="text"],
		input[type="tel"],
		input[type="password"],
		input[type="email"],
		textarea,
		select {
			padding: 0 15px; }
		input {
			padding-left: 8px !important;
			padding-right: 8px !important; }
		.m20 {
			width: 20vw !important; }
		.m25 {
			width: 25vw !important; }
		.m30 {
			width: 30vw !important; }
		.m35 {
			width: 35vw !important; }
		.m40 {
			width: 40vw !important; }
		.m45 {
			width: 45vw !important; }
		.m50 {
			width: 50vw !important; }
		.m55 {
			width: 55vw !important; }
		.m60 {
			width: 60vw !important; }
		.m65 {
			width: 65vw !important; }
		.m70 {
			width: 70vw !important; }
		.m75 {
			width: 75vw !important; }
		.m80 {
			width: 80vw !important; }
		.m85 {
			width: 85vw !important; }
		.m90 {
			width: 90vw !important; }
		.m95 {
			width: 95vw !important; }
		.m100 {
			width: 100vw !important; }
		.m105 {
			width: 105vw !important; }
		.m110 {
			width: 110vw !important; }
		.m115 {
			width: 115vw !important; }
		.m120 {
			width: 120vw !important; }
		.m125 {
			width: 125vw !important; }

		.dropdown-wrapper {
			height: 25px;
			margin: 5px 0;

			select {
				padding: 0px 7px;
				font-size: 11px; }

			//small arrow
			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				right: 6px;
				top: 16px;
				margin-top: -6px;
				border-width: 7px 7px 0 7px;
				border-style: solid;
				border-color: #777777 transparent;
				pointer-events: none; }

			.dropdown {
				width: 100%;
				height: auto;
				border: solid 1px #808080;
				border-radius: 20px;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				cursor: pointer;
				outline: none;
				font-size: 14px;
				color: #777777; } } } }









