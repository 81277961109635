//decroation circles
.decoration-bg-story {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 1400px;
	margin: 0 auto;
	z-index: -9999;

	.bg-circle:nth-child(1) {
		top: -130px;
		left: 40px; }
	.bg-circle:nth-child(2) {
		top: -110px;
		left: 900px; }
	.bg-circle:nth-child(3) {
		top: 245px;
		left: 85px; }
	.bg-circle:nth-child(4) {
		top: 215px;
		left: 1150px; }
	.bg-circle:nth-child(5) {
		top: 335px;
		left: 1025px; }
	.bg-circle:nth-child(6) {
		top: 390px;
		left: 490px; }
	.bg-circle:nth-child(7) {
		top: 520px;
		left: 220px; }
	.bg-circle:nth-child(8) {
		top: 495px;
		left: 1240px; }
	.bg-circle:nth-child(9) {
		top: 465px;
		left: -400px; }
	.bg-circle:nth-child(10) {
		top: 1160px;
		left: 1290px; }
	.bg-circle:nth-child(11) {
		top: 1490px;
		left: 485px; }
	.bg-circle:nth-child(n+11) {
		display: none; } }


#bootstrapoverride .story-container {
	position: relative;
	width: 900px;
	margin: 40px auto 80px;
	z-index: 10;

	.introduction {
		position: relative;

		.text-align-bottom {
			position: absolute;
			bottom: 0;
			right: 0;
			padding-bottom: 25px;

			h2 {
				font-size: 26px;
				line-height: 39px; } } }


	.books {
		margin: 80px 0 120px;

		.col-sm-3 {
			margin-bottom: 45px; }

		.col-sm-6 {
			text-align: right; } }


	.img-wrapper {
		width: 175px;
		height: 190px; } }


#bootstrapoverride .story-book-container {
	position: relative;
	width: 1100px;
	margin: 40px auto 80px;
	z-index: 10;


	.book-bg {
		width: 100%;
		padding: 80px 50px;
		background-color: #fff1dc;

		.book-container {
			width: 100%;
			height: 650px;
			background-color: white;
			box-shadow: $box-shadow2; } } }

@media all and (max-width: $mobile) {
	.decoration-bg-story {
		.bg-circle:nth-child(1) {
			top: 0px;
			left: 5vw;
			background-color: #ff4191 !important; }
		.bg-circle:nth-child(2) {
			top: 110px;
			left: 90vw;
			background-color: #ffe100 !important; }
		.bg-circle:nth-child(3) {
			top: 200px;
			left: 66vw;
			width: 30vw !important;
			height: 30vw !important;
			background-color: #64d200 !important;
			display: block !important; }
		.bg-circle:nth-child(4) {
			top: 270px;
			left: 10vw;
			width: 20vw !important;
			height: 20vw !important;
			background-color: #00b9ff !important;
			display: block !important; }
		.bg-circle:nth-child(5) {
			top: 200px;
			left: 2vw;
			width: 8vw !important;
			height: 8vw !important;
			display: block !important; }
		.bg-circle:nth-child(6) {
			top: 400px;
			left: 93vw;
			background-color: #64d200 !important; }
		.bg-circle:nth-child(7) {
			top: 520px;
			left: -3vw;
			width: 15vw !important;
			height: 15vw !important;
			background-color: #ff7bac !important; }
		.bg-circle:nth-child(8) {
			top: 660px;
			left: 90vw;
			background-color: #ffe100 !important; }
		.bg-circle:nth-child(9) {
			top: 680px;
			left: -30vw;
			width: 40vw !important;
			height: 40vw !important; }
		.bg-circle:nth-child(10) {
			top: 880px;
			left: 85vw;
			height: 5vw !important;
			width: 5vw !important;
			background-color: #ff7bac !important; }
		.bg-circle:nth-child(11) {
			top: 920px;
			left: 90vw;
			background-color: #3fa9f6 !important; }
		.bg-circle:nth-child(n+11) {
			display: block; }
		.bg-circle:nth-child(12) {
			top: 1050px;
			left: -2vw; }
		.bg-circle:nth-child(13) {
			top: 1200px;
			left: -20vw; }
		.bg-circle:nth-child(14) {
			top: 1260px;
			left: 80vw; }
		.bg-circle:nth-child(15) {
			top: 1360px;
			left: 10vw; }
		.bg-circle:nth-child(16) {
			top: 1500px;
			left: 80vw; }
		.bg-circle:nth-child(17) {
			top: 1750px;
			left: 85vw; }
		.bg-circle:nth-child(18) {
			top: 1800px;
			left: 15vw; }
		.bg-circle:nth-child(19) {
			top: 1930px;
			left: -5vw; }
		.bg-circle:nth-child(20) {
			top: 2040px;
			left: 15vw; }
		.bg-circle:nth-child(21) {
			top: 2100px;
			left: 80vw; }
		.bg-circle:nth-child(22) {
			top: 2250px;
			left: 90vw; }

		.bg-circle:nth-child(23) {
			top: 2330px;
			left: -10vw; }
		.bg-circle:nth-child(24) {
			top: 2470px;
			left: 95vw; }
		.bg-circle:nth-child(25) {
			top: 2650px;
			left: 90vw; }
		.bg-circle:nth-child(26) {
			top: 2700px;
			left: -20vw; }
		.bg-circle:nth-child(27) {
			top: 2880px;
			left: 4vw; } }

	#bootstrapoverride .story-container {
		width: 100vw;

		.introduction {
			margin-bottom: 0px;
			margin-top: 70px;

			.text-align-bottom {
				position: relative;
				text-align: center;
				font-size: 14px;
				width: 273px;
				margin: 5px auto;

				h2 {
					font-size: 26px;
					line-height: 39px;
					font-size: 14px;
					line-height: 1.64;
					letter-spacing: 1.2px; } }
			.col-sm-5 {
				img {
					width: 90%;
					margin-left: 5%; } } }

		.books {
			margin: 80px auto 120px;
			width: 220px;

			.col-sm-3 {
				margin-bottom: 45px; }

			.col-sm-6 {
				text-align: right;
				img {
					width: 100%; } } }


		.img-wrapper {} }


	#bootstrapoverride .story-book-container {
		width: 100vw; } }










