

//animation arguments mixin
@mixin animation($name, $timing, $iteration, $duration, $delay, $fill) {
	animation-name: $name;
	animation-timing-function: $timing;
	animation-iteration-count: $iteration;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-fill-mode: $fill; }


// floating animation
@keyframes floating {
	0%, 100% {
		transform: rotate(0deg) translate3d(-20px,0px,0px) rotate(-5deg); }
	50% {
		transform: rotate(-180deg) translate3d(-20px,0px,0px) rotate(180deg); } }


.floating {
	@include animation(floating, ease-in-out, infinite, 5s, 0s, none); }



// cloud floating animation
@keyframes floating-cloud {
	0%, 100% {
		transform: translate3d(-20px,0px,0px); }
	50% {
		transform: translate3d(20px,0px,0px); } }




// with scale
@keyframes float-scale-06 {
	0%, 100% {
		transform: translate3d(-20px,0px,0px) scale(.6); }
	50% {
		transform: translate3d(20px,0px,0px) scale(.6); } }

@keyframes float-scale-07 {
	0%, 100% {
		transform: translate3d(-20px,0px,0px) scale(.7); }
	50% {
		transform: translate3d(20px,0px,0px) scale(.7); } }

@keyframes float-scale-08 {
	0%, 100% {
		transform: translate3d(-20px,0px,0px) scale(.8); }
	50% {
		transform: translate3d(20px,0px,0px) scale(.8); } }

@keyframes float-scale-12 {
	0%, 100% {
		transform: translate3d(-20px,0px,0px) scale(1.2); }
	50% {
		transform: translate3d(20px,0px,0px) scale(1.2); } }

@keyframes float-scale-14 {
	0%, 100% {
		transform: translate3d(-20px,0px,0px) scale(1.4); }
	50% {
		transform: translate3d(20px,0px,0px) scale(1.4); } }



// .floating-cloud
// 	@include animation(floating-cloud, ease-in-out, infinite, 5s, 0s, none)



// flying down animation
@keyframes flying-down {
	100% {
		transform: transalte(0, 0); } }


// balloons flying off
@keyframes balloon-fly-1 {
	0% {
		transform: translate(0, 0); }
	90% {
		opacity: 1; }
	100% {
		transform: translate(-200px, -500px);
		opacity: 0; } }

@keyframes balloon-fly-2 {
	0% {
		transform: translate(0, 0); }
	90% {
		opacity: 1; }
	100% {
		transform: translate(-20px, -500px);
		opacity: 0; } }

@keyframes balloon-fly-3 {
	0% {
		transform: translate(0, 0); }
	90% {
		opacity: 1; }
	100% {
		transform: translate(200px, -500px);
		opacity: 0; } }

@keyframes balloon-fly-4 {
	0% {
		transform: translate(0, 0); }
	90% {
		opacity: 1; }
	100% {
		transform: translate(400px, -500px);
		opacity: 0; } }



// $balloon-bazier: cubic-bezier(.36,.22,.78,.37)
$balloon-bazier: cubic-bezier(.57,.08,.84,.87);


.balloon-fly-1 {
	@include animation(balloon-fly-1, $balloon-bazier, 1, 2.5s, 0s, forwards); }
.balloon-fly-2 {
	@include animation(balloon-fly-2, $balloon-bazier, 1, 2.5s, 0s, forwards); }
.balloon-fly-3 {
	@include animation(balloon-fly-3, $balloon-bazier, 1, 2.5s, 0s, forwards); }
.balloon-fly-4 {
	@include animation(balloon-fly-4, $balloon-bazier, 1, 2.5s, 0s, forwards); }


// cloud float
@keyframes cloud-float {
	0%, 100% {
		transform: translate(10px, 0); }
	50% {
		transform: translate(-10px, 0); } }


.cloud-float {
	@include animation(cloud-float, ease-in-out, infinite, 3s, 0s, none); }



// changing bear positon
.fly-to-right {
	transform: translate3d(300px, 0px, 0px) !important;
	transition: transform 2s ease-in-out; }

.fly-to-middle {
	transform: translate3d(50px, 0px, 0px) !important;
	transition: transform 2s ease-in-out; }


// bear landing
@keyframes bear-landing {
	0% {
		position: absolute;
		top: 8280px;
		left: 80px; }
	100% {
		position: absolute;
		top: 8310px;
		left: 70px; } }


.bear-landing {
	animation-name: bear-landing, family-sway;
	animation-timing-function: ease-in-out, ease-in-out;
	animation-iteration-count: 1, infinite;
	animation-duration: 1s, 2s;
	animation-delay: 0, 0;
	animation-fill-mode: forwards, none; }



// fmaily sway
@keyframes family-sway {
	0%, 100% {
		transform: rotate(-3deg);
		transform-origin: 50% 100%; }
	50% {
		transform: rotate(3deg);
		transform-origin: 50% 100%; } }


.family-sway {
	@include animation(family-sway, ease-in-out, infinite, 2s, 0s, none); }



// bear family spreading out
.family-opening-left-1 {
	left: 470px !important;
	transition: left 2s ease-in-out; }

.family-opening-left-2 {
	left: 570px !important;
	transition: left 2s ease-in-out; }

.family-opening-left-3 {
	left: 630px !important;
	transition: left 2s ease-in-out; }

.family-opening-right-1 {
	left: 810px !important;
	transition: left 2s ease-in-out; }

.family-opening-right-2 {
	left: 890px !important;
	transition: left 2s ease-in-out; }



// pop up animation
.popup {
	transform: scale(1) !important;
	transition: transform .7s cubic-bezier(.42,.05,.56,1.58); }


// clouds moving in
.fly-from-left {
	transform: translateX(0) !important;
	transition: transform 2s cubic-bezier(.06,.53,.47,.93); }
.fly-from-right {
	transform: translateX(0) !important;
	transition: transform 2s cubic-bezier(.06,.53,.47,.93); }

.push-out-left {
	transform: translateX(-800px); }

.push-out-right {
	transform: translateX(800px); }


// rain pulse
@keyframes pulse {
	0%, 100% {
		opacity: 1; }
	50% {
		opacity: 0; } }

.pulse-1 {
	@include animation(pulse, ease-in-out, infinite, 1.5s, 0s, none); }
.pulse-2 {
	@include animation(pulse, ease-in-out, infinite, 1.5s, .5s, none); }
.pulse-3 {
	@include animation(pulse, ease-in-out, infinite, 1.5s, 1s, none); }


// dolphin jump
@keyframes dolphin-jump {
	0% {
		transform: rotate(0deg) translate(0) rotate(0deg); }
	100% {
		transform: rotate(45deg) translate(-350px) rotate(-45deg); } }


.dolphin-jump {
	@include animation(dolphin-jump, ease-in-out, 1, 1.5s, 0s, forwards); }


// raise heart
@keyframes raise-up {
	0% {
		transform: translateY(0); }
	100% {
		transform: translateY(-200px); } }

@keyframes bouncing {
	0%, 100% {
		top: 130px;
		transform: translateY(0); }
	50% {
		transform: translateY(15px); } }


.raise-heart {
	animation-name: raise-up, bouncing;
	animation-timing-function: ease-out, ease-in-out;
	animation-iteration-count: 1, infinite;
	animation-duration: 2s, 2s;
	animation-delay: 0s, 2s; }



// sparkles
// @keyframes sparkling
// 	0%
// 		opacity: 0
// 	50%
// 		opacity: 1
// 		// transform: translate3d(0px, -50px, 0px)
// 	100%
// 		opacity: 0
// 		// transform: translate3d(0px, -100px, 0px)

@keyframes sparkling {
	0% {
		opacity: 0; }
	25% {
		opacity: 1; }
	50% {
		opacity: 0; }
	75% {
		opacity: 1; }
	100% {
		opacity: 0; } }



.sparkling {
	img {
		animation-name: sparkling;
		animation-timing-function: linear;
		animation-iteration-count: 1;
		animation-duration: 2s;
		animation-fill-mode: forwards; } }




// winking
@keyframes winking {
	0% {
		z-index: 1; }
	100% {
		z-index: -1; } }

@media all and (max-width: $mobile) {
	.family-opening-left-1 {
		left: 32vw !important; }

	.family-opening-left-2 {
		left: 39vw !important; }

	.family-opening-left-3 {
		left: 50vw !important; }

	.family-opening-right-1 {
		left: 66vw !important; }

	.family-opening-right-2 {
		left: 74vw !important; }

	.bear-absolute {
		position: absolute !important;
		top: 6850px !important;
		left: 4vw !important; }
	.fly-to-right {
		transform: translate3d(33vw, 0px, 0px) !important;
		transition: transform 2s ease-in-out; }
	.fly-to-middle {
		transform: translate3d(0vw, 0px, 0px) !important;
		transition: transform 2s ease-in-out; } }












