#bootstrapoverride {
	nav.desktop-nav {
		width: 100%;
		text-align: center;
		padding: 50px 0 40px;

		.logo img {
			width: 243px;
			height: 34px; }

		.logo {
			display: inline-block;
			margin-bottom: 30px; }

		.quick-nav {
			position: absolute;
			top: 50px;
			right: 20px;

			li {
				display: inline-block;
				margin: 0 4px;
				vertical-align: top;

				&:first-of-type {
					position: absolute;
					top: 7px;
					left: -90px; } }

			.circle {
				display: inline-block;
				width: 35px;
				height: 35px;
				background-color: $vermillion;
				border-radius: 50%;

				img {
					float: left; }

				div {
					float: left;
					color: white;
					font-size: 20px;
					line-height: 40px; } }

			.non-empty-cart {
				width: 70px;
				height: 35px;
				border-radius: 18px; }

			.search-btn {
				display: inline-block;
				position: relative;
				width: 35px;
				height: 35px;
				transition: width .5s ease-in-out;

				.circle {
					position: absolute;
					top: 0;
					left: 0;
					width: 35px;
					height: 35px;
					cursor: pointer; }

				input {
					display: inline-block;
					width: 230px;
					height: 35px;
					color: #545455;
					padding: 1px 0 0 15px;
					border: solid 4px #9b9da0;
					border-radius: 18px;
					pointer-events: none;

					&::-webkit-input-placeholder {
					   color: #545455; }
					&:-moz-placeholder {
					   color: #545455; }
					&::-moz-placeholder {
					   color: #545455; }
					&:-ms-input-placeholder {
					   color: #545455; } }


				.expanded>input {
					pointer-events: auto; }

				.search-bubble {
					display: inline-block;
					position: absolute;
					top: -45px;
					left: -155px;
					opacity: 0;
					transition: opacity .4s ease-out;
					z-index: 1;

					img {
						position: absolute;
						top: 8px;

						&:hover {
							cursor: pointer; } }

					#search {
						right: 32px; }
					#delete-input {
						right: 10px; }

					&.expanded {
						opacity: 1; }

					// &:before
					// 	content: ""
					// 	position: absolute
					// 	top: 28px
					// 	left: 60px
					// 	width: 0
					// 	height: 0
					// 	border-style: solid
					// 	border-width: 8px 6px
					// 	border-color: $vermillion transparent transparent transparent
					&:after {
						content: "";
						position: absolute;
						top: 32px;
						left: 153px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 18px 8px;
						border-color: #9b9da0 transparent transparent transparent;
						transform: skew(40deg); } } }


			a {
				position: relative;

				&:hover .bubble {
					opacity: 1; }

				.bubble {
					display: inline-block;
					position: absolute;
					top: -60px;
					left: -5px;
					width: 100px;
					height: 28px;
					color: $white;
					background-color: $vermillion;
					padding: 2px 0;
					border-radius: 18px;
					opacity: 0;
					transition: opacity .4s ease-out;
					pointer-events: none;

					&:before {
						content: "";
						position: absolute;
						top: 24px;
						left: 15px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 11px 7px;
						border-color: $vermillion transparent transparent transparent; } } }


			a[href="/bear"] .bubble {
				left: -50px !important;

				&:before {
					left: 60px !important; }
				&:after {
					left: 61px !important; } }


			a[href="/cart"] .bubble {
				width: 80px !important;
				left: -35px !important;

				&:before {
					left: 45px !important; }
				&:after {
					left: 46px !important; } } }


		.main-nav {
			li {
				display: inline-block;
				margin: 0 1px;
				padding: 5px 15px 3px;
				border: solid 1px transparent;
				transition: background-color .3s, color .3s;

				a {
					font-size: 18px;
					font-weight: bold; } }

			li:last-of-type {
				position: relative;

				span.cart {
					position: absolute;
					bottom: -5px;
					right: -60px;
					width: 75px;
					height: 55px; } } } } }
.mobile-nav-top {
	display: none; }

.side-nav {
	position: fixed;
	width: 200px;
	height: 100vh;
	top: 0px;
	left: -200px;
	transition: all 0.2s linear;
	background-color: #f0190f;
	overflow: hidden;
	li {
		color: white;
		letter-spacing: 1.6px;
		line-height: 60px !important;
		padding: 0px 24px;
		height: 60px;
		position: relative;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
		text-align: center;
		display: block;

		&:first-child {
			line-height: 0px !important;
			padding: 18px 0px; }

		input {
			height: 23px;
			width: 112px !important;
			background-color: white !important;
			color: black;
			position: relative;
			padding: 0px 15px !important; }
		.search {
			position: absolute;
			top: 19px;
			right: 46px; }
		#delete-input {
			position: absolute;
			display: none;
			top: 22px;
			right: 48px; }
		.login-pic {
			position: absolute;
			top: 20px;
			left: 38px; }
		.cart-pic {
			position: absolute;
			top: 11px;
			right: 20px; } } }

.side-nav-open {
	left: 0px; }


@media all and (max-width: $tablet) {
	.desktop-nav {
		display: none; }
	.mobile-nav-top {
		display: block;
		position: fixed;
		z-index: 20;
		background-color: white;
		width: 100vw;
		.nav-burger {
			height: 5px;
			width: 24px;
			position: relative;
			background-color: #f0190f;
			margin: 24px 20px;
			&:before {
				content: "";
				position: absolute;
				height: 5px;
				width: 24px;
				top: -8px;
				background-color: #f0190f; }

			&:after {
				content: "";
				position: absolute;
				height: 5px;
				width: 24px;
				top: 8px;
				background-color: #f0190f; } }

		.mobile-top-logo {
			width: 170px;
			margin: -40px auto 0px;
			img {
				width: 170px;
				height: auto; } }


		.shopping-cart {
			position: absolute;
			top: 16px;
			right: 20px;
			img {
				width: 24px;
				height: 24px; }

			.none-empty-cart {
				div {
					display: none; } } } } }

