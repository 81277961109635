#bootstrapoverride .shop-container {
	max-width: 1200px;
	margin: 30px auto 100px;

	// shop navigation styling
	.shop-nav-container {
		float: left;
		width: 200px;
		padding-left: 30px;

		li {
			cursor: pointer;
			margin-bottom: 5px;
			position: relative; }

		.active-li {
			.active-dec {
				content: "";
				height: 22px;
				width: 22px;
				border-radius: 15px;
				top: -1px;
				left: -29px;
				position: absolute; } }

		.main-category {
			position: relative;
			margin-bottom: 10px;

			&>span {
				font-size: 18px;

				a {
					font-size: 18px; } }

			.color-node {
				position: absolute;
				top: 3px;
				left: -25px;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: transparent; }

			.color-node-boy {
				@extend .color-node;
				background-color: #80d4ff; }

			.color-node-girl {
				@extend .color-node;
				background-color: #ff8cb4; } }


		.sub-category {
			display: none;
			margin: 10px 0 10px 15px;

			a {
				font-size: 14px;
				position: relative; }


			&.active {
				display: block; } } }



	// product list view
	.product-list-container {
		float: left;
		width: calc(100% - 220px);
		.shop-home-pic {
			position: relative;
			margin: 11px;
			background-color: #d8d8d8;
			float: left;
			overflow: hidden;
			img {
				width: inherit;
				height: inherit; }
			.intro {
				position: absolute;
				height: 135px;
				bottom: -135px;
				background-color: #ee8ab6;
				transition: all .1s ease-in-out;
				font-size: 18px;
				font-weight: bold;
				letter-spacing: 2.3px;
				line-height: 1.5;
				color: #ffffff;
				padding: 40px 50px; }

			&:hover {
				.intro {
					bottom: 0px; } } }


		.shop-home-one-pic {
			width: 913px;
			height: 550px;
			.intro {
				width: 913px; } }

		.shop-home-two-pic {
			width: 446px;
			height: 568px;
			.intro {
				width: 446px; } }

		.shop-home-three-pic {
			width: 290px;
			height: 280px;
			.intro {
				width: 290px; } }

		.shop-banner-container-home {
			width: 913px;
			height: 550px;
			background-color: #d8d8d8;
			margin: 11px 11px 60px 11px;
			img {
				width: 913px;
				height: 550px; } }

		.shop-banner-container {
			width: 980px;
			height: 590px;
			background-color: #d8d8d8;
			margin-bottom: 60px;
			img {
				width: 980px;
				height: 590px; } }

		.row>div {
			height: 450px;
			text-align: center; }

		.img-center {
			margin-bottom: 20px;

			img {
				height: 310px; } }

		.product-name,
		.product-price {
			font-size: 18px;
			line-height: 24px; }

		.product-price {
			font-weight: bold;
			font-family: $secondary-style;
			margin-bottom: 5px; }

		.sold-out {
			font-size: 16px;
			line-height: 19px;
			font-weight: bold;
			color: #d1d3d4; } }



	// product view styling
	.size-table-bg {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, .7);
		z-index: 1000; }

	.size-table {
		position: fixed;
		top: 2vh;
		left: 15vw;
		width: 70vw;
		height: 96vh;
		overflow-y: auto;
		z-index: 1001;
		background-color: white;
		padding: 20px;
		.size-div {
			width: 800px;
			margin: 20px auto; }
		.close-btn {
			position: fixed;
			right: 10px;
			top: 6vh;
			width: 32px;
			height: 32px;
			opacity: 1;
			z-index: 1000; }
		.close-btn:before, .close-btn:after {
			position: absolute;
			left: 15px;
			content: '';
			height: 33px;
			width: 2px;
			background-color: #333; }
		.close-btn:before {
			transform: rotate(45deg); }
		.close-btn:after {
			transform: rotate(-45deg); }

		.size-table {}
		table {
			width: 800px;
			margin: 20px auto;
			border-bottom: dashed 1px #808080;
			color: #808080;
			tr:last-child {
				td {
					padding-bottom: 8px; } }
			.unberline-tr {
				border-bottom: dashed 1px #808080;
				th {
					padding-bottom: 5px;
					color: #595757; } }
			.upper-block td {
				padding-top: 5px; }
			th, td {
				min-width: 70px; }
			.table-title-2 {
				letter-spacing: 35px; }
			.table-title-3 {
				letter-spacing: 10.8px; } }

		.size {
			img {
				display: block;
				margin: 20px auto;
				width: 805px; } }


		.size-chart-img {
			background-image:  url("../img/img-sizechart.svg");
			background-size: contain;
			background-repeat: no-repeat;
			width: 90%;
			height: 525px;
			margin: 0px auto; }
		.measure-diagram {
			background-image:  url("../img/measurediagram.svg");
			background-size: contain;
			background-repeat: no-repeat;
			width: 90%;
			height: 1339px;
			margin: 0px auto; }

		.orange-line {
			width: 800px;
			height: 2px;
			background-color: #f0190f;
			margin: 20px auto; } }


	.product-view {
		.similar-products {
			margin: 50px 0; } }


	.item-photo p {
		margin-top: 20px; }


	.active-photo img {
		height: 514px; }

	.mini-photos {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.img-center {
			flex-shrink: 0;
			width: 100px;
			margin: 0px 5px; }

		img {
			height: 100px;
			cursor: pointer; } }


	.item-options {
		.item-name {
			font-size: 18px;
			font-weight: bold; }

		.item-code {
			font-size: 14px;
			color: #6d6d6d; }

		.item-price {
			font-weight: bold;
			color: black;
			margin: 30px 0 10px;

			span {
				font-weight: bold;
				color: $vermillion; } }
		.on-sale-price {
			color: $vermillion; }

		.original-price {
			font-size: 18px;
			font-weight: bold;
			text-decoration: line-through; }

		.small-gray {
			color: #808080;
			font-size: 14px;
			text-decoration: none; }

		.colors {
			width: 90%;
			margin-top: 30px;
			margin-bottom: 20px;

			input[type="radio"] {
				display: none; }

			label {
				width: 36px;
				height: 36px;
				cursor: pointer;
				margin-right: 10px; }

			label.blue {
				@include image-formating("../img/color_tickets/01.png"); }
			label.pink {
				@include image-formating("../img/color_tickets/02.png"); }
			label.yellow {
				@include image-formating("../img/color_tickets/03.png"); }
			label.red {
				@include image-formating("../img/color_tickets/04.png"); }

			input[type="radio"]:checked + label {
				box-shadow: 0 0 0 1px rgba(255,255,255,1), 0 0 0 2px rgba(0,0,0,.4); } }


		a.size-select,
		.size-info {
			display: inline-block;
			color: #808080;
			padding: 0 7px;
			line-height: 16px; }
		.size-info {
			cursor: pointer; }

		a.size-select {
			padding: 0 12px 0 0;
			border-right: 1px solid #808080; }

		.size-view {
			width: 90%;
			margin-top: 15px;
			margin-bottom: 25px;

			input[type="radio"] {
				display: none; }

			label {
				cursor: pointer; }

			.size-bubble {
				display: inline-block;
				width: 50px;
				padding: 3px;
				text-align: center;
				border: 2px solid #595757;
				border-radius: 15px;
				margin: 0 10px 10px 0; }

			input[type="radio"]:checked + label {
				color: $white;
				background-color: $vermillion;
				border: 2px solid $vermillion; } }


		.order-amount>.quantity-adjust {
			width: 90px;
			margin: 0; }


		button[type="submit"] {
			appearance: none;
			padding: 4px 22px;
			color: $white;
			background-color: $vermillion;
			font-size: 18px;
			font-weight: bold;
			line-height: 24px;
			letter-spacing: 2px;
			border-radius: 20px;
			border: none;
			margin: 20px 0 30px; }

		.shop-btn {
			float: left; }

		.shop-like-btn {
			position: relative;
			display: flex;
			img {
				margin: 18px 2px 32px;
				position: relative; }
			span {
				position: absolute;
				width: 0px;
				height: 32px;
				background-color: #f0190f;
				border-radius: 16px;
				top: 19px;
				left: 15px;
				font-size: 16px;
				color: #ffffff;
				transition: all .5s ease-in-out;
				z-index: -1;
				line-height: 2;
				font-weight: bold; }

			&:hover {
				span {
					width: 140px;
					position: absolute; } } } }




	.delivery-info {
		h4 {
			position: relative;
			color: $vermillion;
			margin-bottom: 20px;

			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: -10px;
				left: -10px;
				width: calc(100% + 10px);
				height: 0;
				border-bottom: 1px solid #545454; } }

		p {
			color: #545454;
			font-size: 14px;
			margin-bottom: 30px; } }


	.item-detail-info {
		h5, p {
			font-size: 14px; }

		h5 {
			color: $vermillion;
			margin-bottom: 10px; } }

	.shopping-from {
		min-height: 10px;
		overflow: hidden; }


	.similar-products h4 {
		font-weight: bold;
		margin-bottom: 20px; }
	.recommend-container {
		width: 58.3%;
		min-height: 206px;
		float: left;
		.tab {
			width: 175px;
			height: 60px;
			top: 3px;
			padding: 13px;
			&:after {
				top: 28px;
				left: 168px; }
			p {
				font-size: 18px; } }

		.inner-container {
			width: 100%;
			min-height: 206px;

			.recommend-slick {
				margin: 19px auto;
				width: 90%;
				height: 150px;
				a {
					margin: 0px 5px; }
				.img {
					width: 150px;
					height: 150px;
					background-size: cover;
					float: left; } }
			.slick-prev, .slick-next {
				z-index: 20;
				background-image: none !important;
				&:before {
					opacity: 1;
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 21px 0 21px 18px;
					font-size: 0px;
					border-color: transparent transparent transparent #f0190f; } }
			.slick-prev {
				left: -20px;
				top: 44%; }
			.slick-next {
				right: -14px;
				top: 55%; } } } }


.slick-prev {
	left: 0;
	top: 35%;
	transform: rotateZ(180deg); }
.slick-next {
	right: 0; }

.zoomImg {
	background-color: white; }

.mobile-shop-nav {
	width: 200px;
	height: 60px;
	color: white;
	padding: 18px 0px;
	font-size: 12px !important;
	margin-left: -40px;
	text-align: center;
	position: relative;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 100px 0 rgba(0, 0, 0, 0.19);
	display: none;
	a {
		font-size: 12px !important; }
	input {
		height: 23px;
		width: 112px !important;
		background-color: white !important;
		color: black;
		position: relative; }
	.search {
		position: absolute;
		top: 20px;
		right: 47px; }
	#delete-input {
		position: absolute;
		display: none;
		top: 21px;
		right: 45px; }
	.login-pic {
		position: absolute;
		top: 20px;
		left: 38px; } }

#bootstrapoverride .return-confirm-container {
	width: 700px;
	margin: 50px auto; }


@media all and (max-width: $mobile) {
	.mobile-shop-nav {
		display: block; }
	#bootstrapoverride .shop-container {
		max-width: 100vw;
		margin: 60px auto 100px;

		// shop navigation styling
		.shop-nav-container-open {
			left: 0px !important; }
		.shop-nav-container {
			position: fixed;
			padding: 0px 40px;
			height: 100vh;
			overflow-y: auto;
			overflow-x: hidden;
			top: 0px;
			left: -200px;
			background-color: #f0190f;
			transition: all 0.2s linear;

			li {
				color: white;
				a, span {
					font-size: 12px !important; } }
			.main-category {
				.color-node {
					top: 8px;
					left: -18px;
					width: 7px;
					height: 7px; } }
			.active-li {
				.active-dec {
					width: 9px;
					height: 9px;
					top: 7px;
					left: -19px;
					border: solid 1px white !important; } } }
		.shop-nav {
			margin: 20px 0px; }

		#shop-mobile-title {
			width: 90vw;
			margin: 0px auto -20px;
			font-size: 11px;
			font-weight: 500;
			color: #808080; }
		.mini-photos {
			width: 93vw; }


		// product list view
		.product-list-container {
			float: left;
			width: 100vw;
			padding: 20px 5vw;
			.shop-home-pic {
				margin: 2vw 2vw 0vw 0px;
				.intro {
					padding: 40px 50px; } }

			.shop-home-one-pic {
				width: 90vw;
				height: 54vw;
				margin-right: 0px;
				.intro {
					display: none;
					width: 90vw; } }

			.shop-home-two-pic {
				width: 44vw;
				height: 55vw;
				.intro {
					display: none;
					width: 44vw; } }

			.shop-home-three-pic {
				width: 28vw;
				height: 26vw;
				.intro {
					display: none;
					width: 28vw; } }

			.last-pic {
				margin-right: 0px; }

			.shop-banner-container-home {
				width: 90vw;
				height: 56vw;
				margin: 11px auto 60px;
				img {
					width: 100%;
					height: 100%; } }

			.shop-banner-container {
				width: 90vw;
				height: 56vw;
				margin: 11px auto 60px;
				img {
					width: 100%;
					height: 100%; } }
			.row {
				.col-md-4 {
					width: 44vw;
					height: 50vw;
					margin-right: 2vw;
					margin-bottom: 20px;
					float: left;
					&:nth-child(2n) {
						margin-right: 0px; }

					img {
						width: 44vw;
						height: 39vw; }
					p {
						font-size: 11px !important;
						line-height: 15px;
						width: 44vw; } } }
			.img-center {
				margin-bottom: 0px; } }
		.active-photo {
			img {
				height: 90vw !important; } }
		.size-table {
			top: 5vh;
			left: 0vw;
			width: 100vw;
			height: 90vh;
			padding: 20px;
			.size-div {
				width: 85vw;
				margin: 10px auto;
				padding: 0px 15px;
				font-size: 11px; }
			table {
				width: 78vw;
				margin: 20px auto;
				font-size: 11px;
				th, td {
					min-width: 3%;
					width: 3%;
					&:first-child {
						width: 12%; } }
				.table-title-2 {
					letter-spacing: 15px; }
				.table-title-3 {
					letter-spacing: 1px; } }

			.size {
				img {
					display: block;
					margin: 20px auto;
					width: 100%; } }

			.size-chart-img {
				background-image:  url("../img/mobile-sizechart.svg");
				height: 645px; }
			.measure-diagram {
				background-image:  url("../img/mobile-measurediagram.svg");
				height: 1620px;
				margin: 0px auto; }
			.orange-line {
				width: 77vw;
				height: 2px;
				background-color: #f0190f;
				margin: 20px auto; } } }

	.product-view {
		padding: 0px;
		.item-photo {
			p {
				margin-bottom: 20px;
				font-size: 10px !important; } }
		.item-options {
			.item-name {
				font-size: 16px; }

			.colors {
				label {
					width: 25px;
					height: 25px; } } }
		.shop-like-btn {
			&:hover {
				span {
					width: 0px !important; } } } }
	#bootstrapoverride .shop-container {
		.delivery-info {
			h4 {
				font-size: 18px !important; } }
		.recommend-container {
			width: 90vw;
			min-height: 104px;
			float: left;
			margin: 30px 5vw;
			.tab {
				width: 90px;
				height: 60px;
				top: 17px;
				padding: 3px;
				&:after {
					top: 28px;
					left: 168px; }
				p {
					font-size: 11px; } }

			.inner-container {
				width: 100%;
				min-height: 126px;
				height: 126px;
				border: 2px solid #f0190f;

				.recommend-slick {
					margin: 19px auto;
					width: 90%;
					height: 100px;
					a {
						margin: 0px 2px; }
					.img {
						width: 100%;
						height: 80px;
						margin: 0px; } }
				.slick-prev, .slick-next {
					z-index: 20;
					background-image: none !important;
					&:before {
						opacity: 1;
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 9px 0 9px 10px;
						font-size: 0px;
						border-color: transparent transparent transparent #f0190f; } }
				.slick-prev {
					left: -16px;
					top: 24%; }
				.slick-next {
					right: -17px;
					top: 41%; } } } }
	#bootstrapoverride .return-confirm-container {
		width: 100vw;
		padding: 20px;
		.return-btn {
			float: left !important;
			margin: 10px 0; } } }











