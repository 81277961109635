

// color stack
$water-blue: #00b9ff;
$bright-sky-blue: #00b9ff;
$pink-red: #ea2224;
$lipstick: #d02027;
$vermillion: #f0190f;
$dark-lime-green: #64d200;
$nasty-green: #81b141;
$big-pink: #e872a4;
$pink: #ff4191;
$dusty-orange: #ff7f00;
$pumpkin-orange: #ff7f00;
$sun-yellow: #ffe100;
$purple: #7e49da;
$white: #fff;
$black: #231815;
$black-50: rgba(0,0,0,.5);
$text-grey: #595757;

//decoration circles
$sunflower-yellow: #ffe100;
$barbie-pink: #ff4191;
$bright-sky-blue: #00b9ff;
$pumpkin-orange: #ff7f00;
$vermillion: #f0190f;
$dark-lime-green: #64d200;

// nav/footer colors
$story: #ff4191;
$newclothes: #fdc30e;
$visit: #00b9ff;
$shop: #ee7222;

$tablet: 770px;
$mobile: 500px;



// font stack
@font-face {
	font-family: 'Noto';
	src: url('../fonts/NotoSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Noto';
	src: url('../fonts/NotoSans-Bold.ttf');
	font-weight: bold;
	font-style: normal; }
@font-face {
	font-family: 'Yuanti';
	src: url('../fonts/cwTeXYen-zhonly.ttf');
	font-weight: normal;
	font-style: normal; }
// @font-face
// 	font-family: 'fa'
// 	src: url('../fonts/fontawesome-webfont.woff')
// 	src: url('../fonts/fontawesome-webfont.eot')
// 	src: url('../fonts/fontawesome-webfont.ttf')
// 	font-weight: normal
// 	font-style: normal

$primary-style: "Heiti TC", "lato", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "微軟正黑體", "Microsoft JhengHei", sans-serif;
$secondary-style: "Noto", "lato", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;

// image mixin
@mixin image-formating($image) {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%; }



// shadows
$box-shadow: 1px 1px 6px 2px rgba(0,0,0,.2);
$box-shadow2: 0 0 10px 5px rgba(0,0,0,.05);
$inset-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); // darkening of pictures
$text-shadow: 2px 2px 6px rgba(0,0,0,.5);
$catalogue-shadow: 0 0 25px 8px rgba(0,0,0,.3);


//bootstrap breakpoints (-1 px so can use with max-width)
$xs: 0;
$sm: 420px; //my own custom breakpoint, not in bootstrap
$md: 767px;
$lg: 991px;
$xl: 1199px;





