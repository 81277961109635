//decroation circles
.decoration-bg-visit {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 1400px;
	margin: 0 auto;
	z-index: 0;

	.bg-circle {
		opacity: .4; }

	.bg-circle:nth-child(1) {
		top: 195px;
		left: 480px; }
	.bg-circle:nth-child(2) {
		top: 1100px;
		left: 100px; }
	.bg-circle:nth-child(3) {
		top: 1240px;
		left: 1125px; }
	.bg-circle:nth-child(4) {
		top: 1485px;
		left: -80px; }
	.bg-circle:nth-child(5) {
		top: 1600px;
		left: 1230px; }
	.bg-circle:nth-child(6) {
		top: 1825px;
		left: 170px; }
	.bg-circle:nth-child(7) {
		top: 2080px;
		left: 1105px; }
	.bg-circle:nth-child(8) {
		top: 2265px;
		left: 25px; }
	.bg-circle:nth-child(9) {
		top: 2460px;
		left: 1265px; }
	.bg-circle:nth-child(10) {
		top: 2700px;
		left: 75px; }
	.bg-circle:nth-child(11) {
		top: 2835px;
		left: 1145px; }
	.bg-circle:nth-child(12) {
		top: 2985px;
		left: 150px; }
	.bg-circle:nth-child(13) {
		top: 3190px;
		left: 1265px; }
	.bg-circle:nth-child(14) {
		top: 3315px;
		left: -80px; }
	.bg-circle:nth-child(15) {
		top: 3675px;
		left: 1120px; } }


//for visit store page
.decoration-bg-visit-detail {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;

	.bg-circle {
		opacity: .4; }

	.bg-circle:nth-child(1) {
		top: 325px;
		left: 120px; }
	.bg-circle:nth-child(2) {
		top: 420px;
		left: 1200px; }
	.bg-circle:nth-child(3) {
		top: 720px;
		left: -75px; }
	.bg-circle:nth-child(4) {
		top: 800px;
		left: 1220px; }
	.bg-circle:nth-child(5) {
		top: 960px;
		left: 850px; }
	.bg-circle:nth-child(6) {
		top: 1060px;
		left: 390px; } }



@mixin bear-righttop {
	display: block;
	position: absolute;
	content: "";
	top: -78px;
	right: 0;
	width: 205px;
	height: 90px;
	@media all and (max-width: $mobile) {
		top: -53px;
		width: 125px;
		height: 59px; } }


#bootstrapoverride .visit-container {
	position: relative;
	width: 900px;
	margin: 40px auto 80px;
	z-index: 10;


	ol.carousel-indicators {
		bottom: -50px;
		left: auto;
		right: 0px;
		width: 100%;

		li {
			width: 15px;
			height: 15px;
			border-color: $vermillion;
			margin: 0 7px;
			float: right;

			&.active {
				background-color: $vermillion; } } }


	.visit-nav {
		margin: 65px 0 30px;

		li {
			display: inline-block;
			width: 110px;
			height: 110px;
			margin-right: 15px;
			text-align: center;
			border-radius: 50%;
			border: 3px solid;
			transition: background-color .3s;

			a {
				display: block;
				width: 100%;
				height: 100%;
				padding-top: calc(55px - 14px);
				font-size: 18px;
				font-weight: bold;
				border-radius: 50%;
				transition: color .3s; } }

		.red {
			border-color: $vermillion;
			a {
				color: $vermillion; }

			&:hover {
				background-color: $vermillion;
				a {
					color: $white; } } }

		.green {
			border-color: $dark-lime-green;
			a {
				color: $dark-lime-green; }

			&:hover {
				background-color: $dark-lime-green;
				a {
					color: $white; } } }

		.orange {
			border-color: $pumpkin-orange;
			a {
				color: $pumpkin-orange; }

			&:hover {
				background-color: $pumpkin-orange;
				a {
					color: $white; } } }

		.blue {
			border-color: $bright-sky-blue;
			a {
				color: $bright-sky-blue; }

			&:hover {
				background-color: $bright-sky-blue;
				a {
					color: $white; } } } }



	.img-wrapper {
		width: 900px;
		height: 600px;

		img {
			position: absolute;
			height: auto !important;
			width: auto !important;
			top: -100%;
			bottom: -100%;
			left: -100%;
			right: -100%;
			margin: auto; } }



	.store-list {
		position: relative;
		width: 100%;
		margin-bottom: 35px;
		padding: 40px 90px;
		border: 4px solid;
		border-radius: 20px;
		background-color: $white;

		h5 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 20px; }

		li {
			margin: 10px 0;
			font-size: 15px; }

		.break {
			display: inline-block;
			width: 100%;
			height: 30px; } }

	.div-to-top-btn {
		width: 85px;
		height: 26px;
		padding: 3px 19px 3px 32px;
		border-radius: 30px;
		position: relative;
		float: right;
		color: white;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
		cursor: pointer;

		&:before {
			position: absolute;
			content: "";
			left: 14px;
			top: 10px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 7px 7px 7px;
			border-color: transparent transparent white transparent; } }

	.stores-1 {
		border-color: $vermillion;
		.div-to-top-btn {
			background-color: $vermillion; }
		&:before {
			@include bear-righttop;
			@include image-formating("../img/visit/container-righttop-redhead.svg"); } }

	.stores-2 {
		border-color: $dark-lime-green;
		.div-to-top-btn {
			background-color: $dark-lime-green; }
		// &:before
		// 	@include bear-righttop
 }		// 	@include image-formating("../img/visit/container-righttop-greenhead.svg")

	.stores-3 {
		border-color: $pumpkin-orange;
		.div-to-top-btn {
			background-color: $pumpkin-orange; }
		// &:before
		// 	@include bear-righttop
 }		// 	@include image-formating("../img/visit/container-righttop-orangehead.svg")

	.stores-4 {
		border-color: $bright-sky-blue;
		.div-to-top-btn {
			background-color: $bright-sky-blue; }
		// &:before
		// 	@include bear-righttop
		// 	@include image-formating("../img/visit/container-righttop-bluehead.svg")

		&:after {
			display: block;
			position: absolute;
			content: "";
			top: 90px;
			left: -170px;
			width: 220px;
			height: 295px;
			@include image-formating("../img/visit/container-lower-left-bear.svg"); } }
	.in-branch {
		&:after {
			display: none; } }



	//store detail page
	.store-list.store-detail {
		margin-top: 90px;

		.store-name {
			width: 470px;
			margin: 0 auto 30px;
			padding-bottom: 30px;
			text-align: center;

			h2 {
				font-size: 22px;
				font-weight: bold;
				color: $dark-lime-green; } }


		.row {
			margin-bottom: 45px; }


		.col-sm-2 {
			border-right: 2px solid $text-grey;
			padding-right: 0; }

		.col-sm-6 {
			padding-left: 25px;

			p {
				display: inline-block;
				margin-bottom: 20px; } }


		.info p {
			font-size: 18px;
			font-weight: bold; } }


	//individual colors
	.store-list.store-detail.stores-1 {
		.store-name {
			border-bottom: 4px solid $vermillion;

			h2 {
				color: $vermillion; } } }

	.store-list.store-detail.stores-2 {
		.store-name {
			border-bottom: 4px solid $dark-lime-green;

			h2 {
				color: $dark-lime-green; } } }

	.store-list.store-detail.stores-3 {
		.store-name {
			border-bottom: 4px solid $pumpkin-orange;

			h2 {
				color: $pumpkin-orange; } } }

	.store-list.store-detail.stores-4 {
		.store-name {
			border-bottom: 4px solid $bright-sky-blue;

			h2 {
				color: $bright-sky-blue; } } }



	//bear img for different stores
	.store-list.store-detail.stores-1:before {
		@include bear-righttop;
		@include image-formating("../img/visit/container-righttop-redhead.svg"); }

	.store-list.store-detail.stores-2:before {
		@include bear-righttop;
		@include image-formating("../img/visit/container-righttop-greenhead.svg"); }

	.store-list.store-detail.stores-3:before {
		@include bear-righttop;
		@include image-formating("../img/visit/container-righttop-orangehead.svg"); }

	.store-list.store-detail.stores-4:before {
		@include bear-righttop;
		@include image-formating("../img/visit/container-righttop-bluehead.svg"); }


	.map-container {
		width: 100%;
		height: 306px;
		margin-bottom: 80px;
		iframe {
			height: 150px; } } }

@media all and (max-width: $mobile) {
	#bootstrapoverride .visit-container {
		width: 100vw;
		.img-wrapper {
			width: 100vw;
			height: 300px; }

		.visit-nav {
			margin: 65px auto 30px;
			width: 298px;

			li {
				width: 65px;
				height: 65px;
				margin-right: 6px;
				font-size: 11px;
				a {
					font-size: 11px;
					padding-top: 20px; } } }

		.store-list {
			width: 90vw;
			padding: 14px 20px;
			margin: 0px auto; }

		.store-list-mobile {
			display: none; }


		.stores-1 {
			&:before {
				display: none; } }
		.stores-4 {

			&:after {
				display: none; } }

		//store detail page
		.store-list.store-detail {
			margin-top: 150px;

			.store-name {
				width: 77vw;
				margin: 0 auto 30px;
				padding-bottom: 0px;
				text-align: center;

				h2 {
					font-size: 14px;
					letter-spacing: 0.3px;
					color: $dark-lime-green; } }
			.row {
				margin-bottom: 10px; }
			.col-sm-2 {
				border-right: none; }

			.col-sm-6 {
				padding-left: 15px;
				font-size: 13px; }

			.info p {
				font-size: 13px;
				font-weight: bold;
				margin-bottom: 0px; } }
		.map-container {
			height: 150px;
			margin-bottom: 30px;
			iframe {
				height: 150px; } } }
	.decoration-bg-visit-detail {
		.bg-circle {
			opacity: .4; }

		.bg-circle:nth-child(1) {
			top: 120px;
			left: 2vw; }
		.bg-circle:nth-child(2) {
			top: 220px;
			left: 85px; }
		.bg-circle:nth-child(3) {
			top: 330px;
			left: -15vw; }
		.bg-circle:nth-child(4) {
			top: 430px;
			left: 97vw; }
		.bg-circle:nth-child(5) {
			top: 580px;
			left: 65vw; }
		.bg-circle:nth-child(6) {
			top: 640px;
			left: 15vw; } } }













