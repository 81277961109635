//css for cart view
#bootstrapoverride .cart-view-container {
	position: absolute;
	top: 100px;
	right: 20px;
	width: 510px;
	background-color: $white;
	border: 6px solid #f0190f;
	border-radius: 20px;
	z-index: 100;

	table.table-head {
		height: 90px;

		tr {
			border-bottom: 6px solid #f0190f; }

		th {
			font-weight: bold;
			font-size: 16px; } }

	.table-body {
		height: 350px;
		overflow: scroll;

		tr {
			border-bottom: 2px dashed #f0190f;
			height: 80px; } }

	table {
		width: 100%;
		text-align: center;

		th,
		td {
			width: 18%;
			text-align: center;
			vertical-align: middle; }

		th {
			color: #f0190f; }

		.item-info span,
		.discount-info span {
			display: block; } }


	.checkout-container {
		height: 100px;
		text-align: center;

		.checkout-btn {
			display: inline-block;
			width: 145px;
			height: 35px;
			border: none;
			border-radius: 20px;
			font-size: 18px;
			font-weight: bold;
			color: $white;
			background-color: #f0190f;
			margin-top: 30px;
			padding: 5px 0; } } }

//test comment



