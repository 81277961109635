#bootstrapoverride footer {
	width: 100%;
	background-color: transparent;
	color: white;
	padding: 50px;
	z-index: 11;
	transition: background-color .3s;
	background-color: #bbbbbb;

	.inner-container {
		width: 900px;
		margin: 0 auto;

		.footer-col {
			width: 25%;
			float: left; }

		.col-md-3 {
			h5 {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 15px; }

			li {
				line-height: 16px; }

			li a {
				font-size: 14px;
				line-height: 24px; } }

		.subscribe {
			margin: 2px 0 9px; }

		input[type="email"] {
			display: inline-block;
			float: left;
			width: 100px;
			height: 18px;
			border: solid 1px $white;
			border-radius: 10px;
			padding: 9px 10px;
			background-color: transparent;
			color: $white;
			font-size: 9px;
			line-height: 18px;

			&::-webkit-input-placeholder {
				color: $white; }
			&:-moz-placeholder {
				color: $white; }
			&::-moz-placeholder {
				color: $white; }
			&:-ms-input-placeholder {
				color: $white; } }


		.subscribe-btn {
			display: inline-block;
			float: left;
			width: 45px;
			height: 18px;
			border-radius: 10px;
			padding: 6px 8px 5px 8px;
			margin-left: 3px;
			font-size: 9px;
			line-height: 9px;
			color: $vermillion;
			background-color: $white;
			display: block;
			text-align: center;

			&:hover {
				cursor: pointer; } } }

	.social li {
		display: inline;
		margin: 0 2px; } }

@media all and (max-width: $mobile) {
	#bootstrapoverride footer {
		padding: 40px 15px;
		z-index: 11;

		.inner-container {
			width: 100vw;
			.col-md-3 {
				float: left;
				padding: 0px 20px 0px 0px;
				width: 33vw;
				h5 {
					font-size: 15px;
					margin-bottom: 20px; }
				li {
					font-size: 13px;
					line-height: 2;

					a {
						font-size: 13px;
						line-height: 2; } }
				.subscribe-btn {
					margin-top: 10px; }
				.dense {
					letter-spacing: -1px; } }
			input[type="email"] {
				width: 92px; }
			.footer-pic {
				float: right;
				width: 230px;
				margin: 42px 0px 0px 0px; } } } }





