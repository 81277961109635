

#bootstrapoverride {
	//button for adjusting qty
	.quantity-adjust {
		position: relative;
		width: 80px;
		height: 35px;
		padding: 4px 0;
		text-align: center;
		border: 2px solid $vermillion;
		border-radius: 20px;
		margin: 0 auto;

		.decrease-qty,
		.increase-qty {
			position: absolute;
			display: inline-block;
			top: 7px;
			width: 17px;
			height: 17px;
			background-color: $vermillion;
			border-radius: 50%;
			cursor: pointer;

			&:before {
				content: "";
				position: absolute;
				top: 8px;
				left: 2px;
				width: 13px;
				height: 1px;
				background-color: $white; } }

		.decrease-qty {
			left: 6px; }

		.increase-qty {
			right: 6px;

			&:after {
				content: "";
				position: absolute;
				top: 2px;
				left: 8px;
				width: 1px;
				height: 13px;
				background-color: $white; } }

		.show-qty {
			font-size: 16px; } }

	// styling when out of stock
	.item-quantity.out-of-stock {
		.quantity-adjust {
			border: 2px solid #bcbcbc;

			.decrease-qty,
			.increase-qty {
				display: none; }

			.show-qty {
				color: #bcbcbc; } } }


	.remove-item-btn {
		position: relative;
		width: 19px;
		height: 19px;
		background-color: grey;
		border-radius: 50%;
		margin: 0 auto;
		cursor: pointer;

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 9px;
			width: 15px;
			height: 1px;
			background-color: $white; }
		&:before {
			left: 2px;
			transform: rotate(45deg); }
		&:after {
			right: 2px;
			transform: rotate(-45deg); } } }
@media all and (max-width: $mobile) {
	#bootstrapoverride {
		//button for adjusting qty
		.quantity-adjust {
			width: 66px;
			height: 24px;
			padding: 1px 0;
			border-radius: 12px;

			.decrease-qty,
			.increase-qty {
				position: absolute;
				display: inline-block;
				top: 2px;
				&:before {
					content: "";
					position: absolute;
					top: 8px;
					left: 2px;
					width: 13px;
					height: 1px;
					background-color: $white; } }

			.decrease-qty {
				left: 2px; }

			.increase-qty {
				right: 2px; } }
		.remove-item-btn {
			position: absolute;
			top: -30px;
			right: 15px; } } }









