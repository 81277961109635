
#bootstrapoverride {

	.body-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		width: 100%;
		max-width: 1920px;
		overflow: hidden;
		margin-left: 0px;
		transition: all 0.2s linear; }

	.body-on-nav-open {
		margin-left: 200px; }


	.main-content-wrapper {
		flex: 1;
		position: relative;
		width: 100%;
		margin: 0 auto; } }
@media all and (max-width: $mobile) {
	body {
		overflow-x: hidden; }
	#non-overflow-body {
		width: 100vw;
		overflow-x: hidden; } }

