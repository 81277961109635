.decoration-bg-home {
	position: relative;
	width: 1400px;
	z-index: 0;
	margin: 0 auto;

	div.bg-circle {
		z-index: 0; }

	div.bg-circle:nth-of-type(1) {
		top: 20px;
		left: 400px; }
	div.bg-circle:nth-of-type(2) {
		top: 240px;
		left: 1290px; }
	div.bg-circle:nth-of-type(3) {
		top: 820px;
		left: 720px; }
	div.bg-circle:nth-of-type(4) {
		top: 940px;
		left: 260px; }
	div.bg-circle:nth-of-type(5) {
		top: 340px;
		left: 60px; }
	div.bg-circle:nth-of-type(6) {
		top: 30px;
		left: 85px; }
	div.bg-circle:nth-of-type(7) {
		top: 10px;
		left: 1170px; }
	div.bg-circle:nth-of-type(8) {
		top: 800px;
		left: 1145px; }
	div.bg-circle:nth-of-type(9) {
		top: 955px;
		left: 760px; }
	div.bg-circle:nth-of-type(10) {
		top: 975px;
		left: 1210px; }
	div.bg-circle:nth-of-type(11) {
		top: 840px;
		left: -40px; } }


#bootstrapoverride {
	.home-container {
		position: relative;
		width: 1400px;
		height: 1055px;
		margin: 0 auto;
		z-index: 10;

		.circle {
			display: inline-block;
			position: absolute;
			background-color: #d8d8d8;
			border-radius: 50%;

			.bubble {
				position: absolute; } }

		.bear-img {
			position: absolute;
			top: 370px;
			left: 0;
			right: 0;
			margin: 0 auto; }

		.bear-img-2 {
			@extend .bear-img;
			@include animation(winking, linear, infinite, 2s, 0s, none); }


		.bubble {
			top: -45px;
			right: 10px; }


		#about-circle {
			top: 80px;
			left: 185px;

			&>div {
				@include image-formating('../img/home/home_center_who.png');
				width: 270px;
				height: 270px;
				border-radius: 50%; } }


		#newarrival-circle {
			top: 20px;
			left: 570px;

			&>div {
				@include image-formating('../img/home/home_center_newarrival.png');
				width: 310px;
				height: 310px;
				border-radius: 50%; } }


		#story-circle {
			top: 80px;
			left: 900px;

			&>div {
				@include image-formating('../img/home/home_center_storybook.png');
				width: 240px;
				height: 240px;
				border-radius: 50%; } }


		#newclothes-circle {
			top: 390px;
			left: 1030px;

			&>div {
				@include image-formating('../img/home/home_center_newclothes.png');
				width: 310px;
				height: 310px;
				border-radius: 50%; } }


		#share-circle {
			top: 680px;
			left: 795px;

			&>div {
				@include image-formating('../img/home/home_center_loveshare.png');
				width: 240px;
				height: 240px;
				border-radius: 50%; } }


		#visit-circle {
			top: 700px;
			left: 390px;

			&>div {
				@include image-formating('../img/home/home_center_where.png');
				width: 240px;
				height: 240px;
				border-radius: 50%; } }

		#toy-circle {
			top: 450px;
			left: 60px;

			&>div {
				@include image-formating('../img/home/home_center_toy.png');
				width: 310px;
				height: 310px;
				border-radius: 50%; } } } }

@media all and (max-width: $mobile) {

	.decoration-bg-home {
		position: relative;
		width: 100vw;
		z-index: 0;
		margin: 0 auto;

		div.bg-circle:nth-of-type(1) {
			width: 7vw !important;
			height: 7vw !important;
			top: 85px;
			left: 80vw; }
		div.bg-circle:nth-of-type(2) {
			width: 7vw !important;
			height: 7vw !important;
			top: 240px;
			left: 55vw; }
		div.bg-circle:nth-of-type(3) {
			width: 25vw !important;
			height: 25vw !important;
			top: 203px;
			left: -10vw; }
		div.bg-circle:nth-of-type(4) {
			width: 15vw !important;
			height: 15vw !important;
			top: 268px;
			left: 20vw; }
		div.bg-circle:nth-of-type(5) {
			width: 5vw !important;
			height: 5vw !important;
			top: 429px;
			left: 80vw; }
		div.bg-circle:nth-of-type(6) {
			width: 7vw !important;
			height: 7vw !important;
			top: 518px;
			left: 60vw; }
		div.bg-circle:nth-of-type(7) {
			width: 7vw !important;
			height: 7vw !important;
			top: 664px;
			left: 15vw; }
		div.bg-circle:nth-of-type(8) {
			width: 13vw !important;
			height: 13vw !important;
			top: 714px;
			left: 2vw; }
		div.bg-circle:nth-of-type(9) {
			width: 20vw !important;
			height: 20vw !important;
			top: 770px;
			left: 80vw; }
		div.bg-circle:nth-of-type(10) {
			width: 10vw !important;
			height: 10vw !important;
			top: 906px;
			left: 10vw; }
		div.bg-circle:nth-of-type(11) {
			width: 13vw !important;
			height: 13vw !important;
			top: 972px;
			left: -2px; } }
	#bootstrapoverride {
		.home-container {
			width: 100vw;
			min-height: 1330px;
			overflow: hidden;

			.bear-img {
				top: 370px;
				width: 250px;
				height: 144px;
				left: 0;
				right: 0;
				margin: 0 auto; }
			.bubble {
				width: 90px; }

			#about-circle {
				top: 83px;
				left: 10vw;

				&>div {
					width: 28vw;
					height: 28vw; } }


			#newarrival-circle {
				top: 148px;
				left: 45vw;

				&>div {
					width: 50vw;
					height: 50vw; } }


			#story-circle {
				top: 508px;
				left: 10vw;

				&>div {
					width: 30vw;
					height: 30vw; } }


			#newclothes-circle {
				top: 597px;
				left: 50vw;

				&>div {
					width: 45vw;
					height: 45vw; } }


			#share-circle {
				top: 1005px;
				left: 20vw;

				&>div {
					width: 60vw;
					height: 60vw; } }


			#visit-circle {
				top: 881px;
				left: 60vw;

				&>div {
					width: 30vw;
					height: 30vw; } }

			#toy-circle {
				top: 788px;
				left: 15vw;

				&>div {
					width: 35vw;
					height: 35vw; } } } } }
















