
//circles
#bootstrapoverride {
	.bg-circle {
		position: absolute;
		z-index: 0;
		border-radius: 50%;

		&.bg-yellow {
			background-color: $sun-yellow; }
		&.bg-yellow-2 {
			background-color: $sunflower-yellow; }
		&.bg-green {
			background-color: $dark-lime-green; }
		&.bg-blue {
			background-color: $water-blue; }
		&.bg-blue-2 {
			background-color: $bright-sky-blue; }
		&.bg-orange {
			background-color: $dusty-orange; }
		&.bg-orange-2 {
			background-color: $pumpkin-orange; }
		&.bg-red {
			background-color: $pink-red; }
		&.bg-red-2 {
			background-color: $vermillion; }
		&.bg-pink {
			background-color: $pink; }
		&.bg-pink-2 {
			background-color: $barbie-pink; }

		&.x-1 {
			width: 43px;
			height: 43px; }
		&.x-2 {
			width: 50px;
			height: 50px; }
		&.x-3 {
			width: 70px;
			height: 70px; }
		&.x-4 {
			width: 100px;
			height: 100px; }
		&.x-5 {
			width: 130px;
			height: 130px; }
		&.x-6 {
			width: 160px;
			height: 160px; }
		&.x-7 {
			width: 190px;
			height: 190px; }
		&.x-8 {
			width: 230px;
			height: 230px; }
		&.x-9 {
			width: 290px;
			height: 290px; }
		&.x-10 {
			width: 330px;
			height: 330px; }
		&.x-11 {
			width: 470px;
			height: 470px; } }
	.decoration-bg-story {
		opacity: 0.4; } }

@media all and (max-width: $mobile) {
	#bootstrapoverride {
		.bg-circle {
			&.x-1 {
				width: 43px;
				height: 43px; }
			&.x-2 {
				width: 3vw;
				height: 3vw; }
			&.x-3 {
				width: 5vw;
				height: 5vw; }
			&.x-4 {
				width: 10vw;
				height: 10vw; }
			&.x-5 {
				width: 15vw;
				height: 15vw; }
			&.x-6 {
				width: 20vw;
				height: 20vw; }
			&.x-7 {
				width: 25vw;
				height: 25vw; }
			&.x-8 {
				width: 30vw;
				height: 30vw; }
			&.x-9 {
				width: 35vw;
				height: 35vw; }
			&.x-10 {
				width: 40vw;
				height: 40vw; }
			&.x-11 {
				width: 45vw;
				height: 45vw; } }
		.decoration-bg-story {
			.bg-circle {
				opacity: 1; } } } }

