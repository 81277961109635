#bootstrapoverride {
	.faq-container {
		.position-1 {
			left: 0 !important; }
		.position-2 {
			left: 150px !important; }
		.position-3 {
			left: 300px !important; }
		.position-4 {
			left: 450px !important; }
		.position-5 {
			left: 600px !important; }
		.position-6 {
			left: 750px !important; }
		.faq-nav-tab {
			width: 150px !important; }
		.tab-inactive {
			font-size: 18px !important;
			p {
				font-size: 18px !important; } }

		.tab {
			font-size: 18px !important;
			&:after {
				left: 147px !important; }
			p {
				font-size: 18px !important; } }
		.qa-list-note {
			margin-left: 40px; }

		.inner-container {
			color: #595757;
			.inner-wrap {
				width: 600px;
				margin: 50px auto;

				.q-title {
					font-size: 15px;
					line-height: 2;
					color: #595757;
					cursor: pointer;
					span {
						position: relative;
						color: white;
						margin-right: 10px;
						&:after {
							position: absolute;
							top: -2px;
							left: -6px;
							content: "";
							width: 20px;
							height: 20px;
							background-color: #f47e20;
							border-radius: 50%;
							z-index: -1; } } }
				.q-title-2 {
					span {
						margin-left: -4px;
						margin-right: 7px;
						&:after {
							left: -1px; } } }
				.q-content {
					padding: 0px 30px;
					.split-line {
						width: 600px;
						height: 1px;
						margin: 8px 0px 8px -30px;
						border-top: dashed 1px #f0190f; } } }
			li {
				position: relative;
				margin-left: 40px;
				line-height: 2;

				&:before {
					content: ".";
					padding-right: 8px;
					color: blue;
					display: block;
					position: absolute;
					top: -5px;
					left: -24px; } }
			.dark-title {
				font-size: 15px;
				letter-spacing: 0.2px;
				color: #231f20; }

			.step-title {
				font-size: 15px;
				font-weight: 900;
				letter-spacing: 2.4px;
				color: #f0190f;
				line-height: 2;
				margin-top: 5px;

				&>div {
					line-height: 2; } }
			.sub-title {
				color: #595757; }

			table {
				width: 600px;
				margin: 20px 0px 0px -30px;
				border-bottom: solid 2px #f0190f;
				th {
					font-size: 15px;
					letter-spacing: 0.2px;
					color: #231f20;
					border-bottom: solid 2px #f0190f;
					min-width: 120px;
					text-align: center; }

				tr {
					font-size: 15px;
					line-height: 2;
					color: #595757;
					border-bottom: dashed 1px #f0190f;
					min-width: 120px;
					td {
						padding: 20px; } } }
			.note-table {
				border-bottom: none {
					border-bottom: none; }
				tr {
					width: 500px;
					border-bottom: none;
					th {
						border-bottom: none;
						min-width: 60px; }
					td {
						padding: 5px;
						line-height: 1.5; } } } } }

	.faq-button {
	    width: 85px;
	    height: 26px;
	    float: right;
	    margin-top: 20px;
    	font-size: 16px;
    	padding: 3px 0px 0px 20px;

	    span {
	    	font-size: 16px;
	    	&:after {
	    		bottom: 7px; } } } }
@media all and (max-width: $mobile) {
	#bootstrapoverride {
		.faq-container {
			div {
				font-size: 11px; }
			.faq-nav-tab {
				width: 90vw !important; }
			.inner-container {
				.inner-wrap {
					width: 75vw;
					font-size: 11px;
					.q-title {
						font-size: 11px; }
					.q-content {
						font-size: 11px;
						padding: 0px 15px;
						.split-line {
							width: 75vw;
							margin: 8px 0px 8px -20px; } } }
				.dark-title {
					font-size: 11px; }
				li {
					font-size: 11px; }
				table {
					width: 75vw;
					margin: 20px 0px 0px -15px;
					th {
						min-width: 25vw;
						font-size: 11px; }
					tr {
						min-width: 25vw;
						font-size: 11px;
						td {
							padding: 0px;
							font-size: 11px; } } }

				.step-title {
					font-size: 11px;
					letter-spacing: 1.4px; } } } } }
