body {
	min-width: 1400px; }

#bootstrapoverride {
	h1, h2, h3, h4, h5, li, a, label, p, span, div {
		font-family: $primary-style;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		line-height: 22px; }

	li, a, label, p, span, div {
		font-size: 14px; }


	h1 {
		font-size: 45px; }
	h2 {
		font-size: 37px;
		line-height: 45px; }
	h3 {
		font-size: 24px;
		line-height: 32px; }
	h4 {
		font-size: 20px;
		line-height: 28px; }
	h5 {
		font-size: 15px; }



	p {
		font-size: 14px;
		color: $text-grey; }

	a,
	a:link,
	a:visited,
	a:hover,
	a:active {
		color: inherit;
		text-decoration: inherit;
		font-weight: inherit; }

	input:focus,
	textarea:focus {
		outline: none; } }
.mobile-show {
	display: none; }

.slick-prev, .slick-next {
	z-index: 20;
	background-image: url(/asset/img/icons/btn-stroke-right.svg) !important;
	background-size: cover !important;
	height: 32px;
	&:before {
		opacity: 0;
		content: ""; } }

.slick-prev {
	left: 0;
	top: 35%;
	transform: rotateZ(180deg); }
.slick-next {
	right: 0; }
.slick-dots li.slick-active button:before {
	background-color: #f0190f;
	color: transparent;
	opacity: 1;
	border: none;
	width: 15px;
	height: 15px; }
.slick-dots li button:before {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #f0190f;
	color: transparent;
	opacity: 1; }

.back-to-top-btn {
	position: fixed;
	bottom: 80px;
	right: 8vw;
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: grey;
	opacity: 0.4;
	padding: 10px;
	z-index: 1000000; }

@media all and (max-width: $mobile) {
	body {
		min-width: 100vw; }
	.mobile-not-show {
		display: none; }
	.mobile-show {
		display: block; }
	.back-to-top-btn {
		bottom: 20px;
		right: 20px; } }

